<template>
    <component :is="tag" :class="cssClass">
        <card-header v-if="hasHeaderSlot || hasToolbar1 || hasToolbar2" :class="headerCssClass">
            <slot name="header">
                <div v-if="icon" class="card-caption d-flex align-items-center">
                    <card-icon :icon="icon" :size="iconSize" :color="iconColor" />
                    <div class="ms-2">
                        <card-title v-if="title" :tag="titleTag">{{ title }}</card-title>
                        <card-subtitle v-if="subtitle" :tag="subtitleTag">{{ subtitle }}</card-subtitle>
                    </div>
                </div>
                <div v-else class="card-caption">
                    <card-title v-if="title" :tag="titleTag">{{ title }}</card-title>
                    <card-subtitle v-if="subtitle" :tag="subtitleTag">{{ subtitle }}</card-subtitle>
                </div>
                <card-toolbar v-if="hasToolbar1">
                    <slot name="toolbar1" />
                </card-toolbar>
                <card-toolbar v-if="hasToolbar2">
                    <slot name="toolbar2" />
                </card-toolbar>
            </slot>
        </card-header>
        <card-img v-if="imgSrc && imgTop" :src="imgSrc" :alt="ImgAlt" top />
        <card-body v-if="!noBody" :class="bodyCssClass">
            <slot>
                <card-text v-if="text" :tag="textTag">{{ text }}</card-text>
            </slot>
        </card-body>
        <slot v-else>
            <card-text v-if="text" :tag="textTag">{{ text }}</card-text>
        </slot>
        <card-img v-if="imgSrc && imgBottom" :src="imgSrc" :alt="ImgAlt" bottom />
        <card-footer v-if="hasFooterSlot" :class="footerCssClass">
            <slot name="footer" />
        </card-footer>
    </component>
</template>

<script>
import {computed} from "vue"
import bsCardHeader from "@/components/Bootstrap/Card/CardHeader"
import bsCardBody from "@/components/Bootstrap/Card/CardBody"
import bsCardFooter from "@/components/Bootstrap/Card/CardFooter"
import bsCardTitle from "@/components/Bootstrap/Card/CardTitle"
import bsCardSubtitle from "@/components/Bootstrap/Card/CardSubtitle"
import bsCardImg from "@/components/Bootstrap/Card/CardImg"
import bsCardText from "@/components/Bootstrap/Card/CardText"
import bsCardToolbar from "@/components/Bootstrap/Card/CardToolbar"
import bsCardIcon from "@/components/Bootstrap/Card/CardIcon"

export default {
    name: "bsCard",
    components: {
        cardHeader: bsCardHeader,
        cardBody: bsCardBody,
        cardFooter: bsCardFooter,
        cardTitle: bsCardTitle,
        cardSubtitle: bsCardSubtitle,
        cardImg: bsCardImg,
        cardText: bsCardText,
        cardToolbar: bsCardToolbar,
        cardIcon: bsCardIcon
    },
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        noBody: {
            type: Boolean,
            default: false
        },
        class: String,
        shadow: [Boolean, String],
        icon: String,
        iconSize: String,
        iconColor: String,
        title: String,
        titleTag: String,
        subtitle: String,
        subtitleTag: String,
        text: String,
        textTag: String,
        imgSrc: String,
        imgAlt: String,
        imgTop: {
            type: Boolean,
            default: true
        },
        imgBottom: Boolean,
        bg: String,
        border: String,
        color: String,
        headerBg: String,
        headerBorder: String,
        headerColor: String,
        headerClass: String,
        bodyBg: String,
        bodyBorder: String,
        bodyColor: String,
        bodyClass: String,
        footerBg: String,
        footerBorder: String,
        footerColor: String,
        footerClass: String
    },
    setup(props, { slots, attrs }) {
        const cssClass = computed(() => {
            return [
                'card',
                props.bg && `bg-${props.bg}`,
                props.border && `border-${props.border}`,
                props.color && `text-${props.color}`,
                props.shadow && (typeof props.shadow === "boolean" ? 'shadow' : `shadow-${props.shadow}`),
                (props.icon || hasToolbar1 || hasToolbar2) && 'card-custom',
                props.class || null,
            ]
        })

        const headerCssClass = computed(() => {
            return [
                props.headerBg && `bg-${props.headerBg}`,
                props.headerBorder && `border-${props.headerBorder}`,
                props.headerColor && `text-${props.headerColor}`,
                props.headerClass || null
            ]
        })

        const bodyCssClass = computed(() => {
            return [
                props.bodyBg && `bg-${props.bodyBg}`,
                props.bodyBorder && `border-${props.bodyBorder}`,
                props.bodyColor && `text-${props.bodyColor}`,
                props.bodyClass || null
            ]
        })

        const footerCssClass = computed(() => {
            return [
                props.footerBg && `bg-${props.footerBg}`,
                props.footerBorder && `border-${props.footerBorder}`,
                props.footerColor && `text-${props.footerColor}`,
                props.footerClass || null
            ]
        })

        const hasHeaderSlot = computed(() =>{
            return !!slots.header || props.title || props.subtitle
        })

        const hasFooterSlot = computed(() =>{
            return !!slots.footer
        })

        const hasToolbar1 = computed(() =>{
            return !!slots.toolbar1
        })

        const hasToolbar2 = computed(() =>{
            return !!slots.toolbar2
        })

        return {
            cssClass,
            headerCssClass,
            bodyCssClass,
            footerCssClass,
            hasHeaderSlot,
            hasFooterSlot,
            hasToolbar1,
            hasToolbar2,
            attrs
        };
    }
};
</script>