<template>
    <component
        :is="tag"
        class="icon"
        :class="{
            [`icon-${icon}`] : icon,
            [`icon-${size}`]: size,
            [`text-${color}`]: color
        }"
        v-bind="attrs" />
</template>

<script>
import {watchEffect} from "vue";

export default {
    name: "bsIcon",
    props: {
        tag: {
            type: String,
            default: 'i'
        },
        icon: String,
        size: String,
        color: String,
        hidden: Boolean
    },
    setup(props, {attrs}) {
        watchEffect(() => {
            if (props.hidden) {
                attrs = {
                    ...attrs,
                    "aria-hidden": "true"
                }
            }
        })

        return {
            attrs
        }
    }
}
</script>