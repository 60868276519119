<template>
    <page-content v-if="user">
        <template #header>
            <subheader class="d-none d-lg-block" enable-sticky>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <h1 class="subheader-title h6 mb-0">
                            {{ $t('profile.staff') }}
                        </h1>
                        <div class="subheader-divider subheader-divider-ver mx-3"></div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'staff_list' }">
                                        {{ $t('nav.staff_list') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active" aria-current="page">
                                    {{ $t('profile.new_staff') }}
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </subheader>
        </template>
        <template #main>
            <form class="form" @submit.prevent="saveUserDetails">
                <div class="card shadow mt-4 mt-lg-0">
                    <div class="card-body p-0">
                        <wizard id="wizard1" class="wizard-1">
                            <wizard-nav class="p-lg-5">
                                <wizard-step data-target="#account-details" :selected="true">
                                    <div class="wizard-icon rounded">
                                        <i class="icon icon-check"></i>
                                        <span class="wizard-number">1</span>
                                    </div>
                                    <div class="wizard-label">
                                        <h6 class="wizard-title">
                                            {{ $t('profile.tab.account.title') }}
                                        </h6>
                                        <div class="wizard-desc fs-sm">
                                            {{ $t('profile.tab.account.new_user_subtitle') }}
                                        </div>
                                    </div>
                                </wizard-step>
                                <wizard-step data-target="#server-access">
                                    <div class="wizard-icon rounded">
                                        <i class="icon icon-check"></i>
                                        <span class="wizard-number">2</span>
                                    </div>
                                    <div class="wizard-label">
                                        <h6 class="wizard-title">
                                            {{ $t('profile.tab.server_access.title') }}
                                        </h6>
                                        <div class="wizard-desc fs-sm">
                                            {{ $t('profile.tab.server_access.subtitle') }}
                                        </div>
                                    </div>
                                </wizard-step>
                            </wizard-nav>
                            <wizard-tab-content>
                                <wizard-panel id="account-details" class="py-lg-5" :selected="true">
                                    <div class="row justify-content-center">
                                        <div class="col-md-8">
                                            <fieldset>
                                                <legend>{{ $t('profile.section.account') }}</legend>
                                                <text-input
                                                    id="email"
                                                    type="email"
                                                    :label="$t('form.email.label')"
                                                    :placeholder="$t('form.email.placeholder')"
                                                    :error-message="$t('form.email.error')"
                                                    v-model="v$.email.$model"
                                                    :invalid="v$.email.$errors.length"
                                                    @blur="v$.email.$touch()" />
                                                <div class="form-group">
                                                    <password-input
                                                        id="passwd"
                                                        input-group-class="input-group-flat"
                                                        :label="$t('form.password.label')"
                                                        v-model="v$.password.$model"
                                                        :invalid="v$.password.$errors.length"
                                                        @blur="v$.password.$touch()"
                                                        :placeholder="$t('form.password.placeholder')"
                                                        :error-message="$t('form.password.error')"
                                                        :enable-generator="true" />
                                                </div>
                                                <div class="form-group">
                                                    <checkbox-input
                                                        id="isUserActive"
                                                        :label="$t('form.user_active.label')"
                                                        :form-text="$t('form.user_active.help')"
                                                        v-model="user.is_active" />
                                                </div>
                                                <div class="form-group">
                                                    <checkbox-input
                                                        id="isUserDefault"
                                                        :label="$t('form.default_staff.label')"
                                                        :form-text="$t('form.default_staff.help')"
                                                        v-model="user.is_default" />
                                                </div>
                                            </fieldset>
                                            <fieldset class="mt-4">
                                                <legend>{{ $t('profile.section.personal') }}</legend>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <text-input
                                                            id="first-name"
                                                            :label="$t('form.first_name.label')"
                                                            :placeholder="$t('form.first_name.placeholder')"
                                                            :error-message="$t('form.first_name.error')"
                                                            v-model="v$.first_name.$model"
                                                            :invalid="v$.first_name.$errors.length"
                                                            @blur="v$.first_name.$touch()" />
                                                    </div>
                                                    <div class="col-md-6">
                                                        <text-input
                                                            id="last-name"
                                                            :label="$t('form.last_name.label')"
                                                            :placeholder="$t('form.last_name.placeholder')"
                                                            :error-message="$t('form.last_name.error')"
                                                            v-model="v$.last_name.$model"
                                                            :invalid="v$.last_name.$errors.length"
                                                            @blur="v$.last_name.$touch()" />
                                                    </div>
                                                </div>
                                                <text-input
                                                    id="city"
                                                    :label="$t('form.city.label')"
                                                    :placeholder="$t('form.city.placeholder')"
                                                    :error-message="$t('form.city.error')"
                                                    v-model="v$.city.$model"
                                                    :invalid="v$.city.$errors.length"
                                                    @blur="v$.city.$touch()" />
                                                <textarea-input
                                                    id="remarks"
                                                    v-model="user.remark"
                                                    rows="3"
                                                    :label="$t('form.remarks.label')"
                                                    :placeholder="$t('form.remarks.placeholder')"
                                                    optional />
                                            </fieldset>
                                            <div class="mt-4 text-end">
                                                <button
                                                    type="button"
                                                    class="btn btn-primary"
                                                    data-step="#server-access-tab">
                                                    {{ $t('btn.next_tab') }}
                                                    <i class="icon icon-chevron-right ms-1"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </wizard-panel>
                                <wizard-panel id="server-access" class="px-lg-4 py-lg-5">
                                    <div class="alert border-light alert-custom mb-4">
                                        <div class="d-flex align-items-center">
                                            <div class="alert-icon">
                                                <i class="icon icon-info text-info" />
                                            </div>
                                            <div class="alert-body fs-sm text-dark">
                                                {{ $t('profile.tab.server_access.alert') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2 g-md-3 mt-1">
                                        <div class="col"
                                             v-for="(server, serverIndex) in user.server_access"
                                             :key="serverIndex">
                                            <staff-data-server
                                                :access-groups="accessGroups"
                                                :data-server="server"
                                                :data-server-index="serverIndex"
                                                v-model="serverState[serverIndex]"
                                                @click="toggleServerStatus" />
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between mt-5">
                                        <button
                                            type="button"
                                            class="btn btn-outline-light text-dark"
                                            data-step="#account-details-tab">
                                            <i class="icon icon-chevron-left me-1"></i>
                                            {{ $t('btn.prev_tab') }}
                                        </button>
                                        <button type="submit" class="btn btn-primary">
                                            {{ $t('btn.add_staff') }}
                                        </button>
                                    </div>
                                </wizard-panel>
                            </wizard-tab-content>
                        </wizard>
                    </div>
                </div>
            </form>
        </template>
        <template #footer>
            <bs-modal id="accessGroupsModal" ref="accessGroupsModal">
                <h5 class="mb-1">{{ $t('modal.access_groups.title') }}</h5>
                <p class="mb-4">{{ $t('modal.access_groups.subtitle') }}</p>
                <access-groups-radio
                    v-model="activeServer.group_id"
                    @click="accessGroupsModal.modal.hide()" />
            </bs-modal>
        </template>
    </page-content>
</template>

<script>
import {ref, reactive, computed, provide} from 'vue';
import {useRouter} from 'vue-router';
import { email, required } from "@vuelidate/validators";
import Api from '@/modules/api';
import useUser from "@/modules/user";
import {useStore} from 'vuex';
import useVuelidate from "@vuelidate/core";
import Subheader from '@/components/Subheader';
import PasswordInput from "@/components/Form/PasswordInput";
import CheckboxInput from "@/components/Form/CheckboxInput";
import TextInput from "@/components/Form/TextInput";
import TextareaInput from "@/components/Form/TextareaInput";
import PageContent from "@/components/PageContent";
import AccessGroupsRadio from "@/components/DataServers/AccessGroupsRadio";
import Wizard from "@/components/Wizard/Wizard";
import WizardNav from "@/components/Wizard/WizardNav";
import WizardStep from "@/components/Wizard/WizardStep";
import WizardPanel from "@/components/Wizard/WizardPanel";
import WizardTabContent from "@/components/Wizard/WizardTabContent";
import bsModal from "@/components/Bootstrap/Modal/Modal";
import StaffDataServer from "@/components/DataServers/StaffDataServer";

export default {
    name: "AddStaff",
    components: {
        PageContent,
        Subheader,
        PasswordInput,
        CheckboxInput,
        TextInput,
        TextareaInput,
        Wizard,
        WizardNav,
        WizardStep,
        WizardPanel,
        WizardTabContent,
        bsModal,
        StaffDataServer,
        AccessGroupsRadio
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const accessGroupsModal = ref();
        const activeServer = ref({});
        const accessGroups = ref();

        const userTemplate = reactive({
            account_id: null,
            parent_id: store.getters.user.id,
            login: null,
            password: null,
            email: null,
            registration_date: null,
            last_login_date: null,
            last_ip: null,
            last_client: null,
            session_id: null,
            session_name: null,
            is_default: false,
            first_name: null,
            last_name: null,
            city: null,
            remark: '',
            is_active: false,
            is_deleted: false,
            server_access: []
        });

        const user = ref(userTemplate);

        const serverState = computed(() => {
            return user.value.server_access.map( s => { return !!s.group_id });
        })

        // Form validation rules
        let rules = {
            email: { required, email },
            city: { required },
            first_name: { required },
            last_name: { required },
            password: { required }
        };

        const v$ = useVuelidate(rules, user);
        provide('vuelidate', v$);

        const saveUserDetails = async () => {
            await v$.value.$touch();

            if (!v$.value.$invalid) {
                const staff = await Api.saveStaff(user.value);

                if (staff.account_id) {
                  router.replace({name: 'staff_details', params: {id: staff.account_id}});
                  beforeCreate();
                }
            }
        }

        const toggleServerStatus = (e) => {
            activeServer.value = user.value.server_access[e.target.getAttribute('data-value')]

            if (e.target.checked || e.target.tagName.toLowerCase() !== 'input') {
                accessGroupsModal.value.modal.show();
                e.preventDefault();
            } else {
                activeServer.value.group_id = null;
            }
        }

        const beforeCreate = async () => {
            accessGroups.value = (await Api.getStaffGroups(true));
            user.value.server_access = useUser.getStaffServerAccesses(user, store.getters.userDataServers);
        }

        beforeCreate();

        return {
            v$,
            user,
            accessGroupsModal,
            saveUserDetails,
            toggleServerStatus,
            activeServer,
            serverState,
            accessGroups
        }
    }
}
</script>

<style lang="scss">
    @import "./src/assets/styles/layout/wizard";
</style>