<template>
    <page-content>
        <template #header>
            <subheader enable-sticky class="d-none d-lg-block">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <h1 class="subheader-title h6 mb-0">
                            {{ $t('profile.agent') }}
                        </h1>
                        <div class="subheader-divider subheader-divider-ver mx-3"></div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <router-link :to="{ name: 'partner_list' }">
                                        {{ $t('nav.agents_list') }}
                                    </router-link>
                                </li>
                                <li class="breadcrumb-item active text-capitalize" aria-current="page">
                                    {{ newUser ? $t('profile.new_agent') : userFullName(user.first_name, user.last_name) }}
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div class="d-flex align-items-center ms-auto" v-if="!newUser">
                        <button
                            v-if="false"
                            type="button"
                            class="btn btn-primary btn-sm me-2">
                            {{ $t('btn.send_message') }}
                        </button>
                        <button
                            @click.prevent="deleteUser(user.account_id)"
                            type="button"
                            class="btn btn-danger btn-sm">
                            <i class="icon icon-trash-2 me-2" aria-hidden="true"></i>
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </subheader>
        </template>
        <template #main>
            <form class="form" @submit.prevent="saveUserDetails">
                <div class="card shadow">
                    <div class="card-body p-0">
                        <div class="tab-content" ref="profileTabPanes" id="profileTabPanes">
                            <div
                                class="tab-pane fade show active"
                                id="account-details"
                                role="tabpanel"
                                aria-labelledby="account-details-tab">
                                <div class="card border-0 bg-transparent">
                                    <div class="card-header border-bottom p-lg-4">
                                        <h5 class="card-title mb-1">
                                            {{ $t('profile.tab.account.title') }}
                                        </h5>
                                        <div class="text-muted fs-sm">
                                            {{ newUser ? $t('profile.tab.account.new_user_subtitle') : $t('profile.tab.account.subtitle') }}
                                        </div>
                                    </div>
                                    <div class="card-body p-lg-5">
                                        <fieldset>
                                            <div class="row">
                                                <div class="col-md-6 offset-md-3">
                                                    <h6 class="mb-3 fw-semi-bold">
                                                        {{ $t('profile.section.account') }}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div class="row" v-if="!newUser">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.username.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <text-input
                                                        id="username"
                                                        :placeholder="$t('form.username.placeholder')"
                                                        :error-message="$t('form.username.error')"
                                                        v-model="v$.login.$model"
                                                        :invalid="v$.login.$errors.length"
                                                        @blur="v$.login.$touch()"/>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.email.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <text-input
                                                        id="email"
                                                        type="email"
                                                        :placeholder="$t('form.email.placeholder')"
                                                        :error-message="$t('form.email.error')"
                                                        v-model="v$.email.$model"
                                                        :invalid="v$.email.$errors.length"
                                                        @blur="v$.email.$touch()"/>
                                                </div>
                                            </div>
                                            <div class="row" v-if="!newUser">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.password.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <password-input
                                                        id="passwd"
                                                        v-model="user.password"
                                                        :placeholder="$t('form.password.placeholder')"
                                                        wrapper-class="form-group"
                                                        input-group-class="input-group-flat"
                                                        :enable-generator="true" />
                                                </div>
                                            </div>
                                            <div class="form-group row align-items-center mb-3 mb-lg-2" v-if="!newUser">
                                                <div class="col-6 col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mb-lg-0">
                                                        {{ $t('form.user_active.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-6 text-end text-md-start">
                                                    <checkbox-input
                                                        id="isUserActive"
                                                        wrapper-class="d-inline-block"
                                                        :switch="true"
                                                        v-model="user.is_active"
                                                        :checked="user.is_active" />
                                                </div>
                                            </div>
                                            <div class="form-group row align-items-center mb-0" v-if="!newUser">
                                                <div class="col-6 col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mb-lg-0">
                                                        {{ $t('form.check.user_deleted') }}
                                                    </label>
                                                </div>
                                                <div class="col-6 text-end text-md-start">
                                                    <checkbox-input
                                                        id="isUserDeleted"
                                                        wrapper-class="d-inline-block"
                                                        :switch="true"
                                                        v-model="user.is_deleted" />
                                                </div>
                                            </div>
                                            <div class="form-group row align-items-center" v-if="newUser || true">
                                                <div class="col-6 col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mb-lg-0">
                                                        {{ $t('form.check.email_confirmed') }}
                                                    </label>
                                                </div>
                                                <div class="col-6 text-end text-md-start">
                                                    <checkbox-input
                                                        id="isUserConfirmed"
                                                        wrapper-class="d-inline-block"
                                                        :switch="true"
                                                        v-model="user.email_activation" />
                                                </div>
                                            </div>
                                        </fieldset>
                                        <hr class="dashed my-4 my-lg-5" v-if="newUser" />
                                        <fieldset v-if="newUser">
                                            <div class="row">
                                                <div class="col-md-6 offset-md-3">
                                                    <h6 class="mb-3 fw-semi-bold">
                                                        {{ $t('profile.section.company') }}:
                                                    </h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.company_name.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <text-input
                                                        id="companyName"
                                                        :placeholder="$t('form.company_name.placeholder')"
                                                        :error-message="$t('form.company_name.error')"
                                                        v-model="v$.company_name.$model"
                                                        :invalid="v$.company_name.$errors.length"
                                                        @blur="v$.company_name.$touch()" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.country.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <country-select
                                                            id="country"
                                                            v-model="v$.country_iso.$model"
                                                            :invalid="v$.country_iso.$errors.length"
                                                            @blur="v$.country_iso.$touch()"
                                                            :placeholder="$t('form.country.placeholder')" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.city.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <text-input
                                                        id="cityName"
                                                        :placeholder="$t('form.city.placeholder')"
                                                        :error-message="$t('form.city.error')"
                                                        v-model="v$.city.$model"
                                                        :invalid="v$.city.$errors.length"
                                                        @blur="v$.city.$touch()" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.phone_number.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <text-input
                                                        id="phoneNumber"
                                                        :placeholder="$t('form.phone_number.placeholder')"
                                                        :error-message="$t('form.phone_number.error')"
                                                        v-model="v$.phone.$model"
                                                        :invalid="v$.phone.$errors.length"
                                                        @blur="v$.phone.$touch()" />
                                                </div>
                                            </div>
                                        </fieldset>
                                        <hr class="my-4 my-lg-5" />
                                        <fieldset>
                                            <div class="row">
                                                <div class="col-md-6 offset-md-3">
                                                    <h6 class="mb-3 fw-semi-bold">
                                                        {{ $t('profile.section.personal') }}:
                                                    </h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.first_name.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <text-input
                                                        id="firstName"
                                                        :placeholder="$t('form.first_name.placeholder')"
                                                        :error-message="$t('form.first_name.error')"
                                                        v-model="v$.first_name.$model"
                                                        :invalid="v$.first_name.$errors.length"
                                                        @blur="v$.first_name.$touch()" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.last_name.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <text-input
                                                        id="lastName"
                                                        :placeholder="$t('form.last_name.placeholder')"
                                                        :error-message="$t('form.last_name.error')"
                                                        v-model="v$.last_name.$model"
                                                        :invalid="v$.last_name.$errors.length"
                                                        @blur="v$.last_name.$touch()" />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="!newUser"
                                class="tab-pane fade"
                                id="company-profile"
                                role="tabpanel"
                                aria-labelledby="company-profile-tab">
                                <div class="card border-0 bg-transparent">
                                    <div class="card-header border-bottom p-lg-4">
                                        <h5 class="card-title mb-1">
                                            {{ $t('profile.tab.company.title') }}
                                        </h5>
                                        <div class="text-muted fs-sm">
                                            {{ $t('profile.tab.company.subtitle') }}
                                        </div>
                                    </div>
                                    <div class="card-body p-lg-5">
                                        <fieldset>
                                            <div class="row">
                                                <div class="col-md-6 offset-md-3">
                                                    <h6 class="mb-3 fw-semi-bold">
                                                        {{ $t('profile.section.company') }}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.company_name.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <text-input
                                                        id="companyName"
                                                        :placeholder="$t('form.company_name.placeholder')"
                                                        :error-message="$t('form.company_name.error')"
                                                        v-model="v$.company_name.$model"
                                                        :invalid="v$.company_name.$errors.length"
                                                        @blur="v$.company_name.$touch()" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.country.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <country-select
                                                            id="country"
                                                            v-model="v$.country_iso.$model"
                                                            :invalid="v$.country_iso.$errors.length"
                                                            @blur="v$.country_iso.$touch()"
                                                            :placeholder="$t('form.country.placeholder')" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.city.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <text-input
                                                        id="cityName"
                                                        :placeholder="$t('form.city.placeholder')"
                                                        :error-message="$t('form.city.error')"
                                                        v-model="v$.city.$model"
                                                        :invalid="v$.city.$errors.length"
                                                        @blur="v$.city.$touch()" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.company_address.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <textarea-input
                                                        id="address"
                                                        v-model="user.address"
                                                        :placeholder="$t('form.company_address.placeholder')" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.company_license.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <file-input
                                                        id="license"
                                                        label-class="btn-sm btn-light-primary"
                                                        v-model="user.companyLicense" />
                                                </div>
                                            </div>
                                        </fieldset>
                                        <hr class="my-4 my-lg-5" />
                                        <fieldset>
                                            <div class="row">
                                                <div class="col-md-6 offset-md-3">
                                                    <h6 class="mb-3 fw-semi-bold">
                                                        {{ $t('profile.section.contact') }}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.phone_number.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <text-input
                                                        id="phoneNumber"
                                                        :placeholder="$t('form.phone_number.placeholder')"
                                                        :error-message="$t('form.phone_number.error')"
                                                        v-model="v$.phone.$model"
                                                        :invalid="v$.phone.$errors.length"
                                                        @blur="v$.phone.$touch()" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.fax_number.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <text-input
                                                        id="fax"
                                                        :placeholder="$t('form.fax_number.placeholder')"
                                                        v-model="user.fax" />
                                                </div>
                                            </div>
                                        </fieldset>
                                        <hr class="my-4 my-lg-5" />
                                        <fieldset>
                                            <div class="row">
                                                <div class="col-md-6 offset-md-3">
                                                    <h6 class="mb-3 fw-semi-bold">
                                                        {{ $t('profile.section.additional') }}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mt-lg-1">
                                                        {{ $t('form.remarks.label') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <textarea-input
                                                        id="remark"
                                                        v-model="user.remark"
                                                        :placeholder="$t('form.remarks.placeholder')" />
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="!newUser"
                                class="tab-pane fade"
                                id="server-access"
                                role="tabpanel"
                                aria-labelledby="server-access-tab">
                                <div class="card border-0 bg-transparent">
                                    <div class="card-header border-bottom p-lg-4">
                                        <h5 class="card-title mb-1">
                                            {{ $t('profile.tab.server_access.title') }}
                                        </h5>
                                        <span class="text-muted fs-sm">
                                            {{ $t('profile.tab.server_access.subtitle') }}
                                        </span>
                                    </div>
                                    <div class="card-body p-lg-4">
                                        <table class="table align-middle fs-sm mb-5">
                                            <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">
                                                    {{ $t('layout.table_header.country') }}
                                                </th>
                                                <th scope="col">
                                                    {{ $t('layout.table_header.access') }}
                                                </th>
                                                <th scope="col">
                                                    {{ $t('layout.table_header.group') }}
                                                </th>
                                                <th scope="col">
                                                    {{ $t('layout.table_header.actions') }}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(server, serverIndex) in user.server_access"
                                                :key="serverIndex">
                                                <th scope="row">{{ parseInt(serverIndex) + 1 }}</th>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="symbol">
                                                            <img :src="'/assets/media/flags/square/' + server.country_iso + '.png'"
                                                                 class="rounded-circle" alt="" />
                                                            <i class="indicator"
                                                               aria-hidden="true"
                                                               :class="serverState[serverIndex] ? 'bg-success' : 'bg-danger'">
                                                            </i>
                                                        </div>
                                                        <div class="ms-3">
                                                            <div class="fw-bold">
                                                                {{ server.server_name }}
                                                            </div>
                                                            <div class="text-muted fs-xs o-75">
                                                                {{ serverState[serverIndex] ? $t('layout.enabled') : $t('layout.disabled') }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input
                                                        :id="'bookCR' + serverIndex"
                                                        type="checkbox"
                                                        class="btn-check"
                                                        autocomplete="off"
                                                        v-model="user.server_access[serverIndex].book_ol_cr"
                                                        :checked="server.book_ol_cr"
                                                        aria-label="bookCR">
                                                    <label
                                                        class="badge badge-access px-1 me-1"
                                                        :for="'bookCR' + serverIndex">
                                                        CR
                                                    </label>
                                                    <input
                                                        :id="'bookDR' + serverIndex"
                                                        type="checkbox"
                                                        class="btn-check"
                                                        autocomplete="off"
                                                        v-model="user.server_access[serverIndex].book_ol_dr"
                                                        :checked="server.book_ol_dr"
                                                        aria-label="bookDR">
                                                    <label
                                                        class="badge badge-access px-1 me-1"
                                                        :for="'bookDR' + serverIndex">
                                                        DR
                                                    </label>
                                                    <input
                                                        :id="'bookWM' + serverIndex"
                                                        type="checkbox"
                                                        class="btn-check"
                                                        autocomplete="off"
                                                        v-model="user.server_access[serverIndex].book_ol_wm"
                                                        :checked="server.book_ol_wm"
                                                        aria-label="bookWM">
                                                    <label
                                                        class="badge badge-access px-1 me-1"
                                                        :for="'bookWM' + serverIndex">
                                                        WM
                                                    </label>
                                                    <input
                                                        :id="'bookPK' + serverIndex"
                                                        type="checkbox"
                                                        class="btn-check"
                                                        autocomplete="off"
                                                        v-model="user.server_access[serverIndex].book_ol_pk"
                                                        :checked="server.book_ol_pk"
                                                        aria-label="bookWM">
                                                    <label
                                                        class="badge badge-access px-1 me-1"
                                                        :for="'bookPK' + serverIndex">
                                                        PK
                                                    </label>
                                                </td>
                                                <td>
                                                    <multiselect
                                                        v-if="serverGroups"
                                                        v-model="user.server_access[serverIndex].ftr_partner_group_id"
                                                        class="multiselect-solid multiselect-sm group-selector"
                                                        :options="serverGroups[user.server_access[serverIndex].server_prefix]"
                                                        :placeholder="$t('form.group.placeholder')" />
                                                </td>
                                                <td>
                                                    <bs-tooltip
                                                        #default="tipData"
                                                        :dataName="'data-tip-srv-' + serverIndex"
                                                        :title="serverState[serverIndex] ? 'Disable' : 'Enable'"
                                                        trigger="hover"
                                                        placement="top">
                                                        <button
                                                            type="button"
                                                            v-bind="tipData"
                                                            class="btn btn-sm btn-icon btn-light me-2"
                                                            :aria-label="serverState[serverIndex] ? $t('layout.disable') : $t('layout.enable')"
                                                            @click.prevent="setAccess(serverIndex, !serverState[serverIndex])">
                                                            <i class="icon"
                                                               :class="serverState[serverIndex] ? 'icon-toggle-right' : 'icon-toggle-left'"
                                                               aria-hidden="true"></i>
                                                        </button>
                                                    </bs-tooltip>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="!newUser"
                                class="tab-pane fade"
                                id="settings"
                                role="tabpanel"
                                aria-labelledby="settings-tab">
                                <div class="card border-0 bg-transparent">
                                    <div class="card-header border-bottom p-lg-4">
                                        <h5 class="card-title mb-1">
                                            {{ $t('profile.tab.settings.title') }}
                                        </h5>
                                        <span class="text-muted fs-sm">
                                            {{ $t('profile.tab.settings.subtitle') }}
                                        </span>
                                    </div>
                                    <div class="card-body p-lg-5">
                                        <fieldset>
                                        <div class="row">
                                            <div class="col-md-6 offset-md-3">
                                                <h6 class="mb-3 fw-semi-bold">
                                                    {{ $t('profile.section.api') }}
                                                </h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3 text-lg-end">
                                                <label class="form-label me-lg-4 mt-lg-1">
                                                    {{ $t('form.api_key.label') }}
                                                </label>
                                            </div>
                                            <div class="col-md-6">
                                                <text-input
                                                    id="apiKey"
                                                    wrapper-class="mb-0"
                                                    v-model="user.api_key" />
                                                <button type="button"
                                                        class="btn btn-sm btn-link p-0 mt-1"
                                                        @click="generateApiKey">
                                                    {{ $t('form.api_key.help') }}
                                                </button>
                                            </div>
                                        </div>
                                        </fieldset>
                                        <hr class="my-4 my-lg-5" />
                                        <fieldset>
                                            <div class="row">
                                                <div class="col-md-6 offset-md-3">
                                                    <h6 class="mb-3 fw-semi-bold">
                                                        {{ $t('profile.section.notifications') }}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div class="form-group row align-items-center mb-3">
                                                <div class="col-6 col-md-3 text-lg-end">
                                                    <label class="form-label me-lg-4 mb-lg-0">
                                                        {{ $t('form.check.enable_notifications') }}
                                                    </label>
                                                </div>
                                                <div class="col-6 text-end text-md-start">
                                                    <checkbox-input
                                                        id="enableNotifications"
                                                        wrapper-class="d-inline-block"
                                                        :switch="true"
                                                        v-model="user.enableNotifications"
                                                        :checked="user.enableNotifications" />
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-md-3 text-lg-end">
                                                    <label class="me-lg-4">
                                                        {{ $t('layout.when_to_email') }}
                                                    </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <checkbox-input
                                                        id="memberRegistered"
                                                        :label="$t('form.check.new_user_registered')"/>
                                                    <checkbox-input
                                                        id="orderCreated"
                                                        :label="$t('form.check.new_order_created')"/>
                                                    <checkbox-input
                                                        id="orderApproved"
                                                        :label="$t('form.check.on_order_approval')"/>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer text-end border-top py-4">
                        <button type="submit" class="btn btn-primary">
                            {{ newUser ? $t('btn.add_agent') : $t('btn.update_profile') }}
                        </button>
                    </div>
                </div>
            </form>
        </template>
        <template #sidebar v-if="!newUser">
            <sidebar class="d-none d-lg-block">
                <div class="card shadow">
                    <div class="card-body p-lg-4">
                        <div class="d-flex align-items-center mb-5">
                            <div class="symbol symbol-primary symbol-56">
                                <span class="symbol-caption rounded-circle font-size-h5">
                                    {{ userInitials(user.first_name, user.last_name) }}
                                </span>
                                <i class="indicator bg-success"></i>
                            </div>
                            <div class="ms-3">
                                <h6 class="mb-0">
                                    {{ userFullName(user.first_name, user.last_name) }}
                                </h6>
                                <div class="text-muted fs-sm">
                                    {{ user.email }}
                                </div>
                            </div>
                        </div>
                        <bs-nav type="pills" class="text-dark fs-sm flex-column">
                            <bs-nav-item>
                                <bs-nav-link
                                    class="w-100 fw-semi-bold"
                                    target="account-details"
                                    :label="$t('profile.tab.account.caption')"
                                    icon="user" tab active />
                            </bs-nav-item>
                            <bs-nav-item>
                                <bs-nav-link
                                    class="w-100 fw-semi-bold"
                                    target="company-profile"
                                    :label="$t('profile.tab.company.caption')"
                                    icon="briefcase" tab />
                            </bs-nav-item>
                            <bs-nav-item v-if="user.server_access">
                                <bs-nav-link
                                    class="w-100 fw-semi-bold"
                                    target="server-access"
                                    :label="$t('profile.tab.server_access.caption')"
                                    :badge="enabledServers"
                                    badge-variant="light-info"
                                    badge-color="info"
                                    badge-class="ms-auto"
                                    icon="toggle-right" badge-pill tab />
                            </bs-nav-item>
                            <bs-nav-item>
                                <bs-nav-link
                                    class="w-100 fw-semi-bold"
                                    target="settings"
                                    :label="$t('profile.tab.settings.caption')"
                                    icon="lock" tab />
                            </bs-nav-item>
                        </bs-nav>
                        <hr class="my-4">
                        <ul class="list-unstyled fs-sm">
                            <li class="d-flex align-items-center justify-content-between mb-2">
                                <span class="text-dark">
                                    {{ $t('profile.registered') }}
                                </span>
                                {{ registrationDate }}
                            </li>
                            <li class="d-flex align-items-center justify-content-between">
                                <span class="text-dark">
                                    {{ $t('profile.last_login') }}
                                </span>
                                {{ lastLoginDate }}
                            </li>
                        </ul>
                    </div>
                </div>
            </sidebar>
            <sidebar class="d-lg-none px-0">
                <div class="card card-custom border-0">
                    <div class="card-header border-0">
                        <div class="card-toolbar">
                            <bs-dropdown class="d-lg-none">
                                <bs-button
                                    variant="link"
                                    class="text-dark"
                                    icon="more-vertical"
                                    aria-label="userNav"
                                    icon-hidden dropdown-toggle no-caret />
                                <bs-dropdown-menu right>
                                    <bs-dropdown-item :label="$t('btn.send_message')" icon="star" />
                                    <bs-dropdown-item :label="$t('btn.delete')" class="text-danger" icon="trash-2" />
                                </bs-dropdown-menu>
                            </bs-dropdown>
                        </div>
                    </div>
                    <div class="card-body px-3 pb-5 pt-0">
                        <div class="d-flex flex-column align-items-center">
                            <div class="symbol symbol-primary symbol-56 mb-3">
                                <span class="symbol-caption rounded-circle font-size-h5">
                                    {{ userInitials(user.first_name, user.last_name) }}
                                </span>
                                <i class="indicator bg-success"></i>
                            </div>
                            <div class="text-center">
                                <h6 class="fw-bold mb-0 text-uppercase">
                                    {{ userFullName(user.first_name, user.last_name) }}
                                </h6>
                                <span class="text-muted fs-sm">
                                {{ user.email }}
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                <bs-nav class="nav-pills fs-sm nav-mobile mb-4 px-3">
                    <bs-nav-item>
                        <bs-nav-link
                            class="rounded-pill py-2"
                            target="account-details"
                            :label="$t('profile.tab.account.caption')"
                            tab active />
                    </bs-nav-item>
                    <bs-nav-item>
                        <bs-nav-link
                            class="rounded-pill py-2"
                            target="company-profile"
                            :label="$t('profile.tab.company.caption')"
                            tab />
                    </bs-nav-item>
                    <bs-nav-item v-if="user.server_access">
                        <bs-nav-link
                            class="rounded-pill py-2"
                            target="server-access"
                            :label="$t('profile.tab.server_access.caption')"
                            tab />
                    </bs-nav-item>
                    <bs-nav-item>
                        <bs-nav-link
                            target="settings"
                            class="rounded-pill py-2"
                            :label="$t('profile.tab.settings.caption')"
                            tab />
                    </bs-nav-item>
                </bs-nav>
            </sidebar>
        </template>
    </page-content>
</template>

<script>
import {ref, reactive, computed, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import { useI18n } from "vue-i18n"
import {email, required} from "@vuelidate/validators";
import Swal from "sweetalert2/dist/sweetalert2.js"
import useHelpers from "@/modules/helpers";
import useVuelidate from "@vuelidate/core";
import Sidebar from "@/components/Sidebar";
import Subheader from '@/components/Subheader';
import CountrySelect from "@/components/Form/CountrySelect";
import PasswordInput from "@/components/Form/PasswordInput";
import CheckboxInput from "@/components/Form/CheckboxInput";
import TextInput from "@/components/Form/TextInput";
import TextareaInput from "@/components/Form/TextareaInput";
import FileInput from "@/components/Form/FileInput";
import Multiselect from '@vueform/multiselect';
import Api from '@/modules/api';
import PageContent from "@/components/PageContent";
import useUser from "@/modules/user";
import Tooltip from '@/components/Bootstrap/Tooltip/Tooltip';
import NavItem from "@/components/Bootstrap/Nav/NavItem";
import NavLink from "@/components/Bootstrap/Nav/NavLink";
import Nav from "@/components/Bootstrap/Nav/Nav";
import Dropdown from "@/components/Bootstrap/Dropdown/Dropdown";
import DropdownMenu from "@/components/Bootstrap/Dropdown/DropdownMenu";
import DropdownItem from "@/components/Bootstrap/Dropdown/DropdownItem";
import Button from "@/components/Bootstrap/Button/Button";

export default {
    components: {
        PageContent,
        Sidebar,
        Subheader,
        CountrySelect,
        PasswordInput,
        CheckboxInput,
        TextInput,
        TextareaInput,
        FileInput,
        Multiselect,
        bsTooltip: Tooltip,
        bsNav: Nav,
        bsNavItem: NavItem,
        bsNavLink: NavLink,
        bsDropdown: Dropdown,
        bsDropdownMenu: DropdownMenu,
        bsDropdownItem: DropdownItem,
        bsButton: Button
    },
    setup() {
        const { t } = useI18n({ useScope: 'global' })
        const {
            dateToStr,
            userInitials,
            userFullName,
            validateTabs
        } = useHelpers
        const route = useRoute()
        const router = useRouter()
        const serverGroups = ref(null)
        const profileTabPanes = ref(null)
        const { deleteUser } = useUser

        const user = reactive({
            account_id: null,
            login: '',
            email: '',
            first_name: '',
            last_name: '',
            country_iso: '',
            city: '',
            phone: '',
            companyLicense: [],
            password: null,
            email_activation: null
        })

        const newUser = computed(() => {
            return !parseInt(route.params.id);
        })

        const registrationDate = computed(() => {
            if (user.registration_date !== undefined && user.registration_date) {
                return dateToStr(user.registration_date);
            }

            return '-';
        })

        const lastLoginDate = computed(() => {
            if (user.last_login_date !== undefined && user.last_login_date) {
                return dateToStr(user.last_login_date);
            }

            return '-';
        })

        // Form validation rules
        let rules = {
            email: { required, email },
            country_iso: { required },
            city: { required },
            company_name: { required },
            phone: { required },
            first_name: { required },
            last_name: { required }
        };

        if (!newUser.value) {
            rules = {...rules, login: { required }}
        }

        const enabledServers = computed(() => {
            return serverState.value.reduce((a, b) => a + (b == undefined || b), 0);
        })

        const serverState = computed(() => {
            //return user.value.server_access.map( s => s.book_ol_cr || s.book_ol_dr || s.book_ol_wm || s.book_ol_pk)
            return user.value.server_access.map( s => (s.is_deleted == undefined || !s.is_deleted))
        })

        const setAccess = (idx, access) => {
            //user.value.server_access[idx].book_ol_cr = access;
            //user.value.server_access[idx].book_ol_dr = access;
            //user.value.server_access[idx].book_ol_wm = access;
            //user.value.server_access[idx].book_ol_pk = access;
            user.value.server_access[idx].is_deleted = !access;
        }

        const v$ = useVuelidate(rules, user);

        if (!newUser.value) watch(v$, () => {
            validateTabPanes();
        });

        const validateTabPanes = async () => {
            await v$.value.$touch();
            const tabPanes = profileTabPanes.value.getElementsByClassName('tab-pane');
            validateTabs(tabPanes);

            return !v$.value.$invalid;
        }

        const saveUserDetails = async () => {
            if (await validateTabPanes()) {
                user.inform_user = !!user.password;
                if (newUser.value) {
                    let hostParts = location.host.split('.');
                    hostParts[0] = 'partner';
                    const partner = await Api.addPartner(
                        user,
                        t.value,
                        location.protocol + '//' + hostParts.join('.') + '/activation/'
                        //location.protocol + '//' + hostParts.join('.') + router.resolve({name: 'login_activation'}).fullPath + '/'
                    );

                    if (partner.account_id) {
                        router.replace({name: 'partner_details', params: {id: partner.account_id}});
                        beforeCreate();
                    }
                } else {
                    await Api.updatePartner(user);
                }
            }
        }

        const generateApiKey = async () => {
            Swal.fire({
                title: t('alert.generate_api_key.title'),
                text: t('alert.generate_api_key.text'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('alert.generate_api_key.action'),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const result = await Api.generateApiKey(user.value.account_id);
                    if (result.api_key) {
                        user.value.api_key = result.api_key;
                    }
                }
            })
        }

        const beforeCreate = async() => {
            if (!newUser.value) user.value = Object.assign(user, await Api.getPartner(route.params.id));
            else user.server_access = [];
            const partnerTypes = await Api.getPartnerTypes();
            serverGroups.value = partnerTypes.reduce((res, type) => {
                (res[type.server_prefix] || (res[type.server_prefix] = [{value: '', label: '-'}])).push({value: type.ftr_id, label: type.name});

                return res;
            }, {});

            (await Api.getServersList()).forEach(s => {
                if (!user.server_access.find( f => f.server_prefix == s.server_prefix)) {
                    user.server_access.push({
                        is_deleted: true, // not in partner's access list
                        server_prefix: s.server_prefix,
                        country_iso: s.server_prefix.substr(0, 2),
                        server_name: s.server_name,
                        book_ol_cr: null,
                        book_ol_dr: null,
                        book_ol_wm: null,
                        book_ol_pk: null,
                        ftr_partner_group_id: null,
                    });
                }
            });
        }

        beforeCreate();

        return {
            v$,
            user,
            saveUserDetails,
            profileTabPanes,
            enabledServers,
            serverState,
            setAccess,
            serverGroups,
            userFullName,
            userInitials,
            registrationDate,
            lastLoginDate,
            newUser,
            deleteUser,
            generateApiKey
        }
    }
}
</script>

<style lang="scss">
@import "./src/assets/styles/vendors/sweetalert2/sweetalert2";
.group-selector {
    min-width: 80px;
}
</style>