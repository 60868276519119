<template>
    <div class="tab-pane fade"
         :class="{'show active': selected}"
         :id="id"
         role="tabpanel"
         :aria-labelledby="id + '-tab'">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "TabPane",
    props: {
        id: {
            type: String,
            required: true
        },
        selected: Boolean
    }
}
</script>