<template>
    <component
        :is="htmlTag"
        class="list-group"
        :class="{
            'list-group-flush': flush,
            'list-group-numbered': numbered,
            'list-group-horizontal': horizontal
        }"
        v-bind="attrs">
        <slot />
    </component>
</template>

<script>
import {ref, watchEffect} from "vue"

export default {
    name: "bsListGroup",
    props: {
        tag: {
            type: String,
            default: 'ul'
        },
        flush: Boolean,
        numbered: Boolean,
        horizontal: Boolean,
        tablist: Boolean
    },
    setup(props, {attrs}) {
        const htmlTag = ref(props.tag)

        watchEffect(() => {
            if (props.numbered)
                htmlTag.value = 'ol'

            if (props.tablist)
                attrs = {...attrs, 'role': 'tablist'}
        })

        return {
            htmlTag,
            attrs
        }
    }
}
</script>