<template>
    <component
        :is="tag"
        class="dropdown-menu"
        :class="{ 'dropdown-menu-end': right }"
        v-bind="attrs">
        <slot />
    </component>
</template>

<script>
export default {
    name: "bsDropdownMenu",
    props: {
        tag: {
            type: String,
            default: 'ul'
        },
        right: Boolean
    },
    setup( props, { attrs }) {
        return {
            attrs
        }
    }
}
</script>