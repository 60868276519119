<template>
    <div class="page-wrapper">
        <app-header />
        <router-view />
        <app-footer />
        <app-nav />
        <app-events />
    </div>
</template>

<script>
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import bsToast from '@/components/Bootstrap/Toast/Toast';

export default {
    components: {
        'app-header': Header,
        'app-footer': Footer,
        'app-nav': Navigation,
        'app-events': bsToast
    },
    setup() {
    }
}
</script>
