<template>
    <div class="server-settings card shadow">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="symbol">
                    <img :src="'/assets/media/flags/square/' + server.country_iso + '.png'"
                         class="rounded-circle" :alt="server.country_iso" />
                    <i class="indicator"
                       aria-hidden="true"
                       :class="!!server.ftr_partner_group_id ? 'bg-success' : 'bg-danger'">
                    </i>
                </div>
                <div class="flex-grow-1 ms-3">
                    <div class="d-flex align-items-center justify-content-between">
                        <h6 class="mb-0">
                            {{ server.server_name }}
                        </h6>
                        <span class="badge bg-primary rounded-pill p-1">
                            {{ server.ftr_partner_group_name }}
                        </span>
                    </div>
                    <bs-tooltip
                        v-for="(type, typeIndex) in bookingTypes"
                        :key="typeIndex"
                        #default="bookingData"
                        :dataName="'book_ol_' + type + serverIndex"
                        :title="$t('data_server_access.book_ol_' + type)"
                        trigger="hover"
                        placement="top">
                        <span
                            v-bind="bookingData"
                            class="badge cursor-pointer p-0 me-1"
                            :class="badgeClasses(server['book_ol_' + type])">
                            {{ type.toUpperCase() }}
                        </span>
                    </bs-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue';
import bsTooltip from '@/components/Bootstrap/Tooltip/Tooltip';

const bookingTypes = [ 'cr', 'dr', 'wm', 'pk']

export default {
    name: "PartnerDataServer",
    components: {
        bsTooltip
    },
    props: {
        serverData: {
            server_name: String,
            ftr_partner_group_id: [String, Number],
            ftr_partner_group_name: String,
            country_iso: String,
            book_ol_cr: String,
            book_ol_dr: String,
            book_ol_wm: String,
            book_ol_pk: String
        },
        index: Number
    },
    setup(props) {
        const server = ref(props.serverData);
        const serverIndex = ref(props.index);
        const badgeClasses = (status) => {
            return status ? 'text-success' : 'text-danger'
        }

        return {
            server,
            serverIndex,
            bookingTypes,
            badgeClasses
        }
    }
}
</script>