<template>
    <component
        :is="tag"
        class="breadcrumb-item"
        :class="{'active': current}"
        :aria-current="current ? 'page' : null">
        <slot />
    </component>
</template>

<script>
export default {
    name: "BreadcrumbItem",
    props: {
        tag: {
            type: String,
            default: 'li'
        },
        current: Boolean
    }
}
</script>