<template>
    <component :is="tag" class="card-text">
        <slot>{{ content }}</slot>
    </component>
</template>

<script>
export default {
    name: "bsCardText",
    props: {
        tag: {
            type: String,
            default: 'p'
        },
        content: String
    }
};
</script>