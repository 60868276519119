<template>
    <nav class="navbar fixed-top navbar-dark py-md-4 px-md-5">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img :src="logo" alt="Efrito"/>
            </a>
        </div>
    </nav>
    <div class="login-wrapper d-flex flex-column-reverse flex-md-row">
        <div class="login-content d-flex align-items-center justify-content-center p-4 p-md-5 flex-grow-1">
            <slot/>
        </div>
        <div class="login-banner"
             :style="{ backgroundImage: 'url(\'' + background + '\')' }">
        </div>
    </div>
</template>

<script>
import {ref} from "vue";

export default {
    name: "LoginContent",
    props: {
        dataLogo: {
            type: String,
            default: '/assets/logo-light.png'
        },
        dataBackground: {
            type: String,
            default: '/assets/media/pexels-andrea-piacquadio.jpg'
        }
    },
    setup (props) {
        const logo = ref(props.dataLogo);
        const background = ref(props.dataBackground);

        return {
            logo,
            background
        }
    }
}
</script>

<style lang="scss">
    @import "./src/assets/styles/pages/login/login";
</style>