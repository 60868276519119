<template>
    <li v-for="server in dataServers" :key="server.country_iso">
        <a href="#"
           class="dropdown-item d-flex align-items-center"
           :class="{ active: server.server_name === currentServer }"
           @click.prevent="changeCurrentServer(server.server_name)">
            <img :src="'/assets/media/flags/' + server.country_iso.toUpperCase() + '.png'"
                 :alt="server"
                 class="country-flag me-2">
            {{ server.server_name }}
        </a>
    </li>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    setup() {
        const store = useStore();

        return {
            dataServers: computed(() => store.getters.userDataServers ),
            currentServer: computed(() => store.getters.dataServer ),
            changeCurrentServer: (val) => store.commit('setDataServer', val),
        }
    }
}
</script>

<style scoped>
    .country-flag {
        width: 20px;
        height: auto;
    }
</style>