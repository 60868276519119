<template>
    <component
        :id="elID"
        class="nav-link wizard-step"
        :class="{ 'active': isSelected }"
        :is="tag"
        :data-bs-target="target"
        :aria-controls="target.substring(1)"
        :aria-selected="isSelected ? 'true' : 'false'"
        role="tab"
        v-bind="attrs">
        <slot />
    </component>
</template>

<script>
import {ref, computed} from "vue";

export default {
    name: "WizardStep",
    props: {
        id: String,
        tag: {
            type: String,
            default: 'div'
        },
        dataTarget: {
            type: String,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { attrs}) {
        const target = ref(props.dataTarget);
        const isSelected = ref(props.selected);
        const elID = computed(() => {
            return props.id ? props.id : props.dataTarget.substring(1) + '-tab'
        })

        return {
            props,
            attrs,
            target,
            isSelected,
            elID
        }
    }
}
</script>