<template>
    <div class="card-toolbar">
        <slot />
    </div>
</template>

<script>
export default {
    name: "CardToolbar"
}
</script>
