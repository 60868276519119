<template>
    <component
        :is="htmlTag"
        :href="href"
        class="btn"
        :class="cssClass"
        :type="btnType"
        v-bind="attrs">
        <slot>
            <bs-icon
                v-if="icon"
                :icon="icon"
                :size="iconSize"
                :hidden="iconHidden"
                :class="{ 'me-2': label }" />
            {{ label }}
        </slot>
    </component>
</template>

<script>
import {computed, watchEffect} from "vue";
import bsIcon from "@/components/Bootstrap/Icon/Icon"

export default {
    name: "bsBtn",
    components: { bsIcon },
    inheritAttrs: false,
    props: {
        label: String,
        icon: String,
        iconSize: String,
        iconHidden: Boolean,
        size: String,
        variant: String,
        href: String,
        target: String,
        type: {
            type: String,
            default: 'button'
        },
        disabled: Boolean,
        dropdownToggle: Boolean,
        collapse: Boolean,
        ariaControls: String,
        noCaret: Boolean
    },
    setup(props, { attrs }) {

        const cssClass = computed(() => {
            return [
                `btn-${props.variant || 'secondary'}`,
                props.size && `btn-${props.size}`,
                props.disabled && 'disabled',
                props.dropdownToggle && 'dropdown-toggle',
                props.noCaret && 'no-caret',
                props.icon && !props.label && 'btn-icon'
            ]
        })

        watchEffect(() => {
            if (props.disabled) {
                attrs = {
                    ...attrs,
                    "tabindex": "-1",
                    "aria-disabled": "true"
                }
            }

            if (props.dropdownToggle) {
                attrs = {
                    ...attrs,
                    "aria-expanded": "false",
                    "data-bs-toggle": "dropdown"
                }
            }

            if (props.collapse) {
                attrs = {
                    ...attrs,
                    "data-bs-toggle": "collapse",
                    "aria-expanded": "false",
                    "aria-controls": props.ariaControls || props.target || props.href
                }

                if ( !props.href && props.target ) {
                    attrs = {...attrs, "data-bs-target":  props.target }
                }
            }

            if (props.href) {
                attrs = { ...attrs, role: "button" }
            }
        })

        return {
            cssClass,
            htmlTag: computed(() => props.href ? 'a' : 'button'),
            btnType: computed(() => !props.href ? props.type : null ),
            attrs
        }
    }
}
</script>