<template>
    <component :is="tag" :class="wrapperClassName">
        <form-label v-if="label" :for="id" :required="isRequired" :optional="isOptional">
            {{ label }}
        </form-label>
        <div class="form-error-wrap" v-if="!!errorMessage">
            <div :class="inputGroupClassName">
                <span v-if="showIcon" class="input-group-text">
                    <i class="icon icon-lock" aria-hidden="true"></i>
                </span>
                <input
                    :class="inputClassName"
                    v-bind="attrs"
                    :id="id"
                    :name="name"
                    :type="inputType"
                    :value="inputValue"
                    :placeholder="placeholder"
                    @input="handleInput"
                    autocomplete="new-password"
                    aria-label="Password"/>
                <button
                    class="btn btn-outline-secondary text-dark shadow-none"
                    type="button"
                    aria-label="showPassword"
                    @click.prevent="toggleVisibility">
                    <i class="icon o-50"
                       :class="inputType === 'password' ? 'icon-eye' : 'icon-eye-off'"
                       aria-hidden="true">
                    </i>
                </button>
            </div>
            <span class="form-error">{{ errorMessage}}</span>
        </div>
        <div :class="inputGroupClassName" v-else>
            <span v-if="showIcon" class="input-group-text">
                <i class="icon icon-lock" aria-hidden="true"></i>
            </span>
            <input
                :class="inputClassName"
                v-bind="attrs"
                :id="id"
                :name="name"
                :type="inputType"
                :value="inputValue"
                :placeholder="placeholder"
                @input="handleInput"
                autocomplete="new-password"
                aria-label="Password"/>
            <button
                class="btn btn-outline-light"
                type="button"
                aria-label="showPassword"
                @click.prevent="toggleVisibility">
                <i class="icon"
                   :class="inputType === 'password' ? 'icon-eye' : 'icon-eye-off'"
                   aria-hidden="true">
                </i>
            </button>
        </div>
        <button
            v-if="enableGenerator"
            role="button"
            class="btn btn-link btn-sm p-0 mt-2"
            @click.prevent="generatePasswd">
            <i class="icon icon-toggle-left me-1"></i>
            {{ $t('btn.generate_password') }}
        </button>
        <div v-if="formText" class="form-text">{{ formText }}</div>
    </component>
</template>

<script>
import { computed, ref, watchEffect } from "vue";
import FormLabel from "@/components/Form/FormLabel";

export default {
    name: "PasswordInput",
    components: {
        FormLabel
    },
    props: {
        id: {
            type: String,
            required: true
        },
        required: Boolean,
        optional: Boolean,
        placeholder: String,
        name: String,
        label: String,
        size: String,
        invalid: Number,
        formText: String,
        wrapperClass: String,
        inputGroupClass: String,
        errorMessage: String,
        enableGenerator: {
            type: Boolean,
            default: false
        },
        showIcon: {
            type: Boolean,
            default: false
        },
        tag: {
            type: String,
            default: "div"
        },
        modelValue: [String, Number]
    },
    emits: ["update:modelValue"],
    setup(props, { attrs, emit }) {
        const isRequired = ref(props.required)
        const isOptional = ref(props.optional)
        const inputValue = ref(props.modelValue)
        const inputType = ref('password')

        const inputGroupClassName = computed(() => {
            return [
                "input-group",
                props.inputGroupClass,
                props.invalid && 'is-invalid'
            ];
        });

        const wrapperClassName = computed(() => {
            return [
                'password-input',
                props.wrapperClass
            ]
        })

        const inputClassName = computed(() => {
            return [
                "form-control",
                props.size && `form-control-${props.size}`
            ];
        });

        function handleInput(e) {
            inputValue.value = e.target.value
            emit("update:modelValue", inputValue.value)
        }

        const toggleVisibility = () => {
            inputType.value = inputType.value === "password" ? "text" : "password";
        };

        const generatePasswd = () => {
            let pass = '';
            const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
                'abcdefghijklmnopqrstuvwxyz0123456789@#$';

            for (let i = 1; i <= 8; i++) {
                let char = Math.floor(Math.random() * str.length + 1);
                pass += str.charAt(char)
            }

            inputType.value = 'text';
            inputValue.value = pass;
            emit("update:modelValue", inputValue.value);
        }

        watchEffect(() => (inputValue.value = props.modelValue));

        return {
            isRequired,
            isOptional,
            inputValue,
            inputType,
            handleInput,
            inputGroupClassName,
            wrapperClassName,
            toggleVisibility,
            generatePasswd,
            inputClassName,
            attrs,
            props
        };
    }
};
</script>