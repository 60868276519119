<template>
    <component :is="tag" class="card-title">
        <slot>{{ content }}</slot>
    </component>
</template>

<script>
export default {
    name: "bsCardTitle",
    props: {
        tag: {
            type: String,
            default: 'h5'
        },
        content: String
    }
};
</script>