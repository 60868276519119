<template>
    <div class="server-settings card border-0 bg-light">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="d-flex align-items-center flex-grow-1">
                    <div class="symbol">
                        <img :src="'/assets/media/flags/square/' + server.country_iso + '.png'"
                             class="rounded-circle" :alt="server.country_iso" />
                        <i class="indicator"
                           aria-hidden="true"
                           :class="!!server.group_id ? 'bg-success' : 'bg-danger'">
                        </i>
                    </div>
                    <div class="ms-3">
                        <div class="fw-semi-bold text-dark">
                            {{ server.server_name }}
                        </div>
                        <button
                            type="button"
                            class="btn btn-link btn-sm p-0 text-muted">
                            <span class="text-lowercase">
                                {{ server.group_id ? accessGroups[server.group_id] : $t('layout.disabled') }}
                            </span>
                            <i class="icon icon-edit text-primary"
                               :data-value="index"
                               v-if="!!server.group_id"
                               @click.prevent="$emit('click', $event)" />
                        </button>
                    </div>
                </div>
                <checkbox-input type="checkbox"
                    :id="'server_' + index"
                    :name="'server_' + index"
                    wrapper-class="d-inline-block"
                    :switch="true"
                    :data-value="index"
                    @click="$emit('click', $event)"
                    v-model="inputValue"/>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, computed} from "vue";
import CheckboxInput from "@/components/Form/CheckboxInput";

export default {
    name: "StaffDataServer",
    components: {
        CheckboxInput
    },
    props: {
        modelValue: [String, Number, Boolean],
        dataServer: {
            country_iso: String,
            group_id: String,
            server_name: String
        },
        dataServerIndex: [String, Number],
        accessGroups: Object,
    },
    emits: ["update:modelValue", "click"],
    setup(props, {attrs, emit}) {
        const server = ref(props.dataServer);
        const index = ref(props.dataServerIndex);
        const groups = ref();

        const inputValue = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value),
        })

        return {
            server,
            index,
            groups,
            inputValue,
            attrs
        }
    }
}
</script>

<style lang="scss" scoped>
    .server-settings {
        .btn > .icon {
            visibility: hidden;
            opacity: 0;
            transition: all .3s ease;
        }

        &:hover .btn > .icon {
            visibility: visible;
            opacity: 1;
        }
    }
</style>