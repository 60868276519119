<template>
    <login-content>
        <div class="login-form">
            <div class="text-center">
                <h1 class="fs-2 fw-bold">
                    {{ $t('page.password_reset.title') }}
                </h1>
                <p class="text-muted mb-5">
                    {{ $t('page.password_reset.subtitle') }}
                </p>
            </div>
            <form class="form" @submit.prevent="resetPassword">
                <div class="form-group">
                    <password-input
                        id="password"
                        :placeholder="$t('form.password.placeholder')"
                        input-group-class="input-group-solid"
                        v-model="v$.password.$model"
                        :invalid="v$.password.$errors.length"
                        @blur="v$.password.$touch()" />
                    <div class="form-text text-danger" v-if="v$.password.$errors.length">
                        {{ $t('form.password.help') }}
                    </div>
                </div>
                <button type="submit" class="btn btn-primary w-100">
                    {{ $t('btn.reset_password') }}
                </button>
            </form>
        </div>
    </login-content>
</template>

<script>
import { ref } from "vue";
import LoginContent from "@/components/LoginContent";
import { required, minLength } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import PasswordInput from "@/components/Form/PasswordInput";
import Api from '@/modules/api'
import {useRoute, useRouter} from "vue-router";

export default {
    name: "PasswordReset",
    components: {
        LoginContent,
        PasswordInput
    },
    setup() {
        const password = ref(null)
        const resetKey = useRoute().params.key;
        const router = useRouter();

        let rules = {
            password: {
                minLength: minLength(8),
                required
            }
        };

        const v$ = useVuelidate(rules, {password});

        const resetPassword = async () => {
            v$.value.$touch();

            if (!v$.value.$invalid && resetKey) {
                const result = await Api.resetPassword({key: resetKey, password: password.value});
                if (result.result && 'OK' === result.result) {
                    router.replace({name: 'login'});
                }
            }
        }

        return {
            v$,
            resetPassword
        }
    }
}
</script>

<style lang="scss" scoped>
    .form-control {
        padding: 1.25rem;
    }
</style>