<template>
    <input
        type="file"
        :name="name + '[]'"
        class="form-file"
        :id="id"
        multiple
        @input="handleInput"
    />
    <label :for="id" :class="inputLabelClassName">
        <i :class="labelIcon"></i>
        {{ inputLabel }}
    </label>
</template>

<script>
import { computed, ref, watchEffect } from "vue";
import { useI18n } from 'vue-i18n';

export default {
    name: "FileInput",
    props: {
        id: {
            type: String,
            required: true
        },
        invalid: Number,
        name: String,
        icon: String,
        label: String,
        size: String,
        formText: String,
        labelClass: {
            type: String,
            default: 'btn-secondary'
        },
        modelValue: Object,
        value: String
    },
    emits: ["update:modelValue"],
    setup(props, { attrs, emit }) {
        const { t } = useI18n({ useScope: 'global' });
        const inputValue = ref(props.modelValue);
        const labelRef = ref(null);

        const labelIcon = computed( ()=> {
            return [
                'icon',
                props.labelIcon ? props.labelIcon : 'icon-upload'
            ]
        })

        const wrapperClassName = computed(() => {
            return [ props.wrapperClass || "form-group" ];
        });

        const inputLabelClassName = computed(() => {
            return [
                "form-file-label btn",
                props.labelClass
            ];
        });

        function handleInput(e) {
            inputValue.value = e.target.files;
            console.log(typeof inputValue.value)
            emit("update:modelValue", inputValue.value);
        }

        const inputLabel = computed( () => {
            if (props.modelValue.length) {
                if ( props.modelValue.length > 1 ) {
                    const files = props.modelValue.length
                    return t('file_input.files', files, { count: files });
                } else {
                    return props.modelValue[0].name
                }
            }
            return props.label ? props.label : t('file_input.label');
        })

        watchEffect(() => (inputValue.value = props.modelValue));

        return {
            inputValue,
            labelRef,
            handleInput,
            wrapperClassName,
            inputLabelClassName,
            inputLabel,
            labelIcon,
            attrs,
            props,
            t
        };
    }
};
</script>

<style lang="scss">
@import "./src/assets/styles/vendors/file-input";
</style>