<template>
    <img :src="src" :alt="alt" :class="imgCssClass" />
</template>

<script>
import {computed} from "vue"

export default {
    name: "bsCardTitle",
    props: {
        src: {
            type: String,
            default: null
        },
        alt: {
            type: String,
            default: null
        },
        top: Boolean,
        bottom: Boolean,
        class: String
    },
    setup(props) {
        const imgCssClass = computed(() => {
            return [
                props.top && 'card-img-top',
                props.bottom && 'card-img-bottom',
                props.class || null
            ]
        })

        return {
            imgCssClass
        }
    }
};
</script>