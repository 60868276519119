<template>
    <component :is="wrapper" aria-label="breadcrumb">
        <component :is="breadcrumbTag" class="breadcrumb" :class="breadcrumbClass">
            <slot/>
        </component>
    </component>
</template>

<script>
export default {
    name: "Breadcrumb",
    props: {
        wrapper: {
            type: String,
            default: 'nav'
        },
        breadcrumbTag: {
            type: String,
            default: 'ol'
        },
        breadcrumbClass: String
    }
}
</script>