<template>
    <login-content>
        <div class="login-form" v-if="action !== 'forgot'">
            <div class="text-center">
                <h1 class="fs-2 fw-bold">{{ $t('page.login.title') }}</h1>
                <p class="text-muted mb-5">
                    {{ $t('page.login.title') }}
                </p>
            </div>
            <div v-if="false" class="alert alert-danger" role="alert">
            </div>
            <form class="form" @submit.prevent="login" autocomplete="off">
                <div class="form-group mb-3">
                    <div class="input-group input-group-solid" :class="{ 'is-invalid': v$.username.$errors.length }">
                        <span class="input-group-text text-primary" id="login-username">
                            <i class="icon icon-user" aria-hidden="true"></i>
                        </span>
                        <input class="form-control"
                               type="text"
                               :placeholder="$t('form.username.label')"
                               name="username"
                               autocomplete="off"
                               aria-label="username"
                               aria-describedby="#login-username"
                               v-model.trim="v$.username.$model"
                               @blur="v$.username.$touch()"/>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <div class="input-group input-group-solid" :class="{ 'is-invalid': v$.password.$errors.length }">
                        <span class="input-group-text text-primary" id="login-password">
                            <i class="icon icon-lock" aria-hidden="true"></i>
                        </span>
                        <input class="form-control"
                               :type="passwdType"
                               :placeholder="$t('form.password.label')"
                               name="password"
                               aria-label="password"
                               aria-describedby="#login-password"
                               v-model.trim="v$.password.$model"
                               @blur="v$.password.$touch()"/>
                        <button class="btn btn-outline-secondary text-dark shadow-none"
                                type="button"
                                aria-label="showPassword"
                                @click.prevent="togglePassword">
                            <i class="icon o-50"
                               :class="passwdType === 'password' ? 'icon-eye' : 'icon-eye-off'"
                               aria-hidden="true">
                            </i>
                        </button>
                    </div>
                </div>
                <div class="d-flex flex-wrap justify-content-between align-items-center mb-5">
                    <div class="form-check">
                        <input class="form-check-input"
                               type="checkbox"
                               id="remember"
                               name="remember">
                        <label class="form-check-label" for="remember">
                            {{ $t('btn.remember_me') }}
                        </label>
                    </div>
                    <a href="#"
                       class="text-muted text-hover-primary"
                       @click.prevent="toggleForm">
                        {{ $t('btn.forgot_pwd') }}
                    </a>
                </div>
                <div class="d-grid gap-2 mb-4">
                    <button type="submit" class="btn btn-primary">
                        {{ $t('btn.login') }}
                    </button>
                </div>
                <p class="text-center text-dark mb-0" v-if="registerLink.matched.length > 0">
                    <i18n-t keypath="sign_up.text" for="sign_up.link">
                        <router-link :to="{name: 'register'}">{{ $t('sign_up.link') }}</router-link>
                    </i18n-t>
                </p>
            </form>
        </div>
        <div class="login-form" v-else>
            <div class="text-center">
                <h1 class="fs-2 fw-bold">
                    {{ $t('page.pwd_recovery.title') }}
                </h1>
                <p class="text-muted mb-5">
                    {{ $t('page.pwd_recovery.subtitle') }}
                </p>
            </div>
            <form class="form" @submit.prevent="recoverPassword">
                <div class="form-group mb-3">
                    <div class="input-group input-group-solid" :class="{ invalid: v$.email.$errors.length }">
                        <span class="input-group-text text-primary" id="recover-email">
                            <i class="icon icon-user" aria-hidden="true"></i>
                        </span>
                        <input class="form-control"
                               type="email"
                               :placeholder="$t('form.email.placeholder')"
                               name="email"
                               autocomplete="off"
                               aria-label="username"
                               aria-describedby="#recover-email"
                               v-model.trim="v$.email.$model"
                               @blur="v$.email.$touch()"/>
                    </div>
                </div>
                <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-primary mb-3">
                        {{ $t('btn.recover_pwd') }}
                    </button>
                    <button class="btn btn-link" @click.prevent="toggleForm">
                        {{ $t('btn.back_login') }}
                    </button>
                </div>
            </form>
        </div>
    </login-content>
</template>

<script>
import {useRoute, useRouter} from 'vue-router';
import {ref, computed} from 'vue';
import useVuelidate from '@vuelidate/core'
import Api from '@/modules/api'
import Store from '@/modules/store'
import LoginContent from "@/components/LoginContent";
import {useI18n} from "vue-i18n";

export default {
    name: "UserLogin",
    components: {
        LoginContent
    },
    setup() {
        const { locale } = useI18n();
        const username = ref('');
        const password = ref('');
        const email = ref('');
        const action = ref('login');
        const passwdType = ref('password');
        const error = ref(null);

        const rules = computed(() => ({
            username: {
                required: action.value === 'login'
            },
            password: {
                required: action.value === 'login'
            },
            email: {
                required: action.value === 'forgot'
            }
        }));

        const v$ = useVuelidate(rules, {username, password, email});
        const apiKey = useRoute().params.key;
        const router = useRouter();
        const registerLink = router.resolve('register');

        const login = async () => {
            v$.value.$touch();

            if (!v$.value.$invalid || apiKey) {
                Store.commit('setEvents', []);
                const res = await Api.login(apiKey ? {temporary_token: apiKey} : {login: username.value, password: password.value});
                if (res.error == undefined) {

                    let hostParts = location.host.split('.');
                    const userGroup = res.groups.find(g => g.def).name;

                    // if subdomain == user group or test
                    if (['admin', 'partner'].indexOf(hostParts[0]) < 0 || userGroup == hostParts[0]) {
                        await router.replace({name: 'home'});
                    } else {
                        hostParts[0] = userGroup;
                        location.replace(location.protocol + '//' + hostParts.join('.') + location.pathname);
                    }
                } else {
                    //console.log(res.error);
                    //console.log(store);
                }
            }
        };

        const recoverPassword = async () => {
            v$.value.$touch();

            if (!v$.value.$invalid) {
                await Api.recoverPassword({
                    email: email.value,
                    locale: locale.value,
                    recovery_link: window.location.origin + router.resolve({name: 'password_reset'}).fullPath + '/'
                });
            }
        }

        const togglePassword = () => {
            passwdType.value = passwdType.value === "password" ? "text" : "password";
        };

        const toggleForm = () => {
            action.value = action.value === "login" ? "forgot" : "login";
            v$.value.$reset();
        };

        if (apiKey) {
          let hostParts = location.host.split('.');
          if ('admin' == hostParts[0]) {
            hostParts[0] = 'partner';
            location.replace(location.protocol + '//' + hostParts.join('.') + location.pathname);
            return false;
          }

          login();
        }

        return {
            v$,
            username,
            password,
            email,
            passwdType,
            action,
            toggleForm,
            togglePassword,
            recoverPassword,
            login,
            error,
            registerLink
        };
    },
}
</script>
