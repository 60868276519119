<template>
    <page-content v-if="user">
        <template #header>
            <subheader class="d-none d-lg-flex" enable-sticky>
                <div class="d-none d-lg-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <h1 class="subheader-title h6 mb-0">
                            {{ $t('profile.my_profile') }}
                        </h1>
                        <div class="subheader-divider subheader-divider-ver mx-3 d-none d-md-block"></div>
                        <bs-breadcrumb class="d-none d-lg-block">
                            <bs-breadcrumb-item class="text-capitalize" current>
                                {{ userFullName(user.additional_data.first_name, user.additional_data.last_name) }}
                            </bs-breadcrumb-item>
                        </bs-breadcrumb>
                    </div>
                </div>
            </subheader>
        </template>
        <template #main>
            <bs-tab-content ref="profileTabPanes" id="profileTabPanes">
                <bs-tab-pane id="account-details" selected>
                    <bs-card class="mb-4" header-class="p-lg-4" body-class="p-lg-5" :title="$t('profile.tab.account.title')" shadow>
                        <h6 class="mb-3">
                            {{ $t('profile.section.personal') }}
                        </h6>
                        <bs-list-group class="text-dark" flush>
                            <bs-list-group-item class="d-flex align-items-center py-3 px-0">
                                <span class="text-muted w-50">
                                    {{ $t('form.first_name.label') }}
                                </span>
                                {{ user.additional_data.first_name }}
                            </bs-list-group-item>
                            <bs-list-group-item class=" d-flex align-items-center py-3 px-0">
                                <span class="text-muted w-50">
                                    {{ $t('form.last_name.label') }}
                                </span>
                                {{ user.additional_data.last_name }}
                            </bs-list-group-item>
                            <bs-list-group-item class="d-flex align-items-center py-3 px-0">
                                <span class="text-muted w-50">
                                    {{ $t('form.email.label') }}
                                </span>
                                {{ user.email }}
                            </bs-list-group-item>
                            <bs-list-group-item class="d-flex align-items-center py-3 px-0" v-if="userGroup.name === 'staff'">
                                <span class="text-muted w-50">
                                    {{ $t('form.city.label') }}
                                </span>
                                {{ user.additional_data.city }}
                            </bs-list-group-item>
                        </bs-list-group>
                        <h6 class="mt-4 mb-3" v-if="userGroup.name === 'partner'">
                            {{ $t('profile.section.company') }}
                        </h6>
                        <bs-list-group v-if="userGroup.name === 'partner'" class="text-dark" flush>
                            <bs-list-group-item class="d-flex align-items-center py-3 px-0">
                                <span class="text-muted w-50">
                                    {{ $t('form.company_name.label') }}
                                </span>
                                {{ user.additional_data.company_name }}
                            </bs-list-group-item>
                            <bs-list-group-item class="d-flex align-items-center py-3 px-0">
                                <span class="text-muted w-50">
                                    {{ $t('form.country.label') }}
                                </span>
                                {{ user.additional_data.country }}
                            </bs-list-group-item>
                            <bs-list-group-item class="d-flex align-items-center py-3 px-0">
                                <span class="text-muted w-50">
                                    {{ $t('form.city.label') }}
                                </span>
                                {{ user.additional_data.city }}
                            </bs-list-group-item>
                            <bs-list-group-item class="d-flex align-items-center py-3 px-0">
                                <span class="text-muted w-50">
                                    {{ $t('form.company_address.label') }}
                                </span>
                                {{ user.additional_data.address }}
                            </bs-list-group-item>
                            <bs-list-group-item class="d-flex align-items-center py-3 px-0">
                                <span class="text-muted w-50">
                                    {{ $t('form.phone_number.label') }}
                                </span>
                                {{ user.additional_data.phone }}
                            </bs-list-group-item>
                            <bs-list-group-item class="list-group-item d-flex align-items-center py-3 px-0">
                                <span class="text-muted w-50">
                                    {{ $t('form.fax_number.label') }}
                                </span>
                                {{ user.additional_data.fax }}
                            </bs-list-group-item>
                        </bs-list-group>
                    </bs-card>
                </bs-tab-pane>
                <bs-tab-pane id="server-access">
                    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 g-lg-4">
                        <div class="col"
                             v-for="(server, serverIndex) in user.server_access"
                             :key="serverIndex">
                            <partner-data-server
                                v-if="userGroup.name === 'partner'"
                                :serverData="server"
                                :index="serverIndex" />
                            <staff-data-server
                                v-if="userGroup.name === 'staff'"
                                :serverData="server"
                                :index="serverIndex" />
                        </div>
                    </div>
                </bs-tab-pane>
                <bs-tab-pane id="settings">
                    <form @submit.prevent="saveSettings">
                        <bs-card
                            :title="$t('profile.tab.settings.title')"
                            header-class="p-lg-4"
                            body-class="p-lg-5"
                            footer-class="p-lg-4"
                            shadow>
                            <div class="row">
                                <div class="col-md-6 offset-md-3">
                                    <h6 class="mb-3">
                                        {{ $t('profile.section.change_password') }}
                                    </h6>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 text-lg-end">
                                    <label class="form-label me-lg-4 mt-lg-1">
                                        {{ $t('Password') }}
                                    </label>
                                </div>
                                <div class="col-md-6">
                                    <password-input
                                        id="passwd"
                                        v-model="password"
                                        :placeholder="$t('form.password.placeholder')"
                                        input-group-class="input-group-flat"
                                        :enable-generator="true" />
                                </div>
                            </div>
                            <template #footer>
                                <button type="submit" class="btn btn-primary">
                                    {{ $t('btn.save_settings') }}
                                </button>
                            </template>
                        </bs-card>
                    </form>
                </bs-tab-pane>
            </bs-tab-content>
        </template>
        <template #sidebar>
            <sidebar class="d-none d-lg-block">
                <bs-card body-class="p-lg-4" shadow>
                    <div class="d-flex align-items-center mb-lg-5">
                        <bs-symbol variant="primary" size="56" indicator="success" rounded-circle>
                            {{ userInitials(user.additional_data.first_name, user.additional_data.last_name) }}
                        </bs-symbol>
                        <div class="ms-3">
                            <h6 class="fw-bold mb-0 text-uppercase">
                                {{ userFullName(user.additional_data.first_name, user.additional_data.last_name) }}
                            </h6>
                            <span class="text-muted fs-sm">
                            {{ user.email }}
                        </span>
                        </div>
                    </div>
                    <bs-nav type="pills" class="text-dark fs-sm flex-column">
                        <bs-nav-item>
                            <bs-nav-link
                                class="w-100 fw-semi-bold"
                                target="account-details"
                                :label="$t('profile.tab.account.caption')"
                                icon="user" tab active />
                        </bs-nav-item>
                        <bs-nav-item v-if="user.server_access">
                            <bs-nav-link
                                class="w-100 fw-semi-bold"
                                target="server-access"
                                :label="$t('profile.tab.server_access.caption')"
                                icon="toggle-right" tab />
                        </bs-nav-item>
                        <bs-nav-item>
                            <bs-nav-link
                                class="w-100 fw-semi-bold"
                                target="settings"
                                :label="$t('profile.tab.settings.caption')"
                                icon="lock" tab />
                        </bs-nav-item>
                    </bs-nav>
                    <hr class="my-4">
                    <ul class="list-unstyled fs-sm">
                        <li class="d-flex align-items-center justify-content-between mb-2">
                            <span class="text-dark">
                                {{ $t('profile.registered') }}
                            </span>
                            {{ registrationDate }}
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="text-dark">
                                {{ $t('profile.last_login') }}
                            </span>
                            {{ lastLoginDate }}
                        </li>
                    </ul>
                </bs-card>
            </sidebar>
            <sidebar class="d-lg-none px-0">
                <bs-card border="0" no-body>
                    <bs-card-body class="px-3 py-5">
                        <div class="d-flex flex-column align-items-center">
                            <bs-symbol variant="primary" size="56" class="mb-3" indicator="success" rounded-circle>
                                {{ userInitials(user.additional_data.first_name, user.additional_data.last_name) }}
                            </bs-symbol>
                            <div class="text-center">
                                <h6 class="fw-bold mb-0 text-uppercase">
                                    {{ userFullName(user.additional_data.first_name, user.additional_data.last_name) }}
                                </h6>
                                <span class="text-muted fs-sm">
                                {{ user.email }}
                            </span>
                            </div>
                        </div>
                    </bs-card-body>
                </bs-card>
                <bs-nav class="nav-pills fs-sm nav-mobile mb-4 px-3">
                    <bs-nav-item>
                        <bs-nav-link
                            class="rounded-pill py-2"
                            target="account-details"
                            :label="$t('profile.tab.account.caption')"
                            tab active />
                    </bs-nav-item>
                    <bs-nav-item v-if="user.server_access">
                        <bs-nav-link
                            class="rounded-pill py-2"
                            target="server-access"
                            :label="$t('profile.tab.server_access.caption')"
                            tab />
                    </bs-nav-item>
                    <bs-nav-item>
                        <bs-nav-link
                            class="rounded-pill py-2"
                            target="settings"
                            :label="$t('profile.tab.settings.caption')"
                            tab />
                    </bs-nav-item>
                </bs-nav>
            </sidebar>
        </template>
    </page-content>
</template>

<script>
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import useHelpers from "@/modules/helpers";
import Sidebar from "@/components/Sidebar";
import Subheader from '@/components/Subheader';
import PasswordInput from "@/components/Form/PasswordInput";
import PageContent from "@/components/PageContent";
import PartnerDataServer from "@/components/Profile/PartnerDataServer";
import StaffDataServer from "@/components/Profile/StaffDataServer";
import NavItem from "@/components/Bootstrap/Nav/NavItem";
import NavLink from "@/components/Bootstrap/Nav/NavLink";
import Nav from "@/components/Bootstrap/Nav/Nav";
import Card from "@/components/Bootstrap/Card/Card";
import CardBody from "@/components/Bootstrap/Card/CardBody";
import Symbol from "@/components/Bootstrap/Symbol/Symbol";
import TabContent from "@/components/Bootstrap/Tab/TabContent";
import TabPane from "@/components/Bootstrap/Tab/TabPane";
import ListGroup from "@/components/Bootstrap/ListGroup/ListGroup";
import ListGroupItem from "@/components/Bootstrap/ListGroup/ListGroupItem";
import Breadcrumb from "@/components/Bootstrap/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Bootstrap/Breadcrumb/BreadcrumbItem";
import Api from "@/modules/api";

export default {
    name: "Profile",
    components: {
        PageContent,
        Sidebar,
        Subheader,
        PasswordInput,
        PartnerDataServer,
        StaffDataServer,
        bsNav: Nav,
        bsNavItem: NavItem,
        bsNavLink: NavLink,
        bsCard: Card,
        bsCardBody: CardBody,
        bsSymbol: Symbol,
        bsTabContent: TabContent,
        bsTabPane: TabPane,
        bsListGroup: ListGroup,
        bsListGroupItem: ListGroupItem,
        bsBreadcrumb: Breadcrumb,
        bsBreadcrumbItem: BreadcrumbItem
    },
    setup() {
        const store = useStore();
        const { dateToStr, userInitials, userFullName } = useHelpers;
        const password = ref();
        const user = computed(() => store.getters.user);
        const userGroup = store.getters.user.groups.find( g => {
            return g.def
        });

        console.log(user.value);

        const registrationDate = computed(() => {
            if (user.value.registration_date !== undefined && user.value.registration_date) {
                return dateToStr(user.value.registration_date);
            }

            return '-';
        })

        const lastLoginDate = computed(() => {
            if (user.value.last_login_date !== undefined && user.value.last_login_date) {
                return dateToStr(user.value.last_login_date);
            }

            return '-';
        })

        const saveSettings = () => {
            Api.changePassword(password.value);
        }

        return {
            user,
            password,
            userGroup,
            userFullName,
            userInitials,
            registrationDate,
            lastLoginDate,
            saveSettings
        }
    }
}
</script>