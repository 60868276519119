<template>
    <div class="py-3 px-4">
        <div class="d-flex align-items-center">
            <div class="symbol symbol-primary symbol-56">
                <span class="symbol-caption rounded-circle font-size-h5">
                    {{ initials ? initials : '?' }}
                </span>
                <i class="indicator bg-success"></i>
            </div>
            <div class="ms-3">
                <h6 class="fw-bold mb-0">
                    {{ fullName ? fullName : 'N/A' }}
                </h6>
                <span class="text-muted fs-sm">
                    {{ email }}
                </span>
            </div>
        </div>
    </div>
    <div class="dropdown-divider"></div>
    <ul class="list-unstyled my-3">
        <li>
            <router-link :to="{ name: 'profile' }" class="dropdown-item fs-sm text-muted d-flex align-items-center px-4">
                <span class="symbol">
                    <span class="symbol-caption text-info fs-5">
                        <i class="icon icon-user" aria-hidden="true"></i>
                    </span>
                </span>
                <span class="ms-3">
                    <span class="fw-semi-bold d-block text-dark">My Profile</span>
                    Account settings and more
                </span>
            </router-link>
        </li>
        <li v-if="false">
            <a href="#" class="dropdown-item fs-sm text-muted d-flex align-items-center px-4">
                <span class="symbol">
                    <span class="symbol-caption text-success fs-5">
                        <i class="icon icon-briefcase" aria-hidden="true"></i>
                    </span>
                </span>
                <span class="ms-3">
                    <span class="fw-semi-bold d-block text-dark">My Orders</span>
                    All your order and details
                </span>
            </a>
        </li>
        <li v-if="false">
            <a href="#" class="dropdown-item fs-sm text-muted d-flex align-items-center px-4">
                <span class="symbol">
                    <span class="symbol-caption text-danger fs-5">
                        <i class="icon icon-pin" aria-hidden="true"></i>
                    </span>
                </span>
                <span class="ms-3">
                    <span class="fw-semi-bold d-block text-dark">Saved Prices</span>
                    Your favourite hotel prices
                </span>
            </a>
        </li>
    </ul>
    <div class="dropdown-divider"></div>
    <div class="py-3 px-4 text-center">
        <router-link class="btn btn-light-primary px-4" :to="{ name: 'logout' }">
            Logout
        </router-link>
    </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from 'vue';

export default {
    setup() {
        const store = useStore();

        return {
            user: computed(() => store.getters.user),
            fullName: computed(() => store.getters.userFullName),
            initials: computed(() => store.getters.userInitials),
            email: computed(() => store.getters.user.email),
        }
    }
}
</script>