<template>
    <div class="card-body">
        <slot />
    </div>
</template>

<script>
export default {
    name: "bsCardBody"
};
</script>