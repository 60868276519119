<template>
    <card-check
        v-for="(group, groupIndex) in accessGroups"
        :key="groupIndex"
        v-model="accessGroup"
        class="card-check-horizontal shadow-small mt-3"
        :value="group.value"
        :id="'accessGroup' + groupIndex"
        name="accessGroup"
        @click="$emit('click', $event)">
        <h6 class="card-title mb-1">
            {{ group.name }}
        </h6>
        <p class="card-text text-muted fs-sm">
            {{ $t('access_levels.' + group.value) }}
        </p>
    </card-check>
</template>

<script>
import CardCheck from "@/components/Form/CardCheck";
import {ref, computed, onBeforeMount} from "vue";
import Api from "@/modules/api";

export default {
    name: "AccessGroupsRadio",
    components: {
        CardCheck
    },
    props: {
        modelValue: [String, Number]
    },
    emits: ["update:modelValue", "click"],
    setup(props, { emit }) {
        const accessGroups = ref([]);

        const accessGroup = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value)
        })

        onBeforeMount(async () => {
            accessGroups.value = (
                await Api.getStaffGroups()).map( g => {return {name: g.name, value: g.id};}
            );
        })

        return {
            accessGroup,
            accessGroups
        }
    }
}
</script>