<template>
    <div class="card-footer">
        <slot />
    </div>
</template>

<script>
export default {
    name: "bsCardFooter"
};
</script>