<template>
    <PageContent>
        <template v-slot:body>
            _bookingsList
        </template>
    </PageContent>
</template>

<script>
export default {
}
</script>
