<template>
    <component :is="tag" :class="wrapperClassName">
        <form-label v-if="label" :for="id" :required="isRequired" :optional="isOptional">
            {{ label }}
        </form-label>
        <div class="form-error-wrap" v-if="!!errorMessage">
            <input
                v-model="inputValue"
                :class="inputClassName"
                :type="inputType"
                :id="id"
                v-bind="attrs" />
            <span class="form-error">
                {{ errorMessage }}
            </span>
        </div>
        <input
            v-else
            v-model="inputValue"
            :class="inputClassName"
            :type="inputType"
            :id="id"
            v-bind="attrs" />
        <div v-if="formText" class="form-text">
            {{ formText }}
        </div>
    </component>
</template>

<script>
import { ref, computed } from "vue";
import FormLabel from "@/components/Form/FormLabel";
import FormError from "@/components/Form/FormError";

const inputTypes = [
    'text',
    'password',
    'email',
    'number',
    'url',
    'tel',
    'search',
    'range',
    'color',
    'date',
    'time',
    'datetime',
    'datetime-local',
    'month',
    'week',
    'hidden'
];

export default {
    name: "TextInput",
    components: {
        FormLabel,
        FormError
    },
    props: {
        id: String,
        label: String,
        modelValue: [String, Number],
        wrapperClass: String,
        labelClass: String,
        invalid: Number,
        size: String,
        formText: String,
        errorMessage: String,
        required: Boolean,
        optional: Boolean,
        type: {
            type: String,
            default: 'text',
            validator: function(value) {
                return inputTypes.includes(value);
            }
        },
        wrap: {
            type: Boolean,
            default: true
        },
        tag: {
            type: String,
            default: "div"
        }
    },
    emits: ["update:modelValue"],
    setup(props, { attrs, emit }) {
        const isRequired = ref(props.required)
        const isOptional = ref(props.optional)

        const inputValue = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value)
        })

        const inputType = computed(() => {
            return inputTypes.includes(props.type) ? props.type : 'text'
        });

        const wrapperClassName = computed(() => {
            return props.wrapperClass || "form-group";
        });

        const inputClassName = computed(() => {
            return [
                "form-control",
                props.invalid && 'is-invalid',
                props.size && `form-control-${props.size}`
            ];
        });

        const labelClassName = computed(() => {
            return props.labelClass || "form-label"
        });

        return {
            isRequired,
            isOptional,
            inputValue,
            inputType,
            wrapperClassName,
            inputClassName,
            labelClassName,
            attrs,
            props
        };
    }
};
</script>