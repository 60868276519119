<template>
    <div class="date-range-picker dropdown" :id="wrapperId">
        <label v-if="label" class="form-label">{{ label }}</label>
        <div class="input-group">
            <button
                class="form-control text-start"
                @click.prevent="openDatePicker">
                {{ dateFrom }}
            </button>
            <span class="input-group-text" v-if="divider">
                <i class="icon icon-chevron-right"></i>
            </span>
            <button
                class="form-control text-start"
                @click.prevent="openDatePicker">
                {{ dateTo }}
            </button>
            <button
                class="btn btn-outline-secondary"
                type="button" id="button-addon2"
                @click.prevent="resetDatePicker"
                v-if="(dateFrom || dateTo) && reset">
                <i class="icon icon-x"></i>
            </button>
        </div>
        <flat-pickr
            :value="inputValue"
            :config="config"
            @input="handleInput"
            ref="datePicker"
            class="form-control visually-hidden" />
    </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { dateToStr } from "@/classes/helper";
import { ref, watchEffect } from "vue";

export default {
    components: { flatPickr },
    props: {
        modelValue: [String, Number],
        dataWrapper: String,
        dataReset: String,
        dataDivider: String,
        dataLabel: String,
        dataLabelFrom: {
            type: String,
            default: 'From'
        },
        dataLabelTo: {
            type: String,
            default: 'To'
        },
    },
    emits: ["update:modelValue"],
    setup(props, { attrs, emit }) {
        const inputValue = ref(props.modelValue);
        const datePicker = ref(null);
        const reset = ref(props.dataReset);
        const divider = ref(props.dataDivider);
        const label = ref(props.dataLabel);
        const wrapperId = ref(props.dataWrapper);
        const dateFrom = ref(props.dataLabelFrom);
        const dateTo = ref(props.dataLabelTo);

        const openDatePicker = () => {
            datePicker.value.fp.open();
        }

        const resetDatePicker = () => {
            inputValue.value = '';
            dateFrom.value = props.dataLabelFrom;
            dateTo.value = props.dataLabelTo;
        }

        function handleInput(e) {
            inputValue.value = e.target.value;
            emit("update:modelValue", inputValue.value);
        }

        watchEffect(() => (inputValue.value = props.modelValue));

        return {
            inputValue,
            handleInput,
            label,
            reset,
            divider,
            wrapperId,
            dateFrom,
            dateTo,
            openDatePicker,
            resetDatePicker,
            attrs,
            props,
            datePicker,
            config: {
                //appendTo: document.getElementById(self.wrapperId),
                mode: "range",
                //minDate: "today",
                dateFormat: "Y-m-d",
                onChange(date) {
                    dateFrom.value = dateToStr(date[0], 'DD/MM/YY');
                    dateTo.value = dateToStr(date[1], 'DD/MM/YY');
                }
            }
        };
    }
}
</script>

<style src="flatpickr/dist/flatpickr.css"></style>