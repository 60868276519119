<template>
    <div aria-live="polite"
         aria-atomic="true"
         class="position-fixed bottom-0 end-0 p-3"
         v-if="$store.getters.events.length">
        <div class="toast-container">
            <div class="toast align-items-center border-0 text-white"
                 :class="event.type === 'OK' ? 'bg-success' : 'bg-danger'"
                 role="alert"
                 aria-live="assertive"
                 aria-atomic="true"
                 v-for="(event, eventIdx) in $store.getters.events"
                 :data-idx="eventIdx"
                 :key="eventIdx">
                <div class="d-flex">
                    <div class="toast-body d-flex align-items-center">
                        <i class="icon text-white icon-lg o-75 me-3"
                           :class="event.type === 'OK' ? 'icon-info' : 'icon-slash'"
                           aria-hidden="true">
                        </i>
                        {{ $t('event.' + event.origin + '.' + event.type) }}
                    </div>
                    <button
                        type="button"
                        class="btn-close btn-close-white me-2 m-auto"
                        data-bs-dismiss="toast"
                        aria-label="Close" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onUpdated } from "vue";
import { Toast } from "bootstrap";
import Store from "@/modules/store";

export default {
    name: "bsToast",
    props: {
        animation: {
            type: Boolean,
            default: true
        },
        autohide: {
            type: Boolean,
            default: true
        },
        delay: {
            type: [String, Number],
            default: 5000
        }
    },
    setup(props, {attrs}) {
        onUpdated(() => {
            document.querySelectorAll('.toast').forEach(toastEl => {
                toastEl.addEventListener('hidden.bs.toast', function () {
                    Store.commit('delEvent', this.getAttribute('data-idx'))
                })
                const toastObj = new Toast(toastEl, props);
                toastObj.show();
            })
        })

        return {
            attrs,
            props,
        }
    },
}
</script>