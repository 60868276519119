import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        events: [],
        user: {},
        isLoaded: null,
        siteConfig: {
            currency: 'USD'
        },
    },
    getters: {
        isLoaded: state => {
            return state.isLoaded;
        },

        user: state => {
            return (state.user && typeof state.user.token !== 'undefined') ? state.user : null;
        },

        isAuthenticated: state => {
            return (state.user && typeof state.user.token !== 'undefined') ? state.user : null;
        },

        events: state => {
            return state.events.filter(function (el) {
                return el != null;
            });
        },

        currency(state) {
            return state.siteConfig.currency;
        },

        // TODO: Start
        dataServer(state) {
            if ( state.siteConfig.dataServer !== undefined ) {
                return state.siteConfig.dataServer;
            }
            return null;
        },

        userDataServers(state) {
            if ( state.user.server_access !== undefined ) {
                return state.user.server_access;
            }
            return null;
        },

        userFullName(state) {
            if (state.user && state.user.additional_data.length && !state.user.error) {
                return ((state.user.additional_data.first_name || '') + ' ' + (state.user.additional_data.last_name || '')).trim();
            }
            return null;
        },

        userInitials(state) {
            if (state.user && state.user.additional_data.length && !state.user.error) {
                return ((state.user.additional_data.first_name || ' ').charAt(0) + (state.user.additional_data.last_name || ' ').charAt(0)).trim();
            }
            return null;
        }
        // TODO: End

    },
    mutations: {
        setLoaded (state, payload) {
            state.isLoaded = payload;
        },

        setUser (state, payload) {
            state.user = payload;
        },

        pushEvent (state, payload) {
            state.events.push({
                type: payload.type || null,
                origin: payload.origin || null,
                params: payload.params || null,
                description: payload.description || null,
            });
        },

        delEvent (state, idx = -1) {
            if (idx > 0) {
                delete state.events[idx];
            } else {
                state.events.pop();
            }

            state.events = state.events.filter(function (el) {
                return !!el;
            });
        },

        setEvents (state, payload) {
            state.events = payload || [];
        },

        setCurrency(state, payload) {
            state.siteConfig.currency = payload;
        },

        setDataServer(state, payload) {
            state.siteConfig.dataServer = payload;
        },

        setSiteConfig(state, payload) {
            state.siteConfig = payload;
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [createPersistedState()],
    mapActions: [

    ]
});
