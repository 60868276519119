<template>
    <component
        :is="htmlTag"
        :href="href"
        class="list-group-item"
        :class="cssClass"
        v-bind="attrs">
        <slot>
            {{ label }}
            <bs-badge
                v-if="badge"
                :variant="badgeVariant"
                :color="badgeColor"
                :pill="badgePill"
                class="ms-auto">
                <slot name="badge-content">
                    {{ badge }}
                </slot>
            </bs-badge>
        </slot>
    </component>
</template>

<script>
import {ref, computed, watchEffect} from "vue";
import bsBadge from "@/components/Bootstrap/Badge/Badge"

export default {
    name: "bsListGroupItem",
    components: { bsBadge },
    props: {
        tag: {
            type: String,
            default: 'li'
        },
        label: String,
        href: String,
        variant: String,
        tab: Boolean,
        active: Boolean,
        disabled: Boolean,
        badge: String,
        badgeVariant: String,
        badgeColor: String,
        badgePill: Boolean
    },
    setup(props, {attrs}) {
        const htmlTag = ref(props.tag)

        const cssClass = computed(() => {
            return [
                props.active && 'active',
                props.disabled && htmlTag.value !== 'button' && 'disabled',
                (!!props.href || htmlTag.value === 'button') && 'list-group-action',
                props.variant && `list-group-item-${props.variant}`,
                props.badge && 'd-flex align-items-center'
            ]
        })

        watchEffect(() => {
            if (props.href)
                htmlTag.value = 'a'

            if (props.disabled) {
                if (htmlTag.value === 'button') {
                    attrs = {...attrs, 'disabled': true}
                } else {
                    attrs = {...attrs, 'aria-disabled': 'true'}
                }

                if (htmlTag.value === 'a')
                    attrs = {...attrs, 'tabindex': '-1'}
            }

            if (props.active)
                attrs = {...attrs, 'aria-current': 'true'}

            if (htmlTag.value === 'button')
                attrs = {...attrs, 'type': 'button'}

            if (props.tab)
                attrs = {...attrs,
                    'data-bs-toggle': 'list',
                    'role': 'tab'
            }
        })

        return {
            htmlTag,
            cssClass,
            attrs
        }
    }
}
</script>