<template>
    <component
        :is="tag"
        class="badge"
        :class="{
            [`bg-${variant || 'secondary'}`]: true,
            [`text-${color}`]: color,
            'rounded-pill': pill
        }"
        v-bind="attrs">
        <slot>{{ label }}</slot>
    </component>
</template>

<script>
export default {
    name: "bsBadge",
    props: {
        tag: {
            type: String,
            default: 'span'
        },
        label: [String, Number],
        variant: String,
        color: String,
        pill: Boolean
    },
    setup(props, {attrs}) {
        return {
            attrs
        }
    }
}
</script>