<template>
    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
        <nav aria-label="Pagination">
            <ul class="pagination mb-3 mb-md-0">
                <li class="page-item">
                    <a href="#"
                       role="button"
                       class="page-link page-link-control"
                       :class="{disabled: currentPage === 1}"
                       :aria-label="$t('pagination.first')"
                       @click.prevent="currentPage = 1">
                        <i class="icon icon-chevrons-left" aria-hidden="true"></i>
                    </a>
                </li>
                <li class="page-item">
                    <a href="#"
                       role="button"
                       class="page-link page-link-control"
                       :class="{disabled: currentPage === 1}"
                       :aria-label="$t('pagination.prev')"
                       @click.prevent="--currentPage || 1">
                        <i class="icon icon-chevron-left" aria-hidden="true"></i>
                    </a>
                </li>
                <li v-for="page in pagesList" class="page-item" :key="page">
                    <a href="#"
                       role="button"
                       v-if="!isNaN(1 * page)"
                       :class="{'page-link': true, active: page === currentPage}"
                       @click.prevent="currentPage = page">{{ page }}</a>
                    <span v-else>{{ page }}</span>
                </li>
                <li class="page-item">
                    <a href="#"
                       role="button"
                       class="page-link page-link-control"
                       :aria-label="$t('pagination.next')"
                       :class="{disabled: currentPage === pagesCount}"
                       @click.prevent="currentPage++">
                        <i class="icon icon-chevron-right" aria-hidden="true"></i>
                    </a>
                </li>
                <li class="page-item">
                    <a href="#"
                       role="button"
                       class="page-link page-link-control"
                       :aria-label="$t('pagination.last')"
                       :class="{disabled: currentPage === pagesCount}"
                       @click.prevent="currentPage = pagesCount">
                        <i class="icon icon-chevrons-right" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </nav>
        <div class="d-flex align-items-center ms-md-3">
            <select
                class="form-select form-select-sm form-control-solid me-3"
                aria-label="Pager info"
                v-model.number="itemsPerPage">
                <option
                    v-for="option in perPageOptions"
                    :value="option" :key="option"
                    :selected="itemsPerPage === option">
                    {{ option }}
                </option>
            </select>
            <span class="text-nowrap fs-sm">
                {{ $t('pagination.summary', {
                    from: itemsFrom,
                    to: itemsTo,
                    total: totalItems
                }) }}
            </span>
        </div>
    </div>
</template>

<script>
import {computed, ref, watch, watchEffect } from 'vue';

export default {
    name: "Pagination",
    props: {
        items: {
            type: Number,
            required: true,
            default: 0
        },
        page: {
            type: Number,
            default: 1
        },
        perPage: {
            type: Number,
            default: 5
        },
        perPageOptions: {
            type: Array,
            default: () => [5, 10, 15]
        },
    },
    emits: {
        'change'(payload) {
            return payload.currentPage && payload.itemsPerPage
        }
    },
    setup(props, { emit }) {
        const totalItems = ref(props.items)
        const itemsPerPage = ref(props.perPage);
        const currentPage = ref(props.page);

        watch([currentPage, itemsPerPage], () => {
            emit('change', {
                currentPage: currentPage.value,
                itemsPerPage: itemsPerPage.value
            })
        })

        watchEffect(() => {
            totalItems.value = props.items;
        })

        const pagesCount = computed(() => {
            return Math.ceil(totalItems.value / itemsPerPage.value);
        });

        const pagesList = computed(() => {
            let pages = [];

            for (let i = currentPage.value - 2; i <= currentPage.value + 2; i++) {
                if (i > 0 && i <= pagesCount.value) {
                    pages.push(i)
                }
            }

            return pages;
        });

        const itemsFrom = computed(() => {
            return (currentPage.value - 1) * itemsPerPage.value + 1;
        })

        const itemsTo = computed(() => {
            return Math.min((currentPage.value - 1) * itemsPerPage.value + itemsPerPage.value, totalItems.value);
        })

        return {
            totalItems,
            itemsPerPage,
            currentPage,
            pagesCount,
            pagesList,
            itemsFrom,
            itemsTo,
        }
    }
}
</script>