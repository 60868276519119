<template>
    <slot v-bind="{[dataName]: true}"></slot>
</template>

<script>
import {ref, onMounted} from "vue";
import {Tooltip} from 'bootstrap';

export default {
    name: "bsTooltip",
    props: {
        dataName: {
            type: String,
            default: '',
        },
        title: {
            type: String,
            default: 'My Popover',
        },
        trigger: {
            type: String,
            default: 'click',
        },
        delay: {
            type: Number,
            default: 0,
        },
        placement: {
            type: String,
            default: 'top'
        },
        html: {
            type: Boolean,
            default: false,
        },
        fix: {
          type: Boolean,
          default: false,
        }
    },
    setup(props, { slots }) {
        const options = ref(props);
        const slotEl = slots.default();
        onMounted(() => {
            const el = options.value.dataName ? document.querySelector(`[${options.value.dataName}]`) : slotEl[0].el;
            if (el) {
                new Tooltip(el, options.value);
            }
        });

        if (!options.value.dataName) {
            return () => slotEl;
        }
    },
}
</script>