<template>
    <div
        class="modal fade"
        :id="id"
        tabindex="-1"
        aria-hidden="true"
        v-bind="attrs">
        <div :class="modalDialogClassName">
            <div class="modal-content">
                <div v-if="hasHeaderSlot" :class="headerClassName">
                    <slot name="header" />
                    <button
                        v-if="closeBtn"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close" />
                </div>
                <div :class="bodyClassName">
                    <slot name="default" />
                </div>
                <div v-if="hasFooterSlot" :class="footerClassName">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { Modal } from "bootstrap";

export default {
    name: "bsModal",
    props: {
        id: {
            type: String,
            required: true
        },
        type: String,
        closeBtn: {
            type: Boolean,
            default: true
        },
        show: {
            type: Boolean,
            default: false
        },
        backdrop: {
            type: [Boolean, String],
            default: true
        },
        keyboard: {
            type: Boolean,
            default: true
        },
        centered: Boolean,
        focus: {
            type: Boolean,
            default: true
        },
        headerClass: String,
        bodyClass: String,
        footerClass: String,
        modalDialogClass: String
    },
    emits: ["update:modelValue"],
    setup(props, { slots, attrs, emit }) {
        const modal = ref(null);

        const hasHeaderSlot = computed(() =>{
            return !!slots.header
        })

        const hasFooterSlot = computed(() =>{
            return !!slots.footer
        })

        const modalDialogClassName = computed(() => {
            return [
                "modal-dialog",
                props.modalDialogClass,
                props.centered && "modal-dialog-centered"
            ]
        })

        const headerClassName = computed(() => {
            return [
                "modal-header",
                props.headerClass
            ];
        });

        const bodyClassName = computed(() => {
            return [
                "modal-body",
                props.bodyClass
            ];
        });

        const footerClassName = computed(() => {
            return [
                "modal-footer",
                props.footerClass
            ];
        });

        onMounted(() => {
            modal.value = new Modal(document.getElementById(props.id), props);
            emit("update:modelValue", modal.value)
            if (props.show) modal.value.show()
        })

        onUnmounted(() => {
            modal.value.hide()
        })

        return {
            modal,
            headerClassName,
            bodyClassName,
            footerClassName,
            modalDialogClassName,
            hasHeaderSlot,
            hasFooterSlot,
            attrs,
            props
        }
    },
}
</script>