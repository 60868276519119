import { createApp } from 'vue';
import App from './App.vue';
import router from './modules/router';
import store from './modules/store';
import PageContent from "@/components/PageContent";

import "bootstrap";
import setupI18n from "./i18n.js";
require('./assets/styles/style.scss');

const app = createApp(App);

app.use(store);
app.use(router);
app.use(setupI18n);

app.component('PageContent', PageContent);
app.mount('#app');
