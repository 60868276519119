<template>
    <div class="card-icon">
        <i :class="iconCssClass" />
    </div>
</template>

<script>
import {computed} from "vue"

export default {
    name: "bsCardIcon",
    props: {
        icon: String,
        size: String,
        color: String
    },
    setup(props) {
        const iconCssClass = computed(() => {
            return [
                'icon',
                props.icon && `icon-${props.icon}`,
                props.size && `icon-${props.size}`,
                props.color && `text-${props.color}`
            ]
        })

        return {
            iconCssClass
        }
    }
}
</script>

<style scoped>
.card-icon > .icon {
    line-height: 1;
    display: block;
}
</style>