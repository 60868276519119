import axios from 'axios';
import Store from './store';

const client = axios.create();

const apiServers = {
    ACC: {
        url: 'https://accounts.efrito.com/api/v1.5/'
    },
    EFR: {
        url: 'https://dev.efrito.com/api/v1.5/'
    },
    E2: {
        url: 'https://api.efrito.xyz/api/'
    }
};

// A - all, E - errors, S - success
const apiMessages = {
    login: {messages: 'A', loader: false},
    createtemporaryusertoken: {messages: 'E', loader: false},
    generateapikey: {messages: 'E', loader: false},
    loginactivation: {messages: 'A', loader: false},
    passwordchange: {messages: 'A', loader: false},
    passwordrecovery: {messages: 'A', loader: false},
    passwordreset: {messages: 'A', loader: false},
    getserverdsc: {messages: 'E', loader: true},
    saveserverdsc: {messages: 'A', loader: true},
    deleteserverdsc: {messages: 'A', loader: true},
    getpartnertypes: {messages: 'E', loader: true},
    savestaff: {messages: 'A', loader: true},
    setstaffaccess: {messages: 'E', loader: false},
    setserveraccess: {messages: 'E', loader: false},
    updatepartneraccount: {messages: 'A', loader: true},
    updatepartnerinfo: {messages: 'A', loader: true},
    getstafflist: {messages: 'E', loader: true},
    getloginlist: {messages: 'E', loader: true},
    getserverlist: {messages: 'E', loader: true},
    gethotelslist: {messages: 'E', loader: true},
    getstaff: {messages: 'E', loader: true},
    getstaffgroups: {messages: 'E', loader: true},
    getpartner: {messages: 'E', loader: true},
    registerpartner: {messages: 'A', loader: true},
    addpartner: {messages: 'A', loader: true},
    deleteaccount: {messages: 'A', loader: true},
};

export default {
    async login (authData) {
        const {login, password, key, temporary_token} = authData;

        let result = await this.sendRequest(apiServers.ACC, 'login', {login, password, key, temporary_token}, 'post');

        if (result.data.token !== undefined) {
            Store.commit('setUser', result.data);
        }

        return result.data;
    },

    logout() {
        Store.state.user = null;
    },

    async createTemporaryUserToken(id) {
        const result = await this.sendRequest(apiServers.ACC, 'createtemporaryusertoken', {account_id: id}, 'post');

        return result.data;
    },

    async generateApiKey(id) {
        const result = await this.sendRequest(apiServers.ACC, 'generateapikey', {account_id: id}, 'post');

        return result.data;
    },

    async activateLogin(code) {
        const result = await this.sendRequest(apiServers.ACC, 'loginactivation', {activationcode: code}, 'post');

        return result.data;
    },

    async changePassword(password) {
        const result = await this.sendRequest(apiServers.ACC, 'passwordchange', {password: password, password_confirm: password}, 'post');

        return result.data;
    },

    async recoverPassword(data) {
        const {email, locale, recovery_link} = data;
        const result = await this.sendRequest(apiServers.ACC, 'passwordrecovery', {email, locale, recovery_link}, 'post');

        return result.data;
    },

    async resetPassword(data) {
        const {key: reset_code, password: password, password: password_confirm} = data;
        const result = await this.sendRequest(apiServers.ACC, 'passwordreset', {reset_code, password, password_confirm}, 'post');

        return result.data;
    },

    async getRegions(dsId) {
        const result = await this.sendRequest(apiServers.E2, 'data/region/list', {data_server_id: dsId}, 'get');

        return result.data;
    },

    async getServerDSC() {
        const result = await this.sendRequest(apiServers.ACC, 'getserverdsc', null, 'post');

        return result.data;
    },

    async setServerDSC(dscData) {
        const {id, server_prefix, company_name, region, name, email, tel, mob_tel, skype, data} = dscData;
        const result = await this.sendRequest(apiServers.ACC, 'saveserverdsc', {id, server_prefix, company_name, region, name, email, tel, mob_tel, skype, data}, 'post');

        return result.data;
    },

    async delServerDSC(id) {
        const result = await this.sendRequest(apiServers.ACC, 'deleteserverdsc', {dsc_id: id}, 'post');

        return result.data;
    },

    async getPartnerTypes() {
        const result = await this.sendRequest(apiServers.ACC, 'getpartnertypes', null, 'post');

        return result.data;
    },

    async saveStaff(data) {
        const {account_id, email, password, password: password_confirm, is_active, is_default, first_name, last_name, city, remark, is_deleted} = data;
        const result = await this.sendRequest(apiServers.ACC, 'savestaff', {account_id, email, password, password_confirm, is_active, is_default, first_name, last_name, city, remark, is_deleted}, 'post');
        if (result.data.account_id) {
            data.server_access.forEach(async serverAccess => {
                serverAccess.account_id = result.data.account_id;
                await this.saveStaffAccess(serverAccess);
            });
        }

        return result.data;
    },

    async saveStaffAccess(data) {
        const { account_id, server_prefix, group_id } = data;
        const result = await this.sendRequest(apiServers.ACC, 'setstaffaccess', { account_id, server_prefix, group_id }, 'post');

        return result.data;
    },

    async saveStaffInfo(data) {
        const {account_id, email, is_active, is_default, first_name, last_name, city, remark} = data;
        const result = await this.sendRequest(apiServers.ACC, 'savestaff', {account_id, email, is_active, is_default, first_name, last_name, city, remark}, 'post');

        return result.data;
    },

    async updatePartner(data) {
        const {account_id, login, password, email, is_active, is_deleted, email_activation, inform_user} = data;
        await this.updatePartnerAccount({account_id, login, password, email, is_active, is_deleted, email_activation, inform_user});

        const {first_name, last_name, company_name, country_iso: country, city, fax, phone, address, remark} = data;
        await this.updatePartnerInfo({account_id, first_name, last_name, company_name, country, city, fax, phone, address, remark});

        data.server_access.forEach(async (serverAccess) => {
            const {server_prefix, ftr_partner_group_id, book_ol_cr, book_ol_dr, book_ol_wm, book_ol_pk, is_deleted = false} = serverAccess;
            await this.setServerAccess({account_id, server_prefix, ftr_partner_group_id,
                book_ol_cr: !!book_ol_cr,
                book_ol_dr: !!book_ol_dr,
                book_ol_wm: !!book_ol_wm,
                book_ol_pk: !!book_ol_pk,
                is_deleted,
            });
        });
    },

    async setServerAccess(data) {
        const result = await this.sendRequest(apiServers.ACC, 'setserveraccess', data, 'post');

        return result.data;
    },

    async updatePartnerAccount(data) {
        const result = await this.sendRequest(apiServers.ACC, 'updatepartneraccount', data, 'post');

        return result.data;
    },

    async updatePartnerInfo(data) {
        const result = await this.sendRequest(apiServers.ACC, 'updatepartnerinfo', data, 'post');

        return result.data;
    },

    async getStaffList () {
        const result = await this.sendRequest(apiServers.ACC, 'getstafflist', null, 'post');

        return result.data;
    },

    async getLoginList (data) {
        const result = await this.sendRequest(apiServers.ACC, 'getloginlist', data, 'post');

        return result.data;
    },

    async getServersList () {
        const result = await this.sendRequest(apiServers.ACC, 'getserverlist', null, 'post');

        return result.data;
    },

    async getHotelsList (dataServerId) {
        const result = await this.sendRequest(apiServers.EFR, 'gethotelslist', dataServerId, 'post');

        return result.data;
    },

    async getStaff (id) {
        const result = await this.sendRequest(apiServers.ACC, 'getstaff', {account_id: id}, 'post');

        return result.data;
    },

    async getStaffGroups (reduced = false) {
        const result = await this.sendRequest(apiServers.ACC, 'getstaffgroups', null);

        return (reduced) ? result.data.reduce((a, g) => {
                a[g.id] = g.name;
                return a;
            }, {}) : result.data;
    },

    async getPartner (id) {
        const result = await this.sendRequest(apiServers.ACC, 'getpartner', {account_id: id}, 'post');
        result.data.password = '';

        return result.data;
    },

    async registerPartner(userData, locale, activationLink) {
        const result = await this.sendRequest(apiServers.ACC, 'registerpartner', {...userData, locale, activation_link: activationLink});

        return result.data;
    },

    async addPartner (userData, locale, activationLink) {
        let {first_name, last_name, country_iso, city, company_name, phone, email, email_activation, password} = userData;
        const result = await this.sendRequest(apiServers.ACC, 'addpartner', {
            first_name,
            last_name,
            country_iso,
            city,
            company_name,
            phone,
            email,
            email_activation,
            password,
            locale: locale,
            activation_link: activationLink
        }, 'post');

        return result.data;
    },

    async delAccount (id) {
        const result = await this.sendRequest(apiServers.ACC, 'deleteaccount', {id: id});

        return result.data;
    },

    sendRequest (server, apiName, data = null, method = 'post') {

        let requestConfig = {
            url: server.url + apiName,
            method: method,
            [method === 'post' ? 'data' : 'params']: data
        };

        const user = Store.getters.user;
        if (user && user.token !== undefined) {
            requestConfig = {
                ...requestConfig,
                headers: {
                    token: user.token.token
                }
            };
        }

        return client.request(requestConfig);
    }
};

/****************************************/

function getApiFunctionConfig(url) {
    const funcName = url.split('/').pop();
    return {function:funcName, loader:false, messages: false, ...apiMessages[funcName]};
}

client.interceptors.request.use(
    config => {
        const apiConfig = getApiFunctionConfig(config.url);
        Store.commit('setLoaded', !apiConfig.loader);
        return config;
    },
    error => {
        const apiConfig = getApiFunctionConfig(error.config.url);
        Store.commit('setLoaded', !apiConfig.loader);
        return Promise.reject(error);
    }
);

client.interceptors.response.use(
    response => {
        Store.commit('setLoaded', true);

        const apiConfig = getApiFunctionConfig(response.config.url);
        if (apiConfig.messages) {
            if (response.data.error !== undefined) {
                Store.commit ('pushEvent', {type: 'ER', origin: apiConfig.function, params: response.config, description: response.data.error.description});
            } else if (apiConfig.messages !== 'E') {
                Store.commit ('pushEvent', {type: 'OK', origin: apiConfig.function, params: response.config});
            }
        }

        return response;
        },
    error => {
        Store.commit('setLoaded', true);
        const apiConfig = getApiFunctionConfig(error.config.url);
        if (apiConfig.messages !== 'S') {
            Store.commit ('pushEvent', {type: 'ER', origin: apiConfig.function, params: error.config});
        }

        console.log(error);
        return Promise.reject(error);
/*
        if (error.response.status === 401 || error.response.status === 403) {
            Store.state.event = {type: 'error', title: 'Access Denied', message: `to ${error.config.url}`}
            //return Promise.reject('access-denied');
        } else {
            console.error(error);
            return Promise.reject();
        }
 */
    }
);