<template>
    <li class="nav-item" :class="{ 'dropdown': dropdown }">
        <slot />
    </li>
</template>

<script>
export default {
    name: "NavItem",
    props: {
        dropdown: Boolean
    }
}
</script>