<template>
    <slot name="header" />
    <div :class="wrapperClassNames">
        <div class="content row">
            <slot name="sidebar" v-if="hasSidebarSlot" />
            <main class="main">
                <slot name="main" />
            </main>
        </div>
    </div>
    <slot name="footer" />
    <page-loader />
</template>

<script>
import {computed} from "vue";
import PageLoader from "@/components/PageLoader";

export default {
    name: "PageContent",
    components: {
        PageLoader
    },
    props: {
        containerFluid: {
            type: Boolean,
            default: false
        },
        wrapperClass: String
    },
    setup (props, { slots }) {
        const hasSidebarSlot = computed(() =>{
            return !!slots.sidebar;
        })

        const wrapperClassNames = computed(() => {
            return [
                "wrap",
                hasSidebarSlot.value && 'sidebar-primary',
                props.containerFluid ? 'container-fluid' : 'container',
                props.wrapperClass
            ];
        });

        return {
            wrapperClassNames,
            hasSidebarSlot
        }
    }
}
</script>
