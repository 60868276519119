<template>
    <page-content v-if="user">
        <template #header>
            <subheader class="d-none d-lg-block" enable-sticky>
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                        <h1 class="subheader-title h6 mb-0">
                            {{ $t('profile.staff') }}
                        </h1>
                        <div class="subheader-divider subheader-divider-ver mx-3"></div>
                        <bs-breadcrumb>
                            <bs-breadcrumb-item>
                                <router-link :to="{ name: 'staff_list' }">
                                    {{ $t('nav.staff_list') }}
                                </router-link>
                            </bs-breadcrumb-item>
                            <bs-breadcrumb-item current>
                                {{ userFullName(user.first_name, user.last_name) }}
                            </bs-breadcrumb-item>
                        </bs-breadcrumb>
                    </div>
                    <div class="d-flex align-items-center ms-auto">
                        <bs-button variant="light-info" size="sm" :label="$t('btn.send_message')" />
                    </div>
                </div>
            </subheader>
        </template>
        <template #main>
            <form class="form" @submit.prevent="saveUserDetails">
                <bs-card body-class="p-0" footer-class="text-end p-lg-4" shadow>
                    <bs-tab-content ref="profileTabPanes" id="profileTabPanes">
                        <bs-tab-pane id="account-details" selected>
                            <bs-card
                                border="0"
                                bg="transparent"
                                header-class="p-lg-4"
                                body-class="p-lg-5"
                                :title="$t('profile.tab.account.title')"
                                :subtitle="$t('profile.tab.account.subtitle')">
                                <div class="row">
                                    <div class="col-md-6 offset-md-3">
                                        <h6 class="mb-3">
                                            {{ $t('profile.section.account') }}:
                                        </h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 text-lg-end">
                                        <label class="form-label me-lg-4 mt-lg-1">
                                            {{ $t('form.email.label') }}
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <text-input
                                            id="email"
                                            type="email"
                                            :placeholder="$t('form.email.placeholder')"
                                            :error-message="$t('form.email.error')"
                                            v-model="v$.email.$model"
                                            :invalid="v$.email.$errors.length"
                                            @blur="v$.email.$touch()"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 text-lg-end">
                                        <label class="form-label me-lg-4 mt-lg-1">
                                            {{ $t('form.password.label') }}
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <password-input
                                            id="passwd"
                                            v-model="user.password"
                                            :placeholder="$t('form.password.placeholder')"
                                            wrapper-class="form-group"
                                            input-group-class="input-group-flat"
                                            :enable-generator="true" />
                                    </div>
                                </div>
                                <div class="form-group row mb-lg-2">
                                    <div class="col-6 col-md-3 text-lg-end">
                                        <label class="form-label me-lg-4 mt-lg-1">
                                            {{ $t('form.user_active.label') }}
                                        </label>
                                    </div>
                                    <div class="col-6 text-end text-md-start">
                                        <checkbox-input
                                            id="isUserActive"
                                            wrapper-class="d-inline-block mb-0"
                                            :switch="true"
                                            v-model="user.is_active" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-6 col-md-3 text-lg-end">
                                        <label class="form-label me-lg-4 mt-lg-1">
                                            {{ $t('form.default_staff.label') }}
                                        </label>
                                    </div>
                                    <div class="col-6 text-end text-md-start">
                                        <checkbox-input
                                            id="isUserDefault"
                                            wrapper-class="d-inline-block mb-0"
                                            :switch="true"
                                            v-model="user.is_default" />
                                    </div>
                                </div>
                                <hr class="my-5" />
                                <div class="row">
                                    <div class="col-md-6 offset-md-3">
                                        <h6 class="mb-3">
                                            {{ $t('profile.section.personal') }}:
                                        </h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 text-lg-end">
                                        <label class="form-label me-lg-4 mt-lg-1">
                                            {{ $t('form.first_name.label') }}
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <text-input
                                            id="firstName"
                                            :placeholder="$t('form.first_name.placeholder')"
                                            :error-message="$t('form.first_name.error')"
                                            v-model="v$.first_name.$model"
                                            :invalid="v$.first_name.$errors.length"
                                            @blur="v$.first_name.$touch()" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 text-lg-end">
                                        <label class="form-label me-lg-4 mt-lg-1">
                                            {{ $t('form.last_name.label') }}
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <text-input
                                            id="lastName"
                                            :placeholder="$t('form.last_name.placeholder')"
                                            :error-message="$t('form.last_name.error')"
                                            v-model="v$.last_name.$model"
                                            :invalid="v$.last_name.$errors.length"
                                            @blur="v$.last_name.$touch()" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 text-lg-end">
                                        <label class="form-label me-lg-4 mt-lg-1">
                                            {{ $t('form.city.label') }}
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <text-input
                                            id="cityName"
                                            :placeholder="$t('form.city.placeholder')"
                                            :error-message="$t('form.city.error')"
                                            v-model="v$.city.$model"
                                            :invalid="v$.city.$errors.length"
                                            @blur="v$.city.$touch()" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3 text-lg-end">
                                        <label class="form-label me-lg-4 mt-lg-1">
                                            {{ $t('form.remarks.label') }}
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <textarea-input
                                            id="address"
                                            v-model="user.remark"
                                            :placeholder="$t('form.remarks.placeholder')" />
                                    </div>
                                </div>
                            </bs-card>
                        </bs-tab-pane>
                        <bs-tab-pane id="server-access">
                            <bs-card
                                border="0"
                                bg="transparent"
                                header-class="p-lg-4"
                                body-class="p-lg-5"
                                :title="$t('profile.tab.server_access.title')"
                                :subtitle="$t('profile.tab.server_access.subtitle')">
                                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2 g-md-3">
                                    <div class="col"
                                         v-for="(server, serverIndex) in user.server_access"
                                         :key="serverIndex">
                                        <staff-data-server
                                            :access-groups="accessGroups"
                                            :data-server="server"
                                            :data-server-index="serverIndex"
                                            v-model="serverState[serverIndex]"
                                            @click="toggleServerStatus" />
                                    </div>
                                </div>
                            </bs-card>
                        </bs-tab-pane>
                        <bs-tab-pane v-if="false" id="settings">
                            <bs-card
                                border="0"
                                bg="transparent"
                                header-class="p-lg-4"
                                body-class="p-lg-5"
                                :title="$t('profile.tab.settings.title')"
                                :subtitle="$t('profile.tab.settings.subtitle')">
                                <div class="row">
                                    <div class="col-md-6 offset-md-3">
                                        <h6 class="mb-3">
                                            {{ $t('profile.section.notifications') }}
                                        </h6>
                                    </div>
                                </div>
                                <div class="form-group row align-items-center mb-3">
                                    <div class="col-6 col-md-3 text-lg-end">
                                        <label class="form-label me-lg-4 mb-lg-0">
                                            {{ $t('form.check.enable_notifications') }}
                                        </label>
                                    </div>
                                    <div class="col-6 text-end text-md-start">
                                        <checkbox-input
                                            id="enableNotifications"
                                            wrapper-class="d-inline-block"
                                            :switch="true"
                                            v-model="user.enableNotifications"
                                            :checked="user.enableNotifications" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-3 text-lg-end">
                                        <label class="me-lg-4">
                                            {{ $t('layout.when_to_email') }}
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <checkbox-input
                                            id="memberRegistered"
                                            :label="$t('form.check.new_user_registered')"/>
                                        <checkbox-input
                                            id="orderCreated"
                                            :label="$t('form.check.new_order_created')"/>
                                        <checkbox-input
                                            id="orderApproved"
                                            :label="$t('form.check.on_order_approval')"/>
                                    </div>
                                </div>
                            </bs-card>
                        </bs-tab-pane>
                    </bs-tab-content>
                    <template #footer>
                        <bs-button
                            type="submit"
                            variant="primary"
                            :label="$t('btn.update_profile')" />
                    </template>
                </bs-card>
            </form>
        </template>
        <template #sidebar>
            <sidebar class="d-none d-lg-block">
                <bs-card body-class="p-lg-4" shadow>
                    <div class="d-flex align-items-center mb-5">
                        <bs-symbol variant="primary" size="56" indicator="success" rounded-circle>
                            {{ userInitials(user.first_name, user.last_name) }}
                        </bs-symbol>
                        <div class="ms-3">
                            <h6 class="fw-semi-bold mb-0">
                                {{ userFullName(user.first_name, user.last_name) }}
                            </h6>
                            <span class="text-muted fs-sm">
                            {{ user.email }}
                        </span>
                        </div>
                    </div>
                    <bs-nav type="pills" class="text-dark fs-sm flex-column">
                        <bs-nav-item>
                            <bs-nav-link
                                class="w-100 fw-semi-bold"
                                target="account-details"
                                :label="$t('profile.tab.account.caption')"
                                icon="user" tab active />
                        </bs-nav-item>
                        <bs-nav-item v-if="user.server_access">
                            <bs-nav-link
                                class="w-100 fw-semi-bold"
                                target="server-access"
                                :label="$t('profile.tab.server_access.caption')"
                                icon="toggle-right" tab />
                        </bs-nav-item>
                        <bs-nav-item v-if="false">
                            <bs-nav-link
                                class="w-100 fw-semi-bold"
                                target="settings"
                                :label="$t('profile.tab.settings.caption')"
                                icon="lock" tab />
                        </bs-nav-item>
                    </bs-nav>
                    <hr class="my-4">
                    <ul class="list-unstyled fs-sm">
                        <li class="d-flex align-items-center justify-content-between mb-2">
                            <span class="text-dark">
                                {{ $t('profile.registered') }}
                            </span>
                            {{ registrationDate(user) }}
                        </li>
                        <li class="d-flex align-items-center justify-content-between">
                            <span class="text-dark">
                                {{ $t('profile.last_login') }}
                            </span>
                            {{ lastLoginDate(user) }}
                        </li>
                    </ul>
                </bs-card>
            </sidebar>
            <sidebar class="d-lg-none px-0">
                <bs-card border="0" header-border="0" body-class="px-3 pb-5 pt-0">
                    <template #toolbar1>
                        <bs-dropdown class="d-lg-none">
                            <bs-button
                                variant="link"
                                class="text-dark"
                                icon="more-vertical"
                                aria-label="userNav"
                                icon-hidden dropdown-toggle no-caret />
                            <bs-dropdown-menu right>
                                <bs-dropdown-item :label="$t('btn.send_message')" icon="star" />
                                <bs-dropdown-item :label="$t('btn.delete')" class="text-danger" icon="trash-2" />
                            </bs-dropdown-menu>
                        </bs-dropdown>
                    </template>
                    <div class="d-flex flex-column align-items-center">
                        <bs-symbol variant="primary" size="56" class="mb-3" indicator="success" rounded-circle>
                            {{ userInitials(user.first_name, user.last_name) }}
                        </bs-symbol>
                        <div class="text-center">
                            <h6 class="fw-bold mb-0 text-uppercase">
                                {{ userFullName(user.first_name, user.last_name) }}
                            </h6>
                            <span class="text-muted fs-sm">
                            {{ user.email }}
                        </span>
                        </div>
                    </div>
                </bs-card>
                <bs-nav class="nav-pills fs-sm nav-mobile mb-4 px-3">
                    <bs-nav-item>
                        <bs-nav-link
                            class="rounded-pill py-2"
                            target="account-details"
                            :label="$t('profile.tab.account.caption')"
                            tab active />
                    </bs-nav-item>
                    <bs-nav-item v-if="user.server_access">
                        <bs-nav-link
                            class="rounded-pill py-2"
                            target="server-access"
                            :label="$t('profile.tab.server_access.caption')"
                            tab />
                    </bs-nav-item>
                    <bs-nav-item v-if="false">
                        <bs-nav-link
                            class="rounded-pill py-2"
                            target="settings"
                            :label="$t('profile.tab.settings.caption')"
                            tab />
                    </bs-nav-item>
                </bs-nav>
            </sidebar>
        </template>
        <template #footer>
            <bs-modal id="accessGroupsModal" ref="accessGroupsModal">
                <h5 class="mb-1">
                    {{ $t('modal.access_groups.title') }}
                </h5>
                <p class="mb-4">
                    {{ $t('modal.access_groups.subtitle') }}
                </p>
                <access-groups-radio
                    v-model="activeServer.group_id"
                    @click="accessGroupsModal.modal.hide()" />
            </bs-modal>
        </template>
    </page-content>
</template>

<script>
import {ref, computed, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import { email, required } from "@vuelidate/validators";
import Api from '@/modules/api';
import {useStore} from 'vuex';
import useUser from "@/modules/user";
import useHelpers from "@/modules/helpers";
import useVuelidate from "@vuelidate/core";
import Sidebar from "@/components/Sidebar";
import Subheader from '@/components/Subheader';
import PasswordInput from "@/components/Form/PasswordInput";
import CheckboxInput from "@/components/Form/CheckboxInput";
import TextInput from "@/components/Form/TextInput";
import TextareaInput from "@/components/Form/TextareaInput";
import PageContent from "@/components/PageContent";
import Modal from "@/components/Bootstrap/Modal/Modal";
import AccessGroupsRadio from "@/components/DataServers/AccessGroupsRadio";
import StaffDataServer from "@/components/DataServers/StaffDataServer";
import NavItem from "@/components/Bootstrap/Nav/NavItem";
import NavLink from "@/components/Bootstrap/Nav/NavLink";
import Nav from "@/components/Bootstrap/Nav/Nav";
import Dropdown from "@/components/Bootstrap/Dropdown/Dropdown";
import DropdownMenu from "@/components/Bootstrap/Dropdown/DropdownMenu";
import DropdownItem from "@/components/Bootstrap/Dropdown/DropdownItem";
import Button from "@/components/Bootstrap/Button/Button";
import Card from "@/components/Bootstrap/Card/Card";
import Breadcrumb from "@/components/Bootstrap/Breadcrumb/Breadcrumb";
import BreadcrumbItem from "@/components/Bootstrap/Breadcrumb/BreadcrumbItem";
import TabContent from "@/components/Bootstrap/Tab/TabContent";
import TabPane from "@/components/Bootstrap/Tab/TabPane";
import Symbol from "@/components/Bootstrap/Symbol/Symbol";

export default {
    components: {
        PageContent,
        Sidebar,
        Subheader,
        PasswordInput,
        CheckboxInput,
        TextInput,
        TextareaInput,
        AccessGroupsRadio,
        StaffDataServer,
        bsModal: Modal,
        bsNav: Nav,
        bsNavItem: NavItem,
        bsNavLink: NavLink,
        bsDropdown: Dropdown,
        bsDropdownMenu: DropdownMenu,
        bsDropdownItem: DropdownItem,
        bsButton: Button,
        bsCard: Card,
        bsBreadcrumb: Breadcrumb,
        bsBreadcrumbItem: BreadcrumbItem,
        bsTabContent: TabContent,
        bsTabPane: TabPane,
        bsSymbol: Symbol
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const {
            userInitials,
            userFullName,
            validateTabs,
            registrationDate,
            lastLoginDate
        } = useHelpers;

        const accessGroups = ref();

        const accessGroupsModal = ref();
        const activeServer = ref({});
        const profileTabs = ref();
        const profileTabPanes = ref();
        const user = ref();

        const serverState = computed(() => {
            return user.value.server_access.map( s => { return !!s.group_id });
        })

        // Form validation rules
        let rules = {
            email: { required, email },
            city: { required },
            first_name: { required },
            last_name: { required }
        };

        const v$ = useVuelidate(rules, user);

        watch(v$, () => {
            validateTabPanes();
        });

        const validateTabPanes = async () => {
            await v$.value.$touch();

            if (profileTabPanes.value) {
                const tabPanes = profileTabPanes.value.$el.getElementsByClassName('tab-pane');
                validateTabs(tabPanes);
            }

            return !v$.value.$invalid;
        }

        const saveUserDetails = async () => {
            if (await validateTabPanes()) {
                const staff = await Api.saveStaff(user.value);

                if (staff.account_id) {
                  router.replace({name: 'staff_details', params: {id: staff.account_id}});
                  beforeCreate();
                }
            }
        }

        const toggleServerStatus = (e) => {
            activeServer.value = user.value.server_access[e.target.getAttribute('data-value')]

            if (!activeServer.value.group_id || e.target.tagName.toLowerCase() !== 'input') {
                accessGroupsModal.value.modal.show();
                e.preventDefault();
            } else {
                activeServer.value.group_id = null;
            }
        }

        const beforeCreate = async () => {
            accessGroups.value = (await Api.getStaffGroups(true));
            user.value = await Api.getStaff(route.params.id);
            user.value.password = null;
            user.value.server_access = useUser.getStaffServerAccesses(user, store.getters.userDataServers);
        }

        beforeCreate();

        return {
            v$,
            user,
            profileTabs,
            profileTabPanes,
            userFullName,
            userInitials,
            registrationDate,
            lastLoginDate,
            accessGroupsModal,
            saveUserDetails,
            toggleServerStatus,
            activeServer,
            serverState,
            accessGroups
        }
    }
}
</script>