<template>
    <component
        :id="elUid"
        :is="tag"
        :class="elClasses"
        role="tabpanel"
        v-bind="attrs">
        <slot />
    </component>
</template>

<script>
import {ref, computed} from "vue";

export default {
    name: "WizardPanel",
    props: {
        id: {
            type: String,
            required: true
        },
        tag: {
            type: String,
            default: 'div'
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    setup(props, {attrs}) {
        const isSelected = ref(props.selected)
        const elUid = ref(props.id);

        const elClasses = computed(() => {
            return [
                'tab-pane fade',
                isSelected.value && 'show active'
            ]
        })

        return {
            attrs,
            elUid,
            elClasses
        }
    }
}
</script>