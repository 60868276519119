<template>
    <li v-if="wrap">
        <a :href="href"
           class="dropdown-item"
           :class="{'d-flex align-items-center': icon}"
           v-bind="attrs">
            <slot>
                <bs-icon
                    v-if="icon"
                    class="me-2"
                    :icon="icon"
                    :size="iconSize"
                    :color="iconColor" />
                {{ label }}
            </slot>
        </a>
    </li>
    <a v-else
       :href="href"
       class="dropdown-item"
       :class="{'d-flex align-items-center': icon}"
       v-bind="attrs">
        <slot>
            <bs-icon
                v-if="icon"
                class="me-2"
                :icon="icon"
                :size="iconSize"
                :color="iconColor" />
            {{ label }}
        </slot>
    </a>
</template>

<script>
import bsIcon from "@/components/Bootstrap/Icon/Icon"

export default {
    name: "bsDropdownItem",
    inheritAttrs: false,
    components: {
        bsIcon
    },
    props: {
        wrap: {
            type: Boolean,
            default: true
        },
        href: {
            type: String,
            default: '#'
        },
        label: {
            type: String,
            default: ''
        },
        icon: String,
        iconSize: String,
        iconColor: String
    },
    setup(props, { attrs }) {
        return {
            attrs
        };
    }
};
</script>