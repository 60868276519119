<template>
    <login-content>
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-10 col-xl-8 col-xxl-6" v-if="!registered">
                    <div class="text-center">
                        <h1 class="fs-2 fw-bold">{{ $t('page.register.title') }}</h1>
                        <p class="text-center text-dark mb-3">
                            <i18n-t keypath="sign_in.text" for="sign_in.link">
                                <router-link :to="{name: 'login'}">{{ $t('sign_in.link') }}</router-link>
                            </i18n-t>
                        </p>
                    </div>
                    <div class="alert alert-danger" v-if="error">{{ error }}</div>
                    <form class="form" @submit.prevent="register" autocomplete="off" novalidate>
                        <fieldset>
                            <legend class="d-md-none mt-4">
                                {{ $t('layout.personal_info') }}
                            </legend>
                            <text-input
                                id="registerEmail"
                                wrapper-class="mb-2"
                                :label="$t('form.email.label')"
                                class="form-control-solid"
                                :placeholder="$t('form.email.placeholder')"
                                v-model="v$.email.$model"
                                :invalid="v$.email.$errors.length"
                                @blur="v$.email.$touch()"
                            />
                            <div class="row">
                                <div class="col-md-6">
                                    <text-input
                                        id="registerFirstName"
                                        wrapper-class="mb-2"
                                        :label="$t('form.first_name.label')"
                                        class="form-control-solid"
                                        :placeholder="$t('form.first_name.placeholder')"
                                        v-model="v$.first_name.$model"
                                        :invalid="v$.first_name.$errors.length"
                                        @blur="v$.first_name.$touch()"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <text-input
                                        id="registerLastName"
                                        wrapper-class="mb-2"
                                        :label="$t('form.last_name.label')"
                                        class="form-control-solid"
                                        :placeholder="$t('form.last_name.placeholder')"
                                        v-model="v$.last_name.$model"
                                        :invalid="v$.last_name.$errors.length"
                                        @blur="v$.last_name.$touch()"
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend class="d-md-none mt-4">
                                {{ $t('layout.company_info') }}
                            </legend>
                            <div class="row">
                                <div class="col-md-6">
                                    <country-select
                                        id="country"
                                        :label="$t('form.country.label')"
                                        v-model="v$.country_iso.$model"
                                        :invalid="v$.country_iso.$errors.length"
                                        @blur="v$.country_iso.$touch()"
                                        select-class="multiselect-solid"
                                        :placeholder="$t('form.country.placeholder')" />
                                </div>
                                <div class="col-md-6">
                                    <text-input
                                        id="registerCity"
                                        wrapper-class="mb-2"
                                        :label="$t('form.city.label')"
                                        class="form-control-solid"
                                        :placeholder="$t('form.city.placeholder')"
                                        v-model="v$.city.$model"
                                        :invalid="v$.city.$errors.length"
                                        @blur="v$.city.$touch()"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <text-input
                                        id="registerCompanyName"
                                        wrapper-class="mb-2"
                                        :label="$t('form.company_name.label')"
                                        class="form-control-solid"
                                        :placeholder="$t('form.company_name.placeholder')"
                                        v-model="v$.company_name.$model"
                                        :invalid="v$.company_name.$errors.length"
                                        @blur="v$.company_name.$touch()"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <text-input
                                        id="registerPhoneNumber"
                                        wrapper-class="mb-2"
                                        :label="$t('form.phone_number.label')"
                                        class="form-control-solid"
                                        :placeholder="$t('form.phone_number.placeholder')"
                                        v-model="v$.phone.$model"
                                        :invalid="v$.phone.$errors.length"
                                        @blur="v$.phone.$touch()"
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <div class="d-flex justify-content-center mt-5 mb-4">
                            <div class="form-check"
                                 :class="{ isInvalid: v$.tos.$errors.length }">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="registerTos"
                                    v-model="v$.tos.$model"
                                    @blur="v$.tos.$touch()"
                                />
                                <label class="form-check-label" for="registerTos">
                                    <i18n-t keypath="tos.text" for="tos.terms">
                                        <router-link :to="{name: 'register'}">
                                            {{ $t('tos.terms') }}
                                        </router-link>
                                    </i18n-t>
                                </label>
                            </div>
                        </div>
                        <div class="d-grid gap-2 mb-3">
                            <button type="submit" class="btn btn-primary">
                                {{ $t('btn.register') }}
                            </button>
                        </div>
                        <p class="text-center text-muted mb-0">
                            <small>
                                <i18n-t keypath="recaptcha.text" for="recaptcha.text">
                                    <a href="https://policies.google.com/privacy">
                                        {{ $t('recaptcha.privacy') }}
                                    </a>
                                    <a href="https://policies.google.com/terms">
                                        {{ $t('recaptcha.terms') }}
                                    </a>
                                </i18n-t>
                            </small>
                        </p>
                    </form>
                </div>
                <div class="col-md-10 col-xl-8" v-else>
                    <div class="card border-0" v-if="error">
                        <div class="card-body p-md-5 text-center">
                            <i class="icon icon-user fs-1 text-primary mb-3"></i>
                            <h4 class="card-title">
                                {{ $t('page.register.error.title') }}
                            </h4>
                            <p class="card-text mb-5">
                                {{ $t('page.register.error.text') }}
                            </p>
                            <a href="/" class="btn btn-primary">
                                {{ $t('btn.back_home') }}
                            </a>
                        </div>
                    </div>
                    <div class="card border-0" v-else>
                        <div class="card-body p-md-5 text-center">
                            <i class="icon icon-user fs-1 text-primary mb-3"></i>
                            <h4 class="card-title">
                                {{ $t('page.register.success.title') }}
                            </h4>
                            <p class="card-text mb-5">
                                {{ $t('page.register.success.text') }}
                            </p>
                            <router-link to="home" class="btn btn-primary">
                                {{ $t('btn.back_home') }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </login-content>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { ref, reactive } from 'vue';
import { useI18n } from "vue-i18n"
import TextInput from "@/components/Form/TextInput";
//import SelectInput from "@/components/Form/SelectInput";
import CountrySelect from "@/components/Form/CountrySelect";
import Api from '@/modules/api';
import LoginContent from "@/components/LoginContent";

export default {
    name: "UserRegistration",
    components: {
        TextInput,
        //SelectInput,
        LoginContent,
        CountrySelect
    },
    setup() {
        const { t } = useI18n({ useScope: 'global' })
        const registered = ref(false);

        // Form fields
        const form = reactive({
            first_name: '',
            last_name: '',
            email: '',
            country_iso: '',
            city: '',
            company_name: '',
            phone: '',
            tos: false
        })

        // Errors
        const error = ref(null);

        // Countries list
        const countries = ref({});

        // Form validation rules
        const rules = {
            first_name: { required },
            last_name: { required },
            email: { required, email },
            country_iso: { required },
            city: { required },
            company_name: { required },
            phone: { required },
            tos: { checked: value => value === true }
        }

        const v$ = useVuelidate(rules, form)

        // Registration function
        const register = () => {
          v$.value.$touch();
          if (!v$.value.$invalid) {
              let hostParts = location.host.split('.');
              hostParts[0] = 'partner';
              const result = Api.registerPartner(
                  form,
                  t.value,
                  location.protocol + '//' + hostParts.join('.') + '/activation/'
              );

              registered.value = result.error === undefined;
          }
        }

        return {
            v$,
            form,
            register,
            error,
            countries,
            registered
        }
    }
}
</script>
