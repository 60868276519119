<template>
    <div class="cart-header dropdown-header d-flex align-items-center justify-content-between px-4">
        <h5 class="text-white mb-0">My cart</h5>
        <span class="badge bg-success py-2 px-3">
            {{ Object.keys(items).length }} items
        </span>
    </div>
    <div class="cart-items list-group list-group-flush border-bottom">
        <div class="cart-item list-group-item p-4" v-for="item in items" :key="item.id">
            <div class="d-flex">
                <div class="cart-item-img">
                    <img :src="item.hotel.image" class="rounded" :alt="item.hotel.name" />
                </div>
                <div class="card-item-body flex-grow-1">
                    <div class="cart-item-title fw-semi-bold">{{ item.hotel.name }}</div>
                    <div class="cart-item-info text-muted fs-xs">
                        {{ item.room.name + ' ' + item.meal + ', ' + item.adults + ' + ' + item.children  }}
                    </div>
                    <div class="d-flex align-items-end justify-content-between">
                        <div class="fs-xs">12.05 - 18.05</div>
                        <div class="fw-bold">$ 1,250</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ul class="cart-total list-unstyled fs-sm py-3 px-4">
        <li class="d-flex align-items-center justify-content-between mb-1">
            Total: <strong>$ 2,500</strong>
        </li>
        <li class="d-flex align-items-center justify-content-between">
            Commission: <span class="text-success">$ 50</span>
        </li>
    </ul>
    <div class="cart-footer py-3 px-4 text-end">
        <button type="button" class="btn btn-primary px-4" @click.prevent="">
            Place Order
        </button>
    </div>
</template>

<script>
import { ref } from 'vue';
import cartItems from '@/json/cart.json';

export default {
    setup() {
        const items = ref(cartItems);

        return {
            items
        }
    }
}
</script>

<style lang="scss">
@import "./src/assets/styles/layout/cart";
</style>