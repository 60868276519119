<template>
    <header class="banner border-bottom">
        <nav class="navbar navbar-expand navbar-light bg-white px-xl-5">
            <div class="container-fluid flex-row justify-content-start">
                <div class="d-flex w-lg-100">
                    <button
                        class="navbar-toggler d-inline-block"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#menuOffcanvas"
                        type="button">
                        <span class="d-flex align-items-center">
                            <i class="icon icon-menu me-md-3"></i>
                            <span class="d-none d-md-inline-block">{{ $t('layout.menu') }}</span>
                        </span>
                    </button>
                </div>
                <a class="navbar-brand" href="#">
                    <img :src="navbarLogo" alt="" />
                </a>
                <div class="d-flex w-lg-100 ms-auto ms-lg-0">
                    <ul class="navbar-nav flex-row ms-auto">
                        <li class="nav-item dropdown d-none d-xl-block" v-if="dataServers">
                            <a class="nav-link dropdown-toggle"
                               href="#"
                               id="serversDropdown"
                               role="button"
                               data-bs-toggle="dropdown"
                               aria-expanded="false">
                                {{ currentDataServer }}
                            </a>
                            <bs-dropdown-menu class="dropdown-menu-end" aria-labelledby="serversDropdown">
                                <data-server-select />
                            </bs-dropdown-menu>
                        </li>
                        <bs-dropdown tag="li" class="nav-item ms-3" v-if="false">
                            <a class="nav-link dropdown-toggle no-caret"
                               href="#" id="notificationsDropdown"
                               role="button"
                               data-bs-toggle="dropdown"
                               aria-expanded="false"
                               aria-label="Notifications">
                                <i class="icon icon-message" aria-hidden="true"></i>
                            </a>
                            <bs-dropdown-menu
                                class="notifications-dropdown dropdown-menu-end"
                                tag="div" @click.stop>
                                <notification-tabs />
                            </bs-dropdown-menu>
                        </bs-dropdown>
                        <li class="nav-item dropdown" v-if="false">
                            <a class="nav-link dropdown-toggle no-caret"
                               href="#" id="cartDropdown"
                               role="button"
                               data-bs-toggle="dropdown"
                               aria-expanded="false"
                               aria-label="Cart">
                                <i class="icon icon-bag" aria-hidden="true"></i>
                            </a>
                            <bs-dropdown-menu
                                class="cart cart-dropdown dropdown-menu-end dropdown-header-bg"
                                tag="div" @click.stop>
                                <cart />
                            </bs-dropdown-menu>
                        </li>
                        <li class="nav-item dropdown me-lg-3">
                            <a class="nav-link dropdown-toggle no-caret"
                               href="#" id="userDropdown"
                               role="button"
                               data-bs-toggle="dropdown"
                               aria-expanded="false"
                               aria-label="User Menu">
                                <img :src="userAvatar" class="user-avatar" alt="" />
                            </a>
                            <bs-dropdown-menu class="user-menu dropdown-menu-end" tag="div">
                                <user-menu />
                            </bs-dropdown-menu>
                        </li>
                        <li class="nav-item dropdown d-none d-xl-block">
                            <a class="nav-link dropdown-toggle"
                               href="#" id="languageDropdown"
                               role="button"
                               data-bs-toggle="dropdown"
                               aria-expanded="false">
                                {{ $i18n.locale }}
                            </a>
                            <bs-dropdown-menu class="dropdown-menu-end" aria-labelledby="languageDropdown">
                                <language-select :options="languages" />
                            </bs-dropdown-menu>
                        </li>
                        <li class="nav-item dropdown d-none d-xl-block" v-if="false">
                            <a class="nav-link dropdown-toggle"
                               href="#" id="currencyDropdown"
                               role="button"
                               data-bs-toggle="dropdown"
                               aria-expanded="false">
                                {{ currentCurrency }}
                            </a>
                            <bs-dropdown-menu class="dropdown-menu-end" aria-labelledby="currencyDropdown">
                                <currency-select :options="currencies" />
                            </bs-dropdown-menu>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import {ref, computed, onBeforeMount} from 'vue';
import { useStore } from 'vuex';
import bsDropdownMenu from "@/components/Bootstrap/Dropdown/DropdownMenu";
import bsDropdown from "@/components/Bootstrap/Dropdown/Dropdown";
import LanguageSelect from "@/components/Header/LanguageSelect";
import CurrencySelect from "@/components/Header/CurrencySelect";
import DataServerSelect from "@/components/Header/DataServerSelect";
import UserMenu from "@/components/Header/UserMenu";
import Cart from '@/components/Header/Cart';
import NotificationTabs from '@/components/Header/Notifications/NotificationTabs';

export default {
    components: {
        bsDropdownMenu,
        bsDropdown,
        LanguageSelect,
        CurrencySelect,
        DataServerSelect,
        UserMenu,
        NotificationTabs,
        Cart
    },
    setup() {
        // TODO: Get languages and currencies from efrito backend
        const languages = ref(['en', 'ru'])
        const currencies = ref(['USD', 'AED', 'RUB', 'EUR'])

        const store = useStore();
        const navbarLogo = ref('/assets/logo.png')
        const userAvatar = ref('/assets/media/avatar_42.png')

        const currentDataServer = computed(() => {
            return store.getters.dataServer;
        })

        const dataServers = computed(() => {
            return store.getters.userDataServers;
        })

        onBeforeMount (() => {
            if ( dataServers.value !== null && currentDataServer.value === null ) {
                const firstServer = dataServers.value[0].server_name;
                store.commit('setDataServer', firstServer);
            }
        })

        return {
            currentCurrency: computed(() => store.getters.currency),
            currentDataServer,
            dataServers,
            navbarLogo,
            userAvatar,
            languages,
            currencies
        }
    }
}
</script>

<style lang="scss" scoped>
    .user-menu,
    .notifications-dropdown {
        min-width: 340px;
    }

    .cart-dropdown {
        min-width: 380px;
    }

    @media only screen and (max-width: 600px) {
        .user-avatar {
            width: 36px;
        }
    }

    @media only screen and (min-width: 768px) {
        .w-lg-100 {
            width: 100% !important;
        }
    }
</style>