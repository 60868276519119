<template>
    <component
        :is="tag"
        class="symbol"
        :class="{
            [`symbol-${variant || 'primary'}`]: true,
            [`symbol-${size}`]: size
        }">
        <span
            class="symbol-caption"
            :class="{'rounded-circle': roundedCircle}">
            <bs-icon v-if="icon" :icon="icon" :size="iconSize" :color="iconColor" />
            <slot v-else>{{ label }}</slot>
        </span>
        <i v-if="indicator"
           class="indicator"
           :class="`bg-${indicator}`" />
    </component>
</template>

<script>
import Icon from "@/components/Bootstrap/Icon/Icon"

export default {
    name: "Symbol",
    components: {
        bsIcon: Icon
    },
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        variant: String,
        size: String,
        label: String,
        indicator: String,
        roundedCircle: Boolean,
        icon: String,
        iconSize: String,
        iconColor: String
    }
}
</script>