<template>
    <li v-if="wrapper">
        <hr class="dropdown-divider" v-bind="attrs">
    </li>
    <hr v-else class="dropdown-divider" v-bind="attrs">
</template>

<script>
export default {
    name: "bsDropdownDivider",
    inheritAttrs: false,
    props: {
        wrapper: {
            type: Boolean,
            default: true
        }
    },
    setup( props, { attrs }) {
        return {
            attrs
        }
    }
}
</script>