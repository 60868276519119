<template>
    <div class="home">
    </div>
</template>

<script>
//import Api from '../modules/api'
export default {
  // async beforeCreate() {
  //   console.log(await Api.getServersList());
  //   console.log(await Api.getLoginList({
  //     filter: {
  //       group: 'partner',
  //       email_activation: true,
  //       is_deleted: false
  //     },
  //     order: 'register_date',
  //     start: 0,
  //     limit: 20
  //   }));
  // }
}
</script>
