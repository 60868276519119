<template>
    <span class="form-error">
        <slot />
    </span>
</template>

<script>
export default {
    name: "FormError"
}
</script>