<template>
    <div class="d-flex align-items-center justify-content-between py-3 px-4">
        <h5 class="mb-0">Notifications</h5>
        <span class="badge bg-success py-2 px-3">
            {{ Object.keys(events).length + Object.keys(alerts).length }} new
        </span>
    </div>
    <nav>
        <div class="nav nav-tabs px-4" id="nav-tab" role="tablist">
            <button class="nav-link px-0 me-3 fs-sm"
                    id="nav-alerts-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-alerts"
                    type="button"
                    role="tab"
                    aria-controls="nav-alerts"
                    aria-selected="true"
                    :class="{ active: events }"
                    v-if="events">
                Alerts
            </button>
            <button class="nav-link px-0 fs-sm"
                    id="nav-events-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-events"
                    type="button"
                    role="tab"
                    aria-controls="nav-events"
                    aria-selected="false"
                    v-if="alerts">
                Events
            </button>
        </div>
    </nav>
    <div class="tab-content my-3" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-alerts" role="tabpanel" aria-labelledby="nav-alerts-tab" v-if="alerts">
            <notification-item
                :wrapper="false"
                v-for="alert in alerts"
                :key="alert.id"
                :notification="alert" />
        </div>
        <div class="tab-pane fade" id="nav-events" role="tabpanel" aria-labelledby="nav-events-tab" v-if="events">
            <notification-item
                :wrapper="false"
                v-for="event in events"
                :key="event.id"
                :notification="event" />
        </div>
    </div>
    <div class="py-3 px-4 text-center">
        <button type="button" class="btn btn-light-primary px-4" @click.prevent="">
            See All
        </button>
    </div>
</template>

<script>
import { ref } from 'vue';
import NotificationItem from "@/components/Header/Notifications/NotificationItem";
import userAlerts from '@/json/alerts.json';
import userEvents from '@/json/events.json';

export default {
    components: {
        NotificationItem
    },
    setup() {
        const alerts = ref(userAlerts);
        const events = ref(userEvents);

        return {
            alerts,
            events
        }
    }
}
</script>