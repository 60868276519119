<template>
    <div class="wizard" :id="id" v-bind="attrs" ref="wizard">
        <slot />
    </div>
</template>

<script>
import {ref, inject, onMounted} from "vue";
import {Tab} from "bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n"

export default {
    name: "Wizard",
    props: {
        id: {
            type: String,
            required: true
        }
    },
    setup(props, {attrs}) {
        const { t } = useI18n({ useScope: 'global' });
        const v$ = inject('vuelidate');
        const wizard = ref();

        const toggleTab = async (e) => {
            await v$.value.$touch();
            const tab = e.target.getAttribute('data-step') ? document.querySelector(e.target.getAttribute('data-step')) : e.target;
            const current = wizard.value.getElementsByClassName("tab-pane active")
            if (current[0].getElementsByClassName('is-invalid').length) {
                await Swal.fire({
                    title: t('form_validation.error.title'),
                    text: t('form_validation.error.text'),
                    icon: 'error'
                })
            } else {
                (Tab.getInstance(tab) || new Tab(tab)).show();
            }
        }

        onMounted(() => {
            document.querySelectorAll('[data-step]').forEach((step) => {
                step.addEventListener('click', toggleTab);
            })
        })

        return {
            props,
            attrs,
            wizard
        }
    }
}
</script>

<style lang="scss">
    @import "./src/assets/styles/vendors/sweetalert2/sweetalert2";
</style>