<template>
    <label class="form-label" v-bind="attrs">
        <slot />
        <span class="label-required" v-if="isRequired">*</span>
        <span class="label-optional" v-if="isOptional">
            ({{ $t('form.label.optional') }})
        </span>
    </label>
</template>

<script>
import {ref} from "vue";

export default {
    name: "FormLabel",
    props: {
        required: Boolean,
        optional: Boolean
    },
    setup(props, {attrs}) {
        const isRequired = ref(props.required)
        const isOptional = ref(props.optional)

        return {
            attrs,
            isRequired,
            isOptional
        }
    }
}
</script>