//
// User management
//

import Swal from "sweetalert2/dist/sweetalert2.js";
import i18n from '../i18n';
import Api from "./api";
const { t } = i18n.global;

export default {
    // Staff available server accesses
    getStaffServerAccesses(user, parentAccesses) {
        let serverAccesses = user.value.server_access;
        parentAccesses.forEach( s => {
            if (!serverAccesses.find( us => {
                return us.country_iso === s.country_iso;
            })) {
                serverAccesses.push({
                    account_id: null,
                    group_id: null,
                    country_iso: s.country_iso,
                    server_name: s.server_name,
                    server_prefix: s.server_prefix,
                });
            }
        });
        return serverAccesses;
    },

    // User account deletion
    deleteUser(id, callback = null) {
        Swal.fire({
            title: t('user_deletion.title'),
            text: t('user_deletion.text'),
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: t('user_deletion.action')
        }).then(async (result) => {
            if (result.isConfirmed) {
                await Api.delAccount(id);
                if (callback) {
                    callback();
                }
            }
        });
    },
};