<template>
    <component :is="tag" :class="navCssClass" v-bind="attrs">
        <slot />
    </component>
</template>

<script>
import {computed} from "vue"
const navTypes = [ 'tabs', 'pills' ]

export default {
    name: "Nav",
    props: {
        tag: {
            type: String,
            default: 'ul'
        },
        type: {
            type: String,
            default: null,
            validator: function(value) {
                return navTypes.includes(value);
            }
        },
        justifyContent: String,
        alignItems: String,
        vertical: Boolean,
        fill: Boolean,
        justified: Boolean,
        class: String
    },
    setup(props, {attrs}) {
        const navCssClass = computed(() => {
            return [
                'nav',
                props.type && navTypes.includes(props.type) && `nav-${props.type}`,
                props.justifyContent && `justify-content-${props.justifyContent}`,
                props.alignItems &&  `align-items-${props.alignItems}`,
                props.vertical && 'flex-column',
                props.fill && 'nav-fill',
                props.justified && 'nav-justified',
                props.class || null
            ]
        })

        return {
            navCssClass,
            attrs
        }
    }
}
</script>
