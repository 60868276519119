<template>
    <li v-for="lng in languages" :key="lng">
        <a href="#"
           class="dropdown-item d-flex align-items-center"
           :class="{ active: lng === locale }"
           @click.prevent="changeLocale(lng)">
            <img :src="'/assets/media/flags/' + lng.toUpperCase() + '.png'"
                 :alt="$t('languages.' + lng)"
                 class="country-flag me-2">
            {{ $t('languages.' + lng) }}
        </a>
    </li>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
    props: {
        options: Array
    },
    setup( props ) {
        const { locale } = useI18n();
        const languages = ref(props.options)

        function changeLocale(lng) {
            locale.value = lng;
        }

        return {
            locale,
            languages,
            changeLocale
        }
    }
}
</script>

<style scoped>
    .country-flag {
        width: 20px;
        height: auto;
    }
</style>