<template>
    <div
        :class="offcanvasClassName"
        :id="id"
        tabindex="-1"
        aria-hidden="true"
        v-bind="attrs">
        <div class="offcanvas-header" v-if="hasHeader">
            <slot name="header" />
            <button
                v-if="closeBtn"
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close" />
        </div>
        <div class="offcanvas-body">
            <slot name="default" />
        </div>
        <div class="offcanvas-footer" v-if="hasFooter">
            <slot name="footer" />
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { Offcanvas } from "bootstrap";

const placements = [
    'start',
    'end',
    'top',
    'bottom'
]

export default {
    name: "bsOffcanvas",
    props: {
        id: {
            type: String,
            required: true
        },
        backdrop: {
            type: Boolean,
            default: true
        },
        keyboard: {
            type: Boolean,
            default: true
        },
        scroll: {
            type: Boolean,
            default: false
        },
        closeBtn: {
            type: Boolean,
            default: false
        },
        placement: {
            type: String,
            default: 'start',
            validator: function(value) {
                return placements.includes(value);
            }
        }
    },
    emits: ["update:modelValue"],
    setup(props, { slots, attrs, emit }) {
        const offcanvas = ref(null);

        const hasHeader = computed(() =>{
            return !!slots.header
        })

        const hasFooter = computed(() =>{
            return !!slots.footer
        })

        const offcanvasClassName = computed(() => {
            return [
                "offcanvas",
                placements.includes(props.placement) ? `offcanvas-${props.placement}` : 'offcanvas-start'
            ]
        })

        const offcanvasAttrs = ref({
            'backdrop': props.backdrop,
            'keyboard': props.keyboard,
            'scroll': props.scroll
        })

        onMounted(() => {
            offcanvas.value = new Offcanvas(document.getElementById(props.id), offcanvasAttrs.value);
            emit("update:modelValue", offcanvas.value)
        })

        onUnmounted(() => {
            offcanvas.value.hide()
        })

        return {
            offcanvas,
            offcanvasClassName,
            hasHeader,
            hasFooter,
            attrs,
            props
        }
    },
}
</script>