<template>
    <component :is="tag" :class="dropdownCssClass" v-bind="attrs">
        <slot />
    </component>
</template>

<script>
import {computed} from "vue"

export default {
    name: "bsDropdown",
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        dropup: Boolean,
        dropright: Boolean,
        dropleft: Boolean
    },
    setup(props, { attrs }) {
        const dropdownCssClass = computed(() => {
            return [
                'dropdown',
                props.dropup && 'dropup',
                props.dropright && 'dropright',
                props.dropleft && 'dropleft'
            ]
        })
        return {
            dropdownCssClass,
            attrs
        }
    }
}
</script>