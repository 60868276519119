<template>
    <page-content>
        <template #header>
            <subheader>
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <h1 class="subheader-title h6 mb-0">{{ $t('page.dsc_list.heading') }}</h1>
                        <div class="subheader-divider subheader-divider-ver mx-2"></div>
                        <span class="text-muted fs-sm">
                            {{ $t('layout.total_qty', { count: DSCCount }) }}
                        </span>
                    </div>
                    <div class="d-flex align-items-center flex-md-grow-1 ms-auto ms-md-3">
                        <form class="form-inline me-md-auto" @submit.prevent>
                            <div class="input-group input-group-flat">
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    :placeholder="$t('layout.search')"
                                    v-model="strFilter"
                                    @input="filterDSC"
                                    aria-label="Search"/>
                                <span class="input-group-text">
                                    <i class="icon icon-search" aria-hidden="true"></i>
                                </span>
                            </div>
                        </form>

                    </div>
                    <div class="d-none d-md-flex align-items-center ms-auto">
                        <button
                            class="btn btn-primary btn-sm"
                            @click.prevent="toggleDSCForm(null)">
                            <i class="icon icon-plus-circle me-1"></i>
                            {{ $t('btn.add_company') }}
                        </button>
                    </div>
                </div>
            </subheader>
        </template>
        <template #main>
            <div class="card shadow">
                <div class="card-header border-0 d-none d-md-flex align-items-center justify-content-between px-lg-5 pt-lg-5">
                    <div class="card-title">
                        <h5 class="mb-0">
                            {{ $t('page.dsc_list.title') }}
                        </h5>
                    </div>
                </div>
                <div class="card-body px-lg-5 pb-lg-5">
                    <div v-if="DSCCount" class="table-responsive">
                        <table class="table align-middle">
                            <thead>
                            <tr>
                                <th scope="col">
                                    #
                                </th>
                                <th scope="col">
                                    {{ $t('layout.table_header.company') }}
                                </th>
                                <th scope="col">
                                    {{ $t('layout.table_header.contact') }}
                                </th>
                                <th scope="col">
                                    {{ $t('layout.table_header.phone') }}
                                </th>
                                <th scope="col">
                                    {{ $t('layout.table_header.mobile') }}
                                </th>
                                <th scope="col" class="text-center">
                                    {{ $t('layout.table_header.data_server') }}
                                </th>
                                <th scope="col" class="text-center">
                                    {{ $t('layout.table_header.actions') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(DSC, DSCIndex) in DSCStruct" :key="DSC.id">
                                <th scope="row" class="fs-sm">
                                    {{ DSCIndex + 1 }}
                                </th>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="symbol symbol-light-success">
                                            <div class="symbol-caption fw-semi-bold">
                                                {{ DSC.company_name.charAt(0) }}
                                            </div>
                                        </div>
                                        <div class="ms-3">
                                            <div class="fw-semi-bold mb-1">
                                                {{ DSC.company_name }}
                                            </div>
                                            <div class="text-muted fs-sm">
                                                {{ DSC.email }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="fs-sm">
                                    <div class="text-dark mb-1">
                                        {{ DSC.name }}
                                    </div>
                                    <div class="text-muted">
                                        Skype: {{ DSC.skype }}
                                    </div>
                                </td>
                                <td class="fs-sm">{{ DSC.tel}}</td>
                                <td class="fs-sm">{{ DSC.mob_tel}}</td>
                                <td class="text-center">
                                    <span class="badge fs-xxs bg-light-info text-info px-1">
                                        {{ DSC.server_prefix.toUpperCase() }}
                                    </span>
                                </td>
                                <td class="text-nowrap">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-icon btn-outline-light border-0 text-dark"
                                        aria-label="Edit"
                                        @click.prevent="toggleDSCForm(DSC)">
                                        <i class="icon icon-edit" aria-hidden="true"></i>
                                    </button>
                                    <button
                                        role="button"
                                        class="btn btn-sm btn-icon btn-outline-light border-0 text-dark"
                                        aria-label="Delete"
                                        @click.prevent="deleteDSC(DSC)">
                                        <i class="icon icon-trash-2 text-danger" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else class="row justify-content-center">
                        <div class="col-md-8">
                            <div class="card border-0">
                                <div class="card-body text-center">
                                    <i class="icon icon-info icon-32 text-primary mb-4"></i>
                                    <h4>{{ $t('page.dsc_list.no_items.title') }}</h4>
                                    <p class="mb-4">{{ $t('page.dsc_list.no_items.text') }}</p>
                                    <button class="btn btn-light-primary" @click.prevent="toggleDSCForm">
                                        {{ $t('btn.add_company') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <bs-modal
                id="DSCForm"
                aria-labelledby="DSCFormLabel"
                :centered="true"
                v-model="DSCForm">
                <template #header>
                    <h5 id="DSCFormLabel" class="mb-0">
                        {{ $t(activeDSC.id ? 'page.dsc_list.edit_dsc' : 'page.dsc_list.add_dsc') }}
                    </h5>
                </template>
                <form class="form" @submit.prevent>
                    <text-input
                        id="DSCCompanyName"
                        wrapper-class="mb-3"
                        :label="$t('form.company_name.label')"
                        :placeholder="$t('form.company_name.placeholder')"
                        v-model="v$.company_name.$model"
                        :invalid="v$.company_name.$errors.length"
                        @blur="v$.company_name.$touch()" />
                    <div class="row">
                        <div class="col-md-6">
                            <text-input
                                id="DSCContactName"
                                wrapper-class="mb-3"
                                :label="$t('form.contact_person.label')"
                                :placeholder="$t('form.contact_person.placeholder')"
                                v-model="v$.name.$model"
                                :invalid="v$.name.$errors.length"
                                @blur="v$.name.$touch()" />
                        </div>
                        <div class="col-md-6">
                            <text-input
                                id="DSCEmail"
                                wrapper-class="mb-3"
                                :label="$t('form.email.label')"
                                :placeholder="$t('form.email.placeholder')"
                                v-model="v$.email.$model"
                                :invalid="v$.email.$errors.length"
                                @blur="v$.email.$touch()" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <text-input
                                id="DSCPhone"
                                wrapper-class="mb-3"
                                :label="$t('form.phone_number.label')"
                                :placeholder="$t('form.phone_number.placeholder')"
                                v-model="v$.tel.$model"
                                :invalid="v$.tel.$errors.length"
                                @blur="v$.tel.$touch()" />
                        </div>
                        <div class="col-md-6">
                            <text-input
                                id="DSCMobile"
                                wrapper-class="mb-3"
                                :label="$t('form.mobile_number.label')"
                                :placeholder="$t('form.mobile_number.placeholder')"
                                v-model="activeDSC.mob_tel" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <text-input
                                id="DSCSkype"
                                wrapper-class="mb-3"
                                :label="$t('form.skype.label')"
                                :placeholder="$t('form.skype.placeholder')"
                                v-model="activeDSC.skype" />
                        </div>
                        <div class="col-md-6">
                            <select-input
                                id="DSCServerPrefix"
                                :title="$t('form.data_server.label')"
                                label="serverName"
                                track-by="server_prefix"
                                :options="dataServers"
                                :placeholder="$t('form.data_server.placeholder')"
                                v-model="v$.server_prefix.$model"
                                :invalid="v$.server_prefix.$errors.length"
                                @blur="v$.server_prefix.$touch()" />
                        </div>
                    </div>
                    <select-input
                        v-if="v$.server_prefix.$model"
                        id="DSCRegion"
                        :title="$t('form.region.label')"
                        :placeholder="$t('form.region.placeholder')"
                        :multiple="true"
                        :taggable="true"
                        :options="regionOpts"
                        mode="tags"
                        label="label"
                        track-by="value"
                        v-model="activeDSC.region" />
                    <div class="d-grid gap-2 d-md-block text-md-center mt-4">
                        <button
                            :disabled="v$.$invalid"
                            @click="updateDSC"
                            type="button"
                            class="btn btn-primary me-md-2"
                            data-bs-dismiss="modal">
                            {{ $t(activeDSC.id ? 'btn.update' : 'btn.add_company') }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal">
                            {{ $t('btn.close') }}
                        </button>
                    </div>
                </form>
            </bs-modal>
        </template>
    </page-content>
</template>

<script>
import {ref, onMounted, reactive, computed, onBeforeMount, watchEffect} from 'vue';
import { useI18n } from 'vue-i18n';
import { email, required } from '@vuelidate/validators';
import Api from '@/modules/api';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Subheader from '@/components/Subheader';
import PageContent from '@/components/PageContent';
import bsModal from '@/components/Bootstrap/Modal/Modal';
import TextInput from '@/components/Form/TextInput';
import useVuelidate from "@vuelidate/core";
import SelectInput from '@/components/Form/SelectInput';

export default {
    components: {
        PageContent,
        bsModal,
        Subheader,
        TextInput,
        SelectInput
    },
    setup() {
        const { t } = useI18n({ useScope: 'global' });
        const DSCStruct = ref({});
        const allDSC = ref({});
        const DSCForm = ref(null);
        const strFilter = ref();

        const dataServers = ref();
        const regionOpts = ref();

        const DSCData = reactive({
            id: null,
            server_id: null,
            server_prefix: '',
            company_name: '',
            region: [],
            name: '',
            email: '',
            tel: '',
            mob_tel: '',
            skype: '',
            data: ''
        })

        const activeDSC = ref(DSCData);

        const DSCCount = computed(() => {
            return Object.keys(DSCStruct.value).length
        })

        watchEffect( async() => {
            if (activeDSC.value.server_prefix) {
                const regions = await Api.getRegions(activeDSC.value.server_prefix);
                regionOpts.value = regions.map(r => {
                    return {value: r.id, label: r.name}
                });
            } else {
                regionOpts.value = [];
            }
            activeDSC.value.region = activeDSC.value.region.filter( r => {return r.indexOf(activeDSC.value.server_prefix) !== -1});

            console.log(activeDSC.value.region);
        })

        const reloadDSC = async () => {
            allDSC.value = await Api.getServerDSC();
            filterDSC();
        }

        const filterDSC = () => {
            DSCStruct.value = strFilter.value ? allDSC.value.filter(dsc =>
                JSON.stringify(dsc).toLowerCase().includes(strFilter.value.toLowerCase())
            ) : allDSC.value;
        }

        onBeforeMount(async () => {
            dataServers.value = await Api.getServersList();
            dataServers.value.map(o => { o.serverName = `${o.name} (${o.server_prefix})`; o.value = o.server_prefix})
        })

        onMounted(() => {
            reloadDSC();
        });

        const toggleDSCForm = (dsc) => {
            let tmpDSC = dsc ? {...dsc} : {...DSCData};
            try {
                tmpDSC.region = JSON.parse(tmpDSC.region);
            } catch {
                tmpDSC.region = [];
            }
            activeDSC.value = tmpDSC;

            DSCForm.value.show();
        }

        const updateDSC = async () => {
            await v$.value.$touch();
            if (!v$.value.$invalid) {
                let saveDSC = {...activeDSC.value}
                saveDSC.region = JSON.stringify(saveDSC.region);
                await Api.setServerDSC(saveDSC);
                await reloadDSC();
            } else {
              console.log(v$)
            }
        }

        const deleteDSC = (dsc) => {
            Swal.fire({
                title: t('user_deletion.title'),
                text: t('user_deletion.text'),
                icon: 'error',
                showCancelButton: true,
                confirmButtonText: t('user_deletion.action')
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await Api.delServerDSC(dsc.id);
                    await reloadDSC();
                }
            })
        }

        // Form validation rules
        const rules = {
            company_name: { required },
            name: { required },
            email: { required, email },
            tel: { required },
            server_prefix: { required }
        };

        const v$ = useVuelidate(rules, activeDSC);

        return {
            v$,
            DSCStruct,
            DSCForm,
            DSCData,
            DSCCount,
            activeDSC,
            dataServers,
            reloadDSC,
            deleteDSC,
            toggleDSCForm,
            updateDSC,
            filterDSC,
            strFilter,
            regionOpts
        }
    }
}
</script>

