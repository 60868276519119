<template>
    <component :is="tag" v-if="wrap" :class="wrapperClassName" >
        <form-label v-if="label" :for="id" :required="isRequired" :optional="isOptional">
            {{ label }}
        </form-label>
        <div class="form-error-wrap" v-if="!!errorMessage">
            <textarea
                v-model="inputValue"
                :class="inputClassName"
                :id="id"
                v-bind="attrs" />
            <span class="form-error">{{ errorMessage }}</span>
        </div>
        <textarea
            v-else
            v-model="inputValue"
            :class="inputClassName"
            :id="id"
            v-bind="attrs" />
        <div v-if="formText" class="form-text">{{ formText }}</div>
    </component>
</template>

<script>
import {computed, ref} from "vue";
import FormLabel from "@/components/Form/FormLabel";

export default {
    name: "TextareaInput",
    components: {
        FormLabel
    },
    props: {
        id: String,
        label: String,
        modelValue: [String, Number],
        wrapperClass: String,
        labelClass: String,
        invalid: Number,
        formText: String,
        errorMessage: String,
        required: Boolean,
        optional: Boolean,
        wrap: {
            type: Boolean,
            default: true
        },
        tag: {
            type: String,
            default: "div"
        }
    },
    emits: ["update:modelValue"],
    setup(props, { attrs, emit }) {
        const isRequired = ref(props.required)
        const isOptional = ref(props.optional)

        const inputValue = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value)
        })

        const wrapperClassName = computed(() => {
            return props.wrapperClass || "form-group";
        });

        const inputClassName = computed(() => {
            return [
                "form-control",
                props.invalid && 'is-invalid'
            ];
        });

        const labelClassName = computed(() => {
            return props.labelClass || "form-label"
        });

        return {
            isRequired,
            isOptional,
            inputValue,
            wrapperClassName,
            inputClassName,
            labelClassName,
            attrs,
            props
        };
    }
};
</script>