<template>
    <li v-for="currency in currencies" :key="currency">
        <a href="#"
           class="dropdown-item"
           :class="{ active: currency === currentCurrency }"
           @click.prevent="changeCurrentCurrency(currency)">
            <strong>{{ currency}}</strong> - {{ $t('currency.' + currency) }}
        </a>
    </li>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default {
    props: {
        options: Array
    },
    setup( props ) {
        const store = useStore();
        const currencies = ref(props.options)

        return {
            currentCurrency: computed(() => store.getters.currency ),
            changeCurrentCurrency: (val) => store.commit('setCurrency', val),
            currencies
        }
    }
}
</script>