<template>
    <nav class="wizard-steps" v-bind="attrs">
        <div class="nav" role="tablist">
            <slot />
        </div>
    </nav>
</template>

<script>

export default {
    name: "WizardNav",
    props: {
        tag: {
            type: String,
            default: 'nav'
        },
    },
    setup(props, { attrs }) {
        return {
            props,
            attrs
        }
    }
}
</script>