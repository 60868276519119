<template>
    <page-content>
        <template #header>
            <subheader enable-sticky>
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <h1 class="subheader-title h6 mb-0">{{ $t('page.staff_list.heading') }}</h1>
                        <div class="subheader-divider subheader-divider-ver mx-3"></div>
                        <span class="text-muted fs-sm text-nowrap">
                            {{ $t('layout.total_qty', { count: staffCount}) }}
                        </span>
                    </div>
                    <div class="d-flex align-items-center flex-md-grow-1 ms-auto ms-md-3">
                        <form class="form-inline me-md-auto" @submit.prevent>
                            <div class="input-group input-group-sm input-group-flat">
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    :placeholder="$t('layout.search')"
                                    aria-label="Search"/>
                                <span class="input-group-text">
                                    <i class="icon icon-search" aria-hidden="true"></i>
                                </span>
                            </div>
                        </form>
                        <div class="dropdown d-md-none">
                            <a class="btn btn-link text-dark dropdown-toggle px-1 no-caret ms-1"
                               href="#"
                               role="button"
                               id="dropdownMenuLink"
                               data-bs-toggle="dropdown"
                               aria-expanded="false" aria-label="Actions">
                                <i class="icon icon-more-vertical" aria-hidden="true"></i>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <li>
                                    <router-link class="dropdown-item d-flex align-items-center"
                                       :to="{ name: 'add_staff'}">
                                        <i class="icon icon-plus-circle me-2"></i>
                                        {{ $t('btn.add_staff') }}
                                    </router-link>
                                </li>
                                <li>
                                    <a class="dropdown-item d-flex align-items-center"
                                       @click.prevent="exportModal.show()"
                                       href="#">
                                        <i class="icon icon-download me-2"></i>
                                        {{ $t('btn.export') }}
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item d-flex align-items-center"
                                       @click.prevent="filtersModal.show()"
                                       role="button"
                                       href="#">
                                        <i class="icon icon-filter me-2"></i>
                                        {{ $t('btn.filter') }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-none d-md-flex align-items-center ms-auto">
                        <button
                            class="btn btn-outline-light text-dark btn-sm me-2 visible-sticky"
                            type="button"
                            @click.prevent="exportModal.show()">
                            <i class="icon icon-download me-1"></i> {{ $t('btn.export') }}
                        </button>
                        <button
                            class="btn btn-outline-light text-dark btn-sm me-2 visible-sticky"
                            @click.prevent="filtersModal.show()"
                            type="button">
                            <i class="icon icon-filter me-1"></i> {{ $t('btn.filter') }}
                        </button>
                        <router-link
                            class="btn btn-primary btn-sm"
                            :to="{ name: 'add_staff'}">
                            <i class="icon icon-plus-circle me-1"></i> {{ $t('btn.add_staff') }}
                        </router-link>
                    </div>
                </div>
            </subheader>
        </template>
        <template #main>
            <bs-card
                :title="$t('page.staff_list.title')"
                header-border="0"
                header-class="px-lg-5 pt-lg-5 pb-lg-4"
                body-class="px-lg-5 pb-lg-5"
                shadow>
                <template #toolbar1>
                    <bs-btn-group>
                        <bs-btn
                            class="text-dark"
                            variant="outline-light"
                            size="sm"
                            type="button"
                            @click.prevent="exportModal.show()">
                            <i class="icon icon-download me-2"></i> {{ $t('btn.export') }}
                        </bs-btn>
                        <bs-btn
                            class="text-dark"
                            variant="outline-light"
                            size="sm"
                            @click.prevent="filtersModal.show()"
                            type="button">
                            <i class="icon icon-filter me-2"></i> {{ $t('btn.filter') }}
                        </bs-btn>
                    </bs-btn-group>
                </template>
                <div v-if="staffCount">
                    <div class="table-responsive" :class="{ 'mb-4 mb-lg-5' : (staffCount > usersPerPage) }">
                        <table class="table align-middle">
                            <thead>
                            <tr>
                                <th scope="col"
                                    class="sorting"
                                    :class="sortingClass('id')"
                                    @click.prevent="selectOrder('id')">
                                    #
                                </th>
                                <th scope="col"
                                    class="sorting"
                                    :class="sortingClass('login')"
                                    @click.prevent="selectOrder('login')">
                                    {{ $t('layout.table_header.agents') }}
                                </th>
                                <th scope="col">
                                    {{ $t('layout.table_header.city') }}
                                </th>
                                <th scope="col"
                                    class="sorting"
                                    :class="sortingClass('register_date')"
                                    @click.prevent="selectOrder('register_date')">
                                    {{ $t('layout.table_header.registered') }}
                                </th>
                                <th scope="col" class="text-center">
                                    {{ $t('layout.table_header.servers') }}
                                </th>
                                <th scope="col" class="text-center">
                                    {{ $t('layout.table_header.status') }}
                                </th>
                                <th scope="col" class="text-center">
                                    {{ $t('layout.table_header.actions') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(user, index) in usersStruct" :key="user.id">
                                <th scope="row">
                                    <span class="fs-xs">
                                        {{ (pageNumber - 1) * usersPerPage + parseInt(index) + 1 }}
                                    </span>
                                </th>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="symbol" :class="status(user.is_active).symbol">
                                            <div class="symbol-caption">
                                                {{ userInitials(user.first_name, user.last_name) }}
                                            </div>
                                        </div>
                                        <div class="ms-3 me-auto">
                                            <div class="fw-semi-bold text-dark mb-1">
                                                {{ userFullName(user.first_name, user.last_name) }}
                                            </div>
                                            <div class="fs-sm text-muted">
                                                {{ user.email }}
                                            </div>
                                        </div>
                                        <bs-popover
                                            v-if="user.is_default"
                                            :title="$t('default_staff.label')"
                                            :content="$t('default_staff.help')"
                                            trigger="hover"
                                            placement="top">
                                            <button
                                                type="button" class="btn btn-link btn-sm btn-icon"
                                                aria-label="defaultStaff">
                                                <i class="icon icon-star-filled text-warning"></i>
                                            </button>
                                        </bs-popover>
                                    </div>
                                </td>
                                <td>
                                    <span class="fs-sm">{{ user.city }}</span>
                                </td>
                                <td>
                                    <div class="text-dark fs-sm mb-1">
                                        {{ registrationDate(user, $t('layout.not_available')) }}
                                    </div>
                                    <div class="text-muted fs-sm text-nowrap">
                                        {{ $t('layout.last_login') }}:
                                        {{ lastLoginDate(user, $t('layout.not_available')) }}
                                    </div>
                                </td>
                                <td class="text-nowrap text-center">
                                    <span v-for="server in visibleServers(user.server_access)"
                                          :key="server.id"
                                          class="badge fs-xxs bg-light-info text-info px-1 me-1">
                                        {{ server.server_prefix.toUpperCase() }}
                                    </span>
                                    <span v-if="serversCount(user.server_access) > visibleServersCount"
                                          class="badge fs-xxs border-info text-info px-1">
                                        + {{ serversCount(user.server_access) - visibleServersCount }}
                                    </span>
                                </td>
                                <td class="text-center">
                                    <span class="badge" :class="status(user.is_active).badge">
                                        {{ status(user.is_active).badgeLabel }}
                                    </span>
                                </td>
                                <td class="text-nowrap text-center">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-icon btn-outline-light border-0 text-dark"
                                        aria-label="Disable" @click.prevent="toggleUser(user)">
                                        <i class="icon"
                                           :class="status(user.is_active).controlIcon"
                                           aria-hidden="true"></i>
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-icon btn-outline-light border-0 text-dark"
                                        aria-label="Edit"
                                        @click.prevent="$router.push( { name: 'staff_details', params: { id: user.account_id } } )">
                                        <i class="icon icon-edit" aria-hidden="true"></i>
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-icon btn-outline-light border-0 text-danger"
                                        aria-label="delete" @click.prevent="deleteUser(user)">
                                        <i class="icon icon-trash-2" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination
                        v-if="staffCount > usersPerPage"
                        @change="paginationChanged"
                        :page="pageNumber"
                        :per-page="usersPerPage"
                        :per-page-options="[5, 10, 15, 20]"
                        :items="usersStruct.count" />
                </div>
                <div v-else class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="card border-0">
                            <div class="card-body text-center">
                                <i class="icon icon-info icon-32 text-primary mb-4"></i>
                                <h4>{{ $t('page.staff_list.no_items.title') }}</h4>
                                <p class="mb-4">{{ $t('page.staff_list.no_items.text') }}</p>
                                <button class="btn btn-light-primary">
                                    {{ $t('btn.add_staff') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </bs-card>
        </template>
        <template #footer>
            <bs-offcanvas
                id="tableFilter"
                placement="end"
                aria-labelledby="tableFilterLabel"
                v-model="filtersModal">
                <template #header>
                    <h5 id="tableFilterLabel" class="mb-0">
                        {{ $t('layout.filter') }}
                    </h5>
                </template>
                <div class="mb-4">
                    <date-range-picker
                        v-model="filters.dateRegistered"
                        id="filterDateRegistered"
                        :label="$t('filter.date_registered')"
                        label-class="search-col-label"
                        data-wrapper="dateRegistered"
                        :data-label="$t('filter.date_registered')"
                        data-label-from="dd/mm/yy"
                        data-label-to="dd/mm/yy" />
                </div>
                <div class="mb-4">
                    <date-range-picker
                        v-model="filters.lastLogin"
                        :label="$t('filter.last_login')"
                        label-class="search-col-label"
                        data-wrapper="dateRegistered"
                        :data-label="$t('filter.last_login')"
                        data-label-from="dd/mm/yy"
                        data-label-to="dd/mm/yy" />
                </div>
                <div class="mb-4">
                    <label class="form-label">{{ $t('filter.status') }}</label>
                    <radio-input
                        v-model="filters.status"
                        value="-"
                        id="status_all"
                        :label="$t('filter.user_status.all')"
                        name="status"
                        checked />
                    <radio-input
                        v-model="filters.status"
                        id="status_active"
                        :value="true"
                        :label="$t('filter.user_status.active')"
                        name="status" />
                    <radio-input
                        v-model="filters.status"
                        :value="false"
                        id="status_disabled"
                        :label="$t('filter.user_status.disabled')"
                        name="status" />
                </div>
                <div class="mb-4">
                    <label class="form-label">{{ $t('filter.country') }}</label>
                    <country-select
                        id="country"
                        v-model="filters.country"
                        :placeholder="$t('form.country.placeholder')" />
                </div>
                <template #footer>
                    <div class="d-grid gap-2 d-md-block text-md-center w-100">
                        <button
                            class="btn btn-primary me-md-2"
                            type="button"
                            @click.prevent="applyFilter">
                            {{ $t('btn.apply_filter') }}
                        </button>
                        <button
                            class="btn btn-light-primary"
                            type="button"
                            @click.prevent="resetFilter">
                            {{ $t('btn.reset_filter') }}
                        </button>
                    </div>
                </template>
            </bs-offcanvas>
            <bs-modal
                id="exportFields"
                aria-labelledby="exportFieldsLabel"
                v-model="exportModal"
                :centered="true"
                footer-class="d-block d-md-flex justify-content-md-end py-3">
                <template #header>
                    <h5 id="exportFieldsLabel" class="mb-0">
                        {{ $t('layout.choose_export_fields') }}
                    </h5>
                </template>
                <div class="row row-cols-1 row-cols-md-2 gx-4 gy-1 mb-3">
                    <div
                        class="col"
                        v-for="(field, fieldIndex) in exportFields"
                        :key="fieldIndex">
                        <checkbox-input
                            :id="field.name"
                            v-model="exportFields[fieldIndex].value"
                            :label="$t('export_fields.' + field.name)" />
                    </div>
                </div>
                <button type="button"
                        class="btn btn-link btn-sm p-0"
                        @click.prevent="setChecks(true)">
                    {{ $t('btn.select_all') }}
                </button>
                <span class="text-muted mx-1">·</span>
                <button type="button"
                        class="btn btn-link btn-sm p-0"
                        @click.prevent="setChecks(false)">
                    {{ $t('btn.deselect_all') }}
                </button>
                <template #footer>
                    <div class="d-grid gap-2 d-md-block">
                        <button
                            class="btn btn-light text-dark me-md-2"
                            type="button"
                            @click.prevent="exportModal.hide()">
                            {{ $t('btn.close') }}
                        </button>
                        <button
                            class="btn btn-light-primary"
                            type="button"
                            @click.prevent="exportUsers">
                            {{ $t('btn.export_users') }}
                        </button>
                    </div>
                </template>
            </bs-modal>
        </template>
    </page-content>
</template>

<script>
import {ref, watch, reactive, computed} from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import useHelpers from '@/modules/helpers';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Api from "@/modules/api"
import DateRangePicker from '@/components/Form/DateRangePicker';
import RadioInput from '@/components/Form/RadioInput';
import Subheader from '@/components/Subheader';
import PageContent from '@/components/PageContent';
import CountrySelect from '@/components/Form/CountrySelect';
import Pagination from '@/components/Pagination';
import bsModal from '@/components/Bootstrap/Modal/Modal';
import bsOffcanvas from '@/components/Bootstrap/Offcanvas/Offcanvas';
import bsPopover from "@/components/Bootstrap/Popover/Popover"
import CheckboxInput from "@/components/Form/CheckboxInput";
import Button from "@/components/Bootstrap/Button/Button"
import ButtonGroup from "@/components/Bootstrap/ButtonGroup/ButtonGroup"
import Card from "@/components/Bootstrap/Card/Card"

export default {
    components: {
        PageContent,
        DateRangePicker,
        RadioInput,
        Subheader,
        CountrySelect,
        Pagination,
        bsModal,
        bsOffcanvas,
        bsPopover,
        CheckboxInput,
        bsBtn: Button,
        bsBtnGroup: ButtonGroup,
        bsCard: Card
    },
    setup() {
        const filtersModal = ref(null);
        const exportModal = ref(null);
        const { t } = useI18n({ useScope: 'global' });
        const {
            dateToStr,
            userInitials,
            userFullName,
            registrationDate,
            lastLoginDate
        } = useHelpers;
        const route = useRoute();
        const activeUser = ref(null);
        const usersStruct = ref({});
        const visibleServersCount = ref(2);
        const usersPerPage = ref(10);
        const pageNumber = ref(1);
        const orderBy = ref('id');
        const orderDesc = ref(false);

        const exportFields = reactive([
            { name: 'account_id', value: true },
            { name: 'login', value: true },
            { name: 'email', value: true },
            { name: 'first_name', value: true },
            { name: 'last_name', value: true },
            { name: 'city', value: true },
            { name: 'remark', value: true },
            { name: 'registration_date', value: true },
            { name: 'server_access', value: true },
            { name: 'last_login_date', value: true },
            { name: 'is_active', value: true },
            { name: 'is_deleted', value: false },
            { name: 'is_default', value: false },
            { name: 'parent_id', value: false },
            { name: 'session_id', value: false },
            { name: 'session_name', value: false },
            { name: 'last_ip', value: false },
            { name: 'last_client', value: false }
        ])

        const setChecks = (value) => {
            exportFields.forEach( field => {
                field.value = value
            })
        };

        const paginationChanged = (e) => {
            pageNumber.value = e.currentPage;
            usersPerPage.value = e.itemsPerPage;
        }

        const staffCount = computed(() => {
            return Object.keys(usersStruct.value).length
        })

        watch([pageNumber, usersPerPage], () => {
            reloadUsers();
        })

        const filters = reactive({
            dateRegistered: null,
            lastLogin: null,
            status: '-',
            country: null
        })

        const selectOrder = (order) => {
            if ( order === orderBy.value ) {
                orderDesc.value = !orderDesc.value;
            } else {
                orderDesc.value = false;
                orderBy.value = order;
            }
            reloadUsers();
        }

        const sortingClass = (order) => {
            if (orderBy.value === order) {
                return orderDesc.value ? 'sort-desc' : 'sort-asc';
            }
            return '';
        }

        const reloadUsers = async () => {
            usersStruct.value = await Api.getStaffList()
            console.log(usersStruct.value[0])
        }

        const getFilter = () => {
            let filter = {'group': route.params.type};
            if ( filters.status !== undefined && filters.status !== '-' ) {
                filter = { ...filter, is_active: filters.status };
            }
            if (filters.country) {
                filter = {...filter, country: filters.country};
            }
            if (filters.dateRegistered) {
                filter = {
                    ...filter,
                    register_interval: {
                        date_from: filters.dateRegistered.slice(0, 10),
                        date_to: filters.dateRegistered.slice(-10)
                    }
                };
            }
            if (filters.lastLogin) {
                filter = {
                    ...filter,
                    last_login_interval: {
                        date_from: filters.lastLogin.slice(0, 10),
                        date_to: filters.lastLogin.slice(-10)
                    }
                };
            }

            return {
                filter: {
                    group: 'staff',
                    email_activation: true,
                    is_deleted: false,
                    ...filter
                },
                order: orderBy.value,
                order_by_desc: orderDesc.value,
                start: (pageNumber.value - 1) * usersPerPage.value,
                limit: usersPerPage.value
            };
        }

        const visibleServers = (servers) => {
            let obj = {};
            const dsKeys = Object.keys(servers);
            const num = dsKeys.length > visibleServersCount.value ? visibleServersCount.value : dsKeys.length;

            for (let i = 0; i < num; i++) {
                obj[i] = servers[dsKeys[i]];
            }

            return obj;
        }

        const serversCount = (servers) => {
            return Object.keys(servers).length;
        }

        const serversListHtml = (servers) => {
            let listHtml = '';
            const dsKeys = Object.keys(servers);

            for (let i = 0; i < dsKeys.length; i++) {
                listHtml = listHtml + '<li>' + servers[dsKeys[i]].server_name + '</li>'
            }

            return '<ul class="list-unstyled">' + listHtml + '</ul>';
        }

        const resetFilter = () => {
            filters.dateRegistered = null;
            filters.lastLogin = null;
            filters.status = '-';
            filters.country = null;
            filtersModal.value.hide();
            reloadUsers();
        }

        const applyFilter = () => {
            filtersModal.value.hide();
            reloadUsers();
        }

        const status = (status) => {
            return {
                symbol: status ? 'symbol-light-success' : 'symbol-light-danger',
                badge: status ? 'bg-light-success text-success' : 'bg-light-danger text-danger',
                badgeLabel: status ? t('layout.status.active') : t('layout.status.disabled'),
                controlIcon: status ? 'icon-toggle-right' : 'icon-toggle-left',
            }
        }

        const toggleUser = (user) => {
            activeUser.value = user;

            Swal.fire({
                title: user.is_active ? t('user_deactivation.title') : t('user_activation.title'),
                text: user.is_active ? t('user_deactivation.text') : t('user_activation.text'),
                icon: user.is_active ? 'error' : 'success',
                showCancelButton: true,
                confirmButtonText: user.is_active ? t('user_deactivation.action') : t('user_activation.action'),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const userData = {
                        account_id: activeUser.value.account_id,
                        email: activeUser.value.email,
                        is_active: !activeUser.value.is_active,
                        is_default: activeUser.value.is_default,
                        first_name: activeUser.value.first_name,
                        last_name: activeUser.value.last_name,
                        city: activeUser.value.city,
                        remark: activeUser.value.remark,
                    }

                    await Api.saveStaffInfo(userData);
                    await reloadUsers();
                }
            })
        }

        const deleteUser = (user) => {
            Swal.fire({
                title: t('user_deletion.title'),
                text: t('user_deletion.text'),
                icon: 'error',
                showCancelButton: true,
                confirmButtonText: t('user_deletion.action')
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await Api.saveStaff({...user, is_deleted: true});
                    await reloadUsers();
                }
            })
        }

        const exportUsers = async () => {
            exportModal.value.hide();
            let filter = getFilter();
            delete filter.start;
            delete filter.limit;

            const json = await Api.getLoginList(filter);
            const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
            const header = Object.keys(json.result[0])
            const csv = [
                header.join(';'), // header row first
                ...json.result.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'))
            ].join('\r\n');
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'users.csv';
            anchor.click();
        }

        reloadUsers();

        return {
            activeUser,
            usersStruct,
            usersPerPage,
            serversCount,
            visibleServers,
            serversListHtml,
            visibleServersCount,
            resetFilter,
            applyFilter,
            dateToStr,
            userInitials,
            userFullName,
            status,
            reloadUsers,
            toggleUser,
            pageNumber,
            orderBy,
            orderDesc,
            sortingClass,
            selectOrder,
            filters,
            exportUsers,
            paginationChanged,
            staffCount,
            filtersModal,
            exportModal,
            deleteUser,
            registrationDate,
            lastLoginDate,
            exportFields,
            setChecks
        }
    }
}

</script>

<style lang="scss">
@import "./src/assets/styles/vendors/sweetalert2/sweetalert2";

.visible-sticky {
    visibility: hidden;

    .subheader.sticky & {
        visibility: visible;
    }
}
</style>