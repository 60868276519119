<template>
    <component :is="tag" :class="wrapperClassName">
        <label v-if="label" ref="labelRef" class="form-label" :for="id">
            {{ label }}
        </label>
        <multiselect
            mode="single"
            :id="id"
            :searchable="true"
            :class="selectClassName"
            v-model="inputValue"
            :options="options"
            :model-value="inputValue"
            @chage="handleInput"
            :placeholder="placeholder"
            v-bind="attrs"
        />
    </component>
</template>

<script>
import {computed, ref, watch, watchEffect} from "vue";
import Multiselect from '@vueform/multiselect';
import countries from '@/json/countries.en.json';

export default {
    name: 'CountrySelect',
    components: { Multiselect },
    props: {
        id: {
            type: String,
            default: 'countrySelect'
        },
        placeholder: String,
        modelValue: String,
        label: String,
        invalid: Number,
        wrapperClass: String,
        selectClass: String,
        tag: {
            type: String,
            default: 'div'
        }
    },
    emits: ["update:modelValue"],
    setup(props, { attrs, emit }) {
        const options = ref(countries);
        const inputValue = ref(props.modelValue);
        const labelRef = ref(null);

        const wrapperClassName = computed(() => {
            return [
                props.wrapperClass
            ];
        });

        const selectClassName = computed(() => {
            return [
                props.selectClass,
                props.invalid && 'is-invalid'
            ];
        });

        function handleInput(e) {
            inputValue.value = e.target.value;
            emit("update:modelValue", inputValue.value);
        }

        watch(inputValue, () => emit("update:modelValue", inputValue.value));

        watchEffect(() => {
          inputValue.value = props.modelValue
        });


        return {
            attrs,
            props,
            options,
            inputValue,
            labelRef,
            wrapperClassName,
            selectClassName,
            handleInput
        }
    }
}
</script>

<style lang="scss">
    @import "./src/assets/styles/vendors/multiselect";
</style>
