<template>
    <component
        :is="tag"
        class="tab-content"
        v-bind="attrs">
        <slot />
    </component>
</template>

<script>

export default {
    name: "WizardTabContent",
    props: {
        tag: {
            type: String,
            default: 'div'
        },
    },
    setup(props, { attrs }) {
        return {
            props,
            attrs
        }
    }
}
</script>