<template>
    <component :is="tag" class="tab-content">
        <slot />
    </component>
</template>

<script>
export default {
    name: "TabContent",
    props: {
        tag: {
            type: String,
            default: 'div'
        }
    }
}
</script>