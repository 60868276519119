<template>
    <component :is="tag" :class="wrapperClassName" @click="inputEl.click()">
        <div class="card-check-content">
            <slot />
        </div>
        <input
            ref="inputEl"
            :type="inputType"
            v-model="inputValue"
            class="form-check-input"
            :id="id"
            :name="name"
            :aria-label="id"
            @click.stop
            v-bind="attrs" />
    </component>
</template>

<script>
import { ref, computed } from "vue";

const inputTypes = [ 'radio', 'checkbox' ];

export default {
    name: 'CardCheck',
    props: {
        id: String,
        name: String,
        modelValue: [String, Number, Boolean],
        inputType: {
            type: String,
            default: 'radio',
            validator: function(value) {
                return inputTypes.includes(value);
            }
        },
        class: String,
        tag: {
            type: String,
            default: 'div'
        }
    },
    emits: ['update:modelValue'],
    setup(props, { attrs, emit }) {
        const inputEl = ref();

        const inputValue = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value)
        })

        const wrapperClassName = computed( ()=> {
            return [
                'card-check card',
                props.class,
                !!inputEl.value && !!inputEl.value.checked && 'active'
            ]
        })

        return {
            inputEl,
            inputValue,
            wrapperClassName,
            attrs,
            props
        };
    }
};
</script>