<template>
    <bs-dropdown-item class="fs-sm text-muted d-flex align-items-center px-4">
        <div class="symbol" :class="'symbol-light-' + type">
            <span class="symbol-caption fs-5">
                <i class="icon" :class="icons[type]" aria-hidden="true"></i>
            </span>
        </div>
        <div class="ms-3">
            <span class="fw-semi-bold d-block text-dark">
                {{ title }}
            </span>
            {{ text }}
        </div>
    </bs-dropdown-item>
</template>

<script>
import { ref } from 'vue';
import bsDropdownItem from "@/components/Bootstrap/Dropdown/DropdownItem";

export default {
    name: "NotificationItem",
    components: { bsDropdownItem },
    props: {
        notification: Object
    },
    setup(props) {
        const type = ref(props.notification.type);
        const title = ref(props.notification.title);
        const text = ref(props.notification.text);
        const icons = ref({
            info: 'icon-info',
            danger: 'icon-slash',
            warning: 'icon-pin',
            success: 'icon-check'
        })

        return {
            type,
            title,
            text,
            icons
        }
    }
}
</script>