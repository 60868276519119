<template>
    <component :is="tag" v-if="wrap" :class="wrapperClassName">
        <input
            :class="inputClassName"
            v-model="inputValue"
            type="checkbox"
            :id="id"
            :name="name"
            v-bind="attrs"
        />
        <label v-if="label" :class="labelClassName" :for="id">
            {{ label }}
            <span v-if="formText" class="form-text d-block">
                {{ formText }}
            </span>
        </label>
    </component>
    <input
        v-if="!wrap"
        :class="inputClassName"
        v-model="inputValue"
        type="checkbox"
        :id="id"
        :name="name"
        v-bind="attrs"
    />
    <label v-if="!wrap && label" :class="labelClassName" :for="id">
        {{ label }}
        <span v-if="formText" class="form-text d-block">
            {{ formText }}
        </span>
    </label>
</template>

<script>
import {computed} from "vue";

export default {
    name: "CheckboxInput",
    props: {
        id: String,
        label: String,
        name: String,
        modelValue: Boolean,
        inline: Boolean,
        wrapperClass: String,
        labelClass: String,
        btnCheck: Boolean,
        switch: Boolean,
        formText: String,
        wrap: {
            type: Boolean,
            default: true
        },
        formCheck: {
            type: Boolean,
            default: true
        },
        tag: {
            type: String,
            default: "div"
        }
    },
    emits: ["update:modelValue"],
    setup(props, { attrs, emit }) {
        const inputValue = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value)
        })

        const wrapperClassName = computed(() => {
            return [
                props.formCheck && !props.btnCheck && "form-check",
                props.switch && "form-switch",
                props.inline && "form-check-inline",
                props.wrapperClass
            ];
        });

        const inputClassName = computed(() => {
            return [
                props.btnCheck ? "btn-check" : "form-check-input"
            ];
        });
        const labelClassName = computed(() => {
            return [
                props.labelClass || "form-check-label"
            ];
        });

        return {
            wrapperClassName,
            inputClassName,
            labelClassName,
            inputValue,
            attrs,
            props
        };
    }
};
</script>