<template>
    <div class="page-loader" v-if="isActive">
        <div class="ball-scale-multiple">
            <div class="ball"></div>
            <div class="ball"></div>
            <div class="ball"></div>
        </div>
    </div>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
    name: "PageLoader",
    setup() {
        const store = useStore();

        return {
            isActive: computed(() => !store.getters.isLoaded ),
        }
    }
}
</script>

<style lang="scss">
$ball-bg-color: var(--bs-primary);
$loader-bg-color: var(--bs-white);

@function delay($interval, $count, $index) {
    @return ($index * $interval) - ($interval * $count);
}

@keyframes scale-multiple {
    0% {
        transform: scale(0.0);
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    100% {
        transform: scale(1.0);
        opacity: 0;
    }
}

@mixin ball-scale-multiple ($n: 3, $start: 2) {
    @for $i from $start through $n {
        .ball:nth-child(#{$i}) {
            animation-delay: delay(0.2s, $n, $i - 1);
        }
    }
}

.page-loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1030;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $loader-bg-color;
}

.ball-scale-multiple {
    position: relative;
    @include ball-scale-multiple();

    .ball {
        background-color: $ball-bg-color;
        width: 60px;
        height: 60px;
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        animation: scale-multiple 1s 0s linear infinite;
        animation-fill-mode: both;
    }
}
</style>