<template>
    <div :class="subheaderClasses" ref="subheader" v-bind="attrs">
        <div class="container">
            <slot />
        </div>
    </div>
</template>

<script>
import {onMounted, ref, computed} from 'vue';

export default {
    props: {
        enableSticky: Boolean
    },
    setup(props, {attrs}) {
        const subheader = ref(null)
        const isSticky = ref(false);
        const isStickyEnabled = ref(props.enableSticky);

        const subheaderClasses = computed(() => {
            return [
                'subheader',
                isSticky.value && isStickyEnabled.value && 'sticky'
            ]
        })

        const updateStickyHeader = () => {
            if (!subheader.value) return;
            const offset = subheader.value.offsetTop + subheader.value.offsetHeight;
            isSticky.value = window.scrollY > offset;
        }

        onMounted(async () => {
            window.addEventListener('scroll', updateStickyHeader);
        });

        return {
            attrs,
            subheader,
            isSticky,
            isStickyEnabled,
            subheaderClasses
        }
    }
}
</script>