<template>
    <bs-offcanvas v-model="offcanvas" id="menuOffcanvas">
        <ul class="nav flex-column" id="offcanvasNav">
            <li class="nav-item" v-if="false">
                <a class="nav-link collapse-toggle text-truncate"
                   href="#accommodationCollapse"
                   data-bs-toggle="collapse"
                   aria-expanded="false"
                   aria-controls="accommodationCollapse">
                    <span class="nav-link-text">Accommodation</span>
                </a>
                <div id="accommodationCollapse" class="collapse" data-bs-parent="#offcanvasNav">
                    <ul class="nav nav-submenu flex-column">
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                Dynamic rates
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link">
                                Cached rates
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="nav-item" @click="offcanvas.hide()">
                <router-link
                    class="nav-link text-truncate"
                    :to="{ name: userGroup.name === 'admin' ? 'partner_list' : 'staff_list' }">
                    <span class="nav-link-text">Users List</span>
                </router-link>
            </li>
            <li class="nav-item" @click="offcanvas.hide()" v-if="userGroup.name === 'admin'">
                <router-link
                    class="nav-link text-truncate"
                    :to="{ name: 'dsc_list' }">
                    <span class="nav-link-text">DS Companies</span>
                </router-link>
            </li>
            <li class="nav-item">
                <a href="#" class="nav-link text-truncate">
                    <span class="nav-link-text">Contact</span>
                </a>
            </li>
        </ul>
        <template #footer>
            <div class="py-3 w-100 text-center">
                <router-link
                    class="btn btn btn-light text-muted"
                    :to="{ name: 'logout' }">
                    Logout
                </router-link>
            </div>
        </template>
    </bs-offcanvas>
</template>

<script>
import { ref } from "vue";
import { useStore } from 'vuex';
import bsOffcanvas from "@/components/Bootstrap/Offcanvas/Offcanvas";

export default {
    name: "Navigation",
    components: {
        bsOffcanvas
    },
    setup() {
        const offcanvas = ref(null);
        const store = useStore();

        const userGroup = store.getters.user.groups.find( g => {
          return g.def
        });

        return {
            offcanvas,
            userGroup
        }
    }
}
</script>


