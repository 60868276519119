<template>
	<div></div>
</template>

<script>
import Api from '../modules/api'
export default {
  async beforeCreate() {
    await Api.logout();
    await this.$router.replace({name: 'login'});
  }
}
</script>
