<template>
	<div class="d-flex flex-column flex-root">
		<div class="login login-4 d-flex flex-row-fluid login-signin-on" id="kt_login">
			<div class="d-flex flex-center flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat" style="background-image: url('assets/media/bg/bg-3.jpg');">
				<div class="login-form text-center p-7 position-relative overflow-hidden">

					<div class="d-flex flex-center mb-15">
						<a href="#">
							<img src="assets/logo.png" alt="" />
						</a>
					</div>

					<div class="login-signin">
						<div class="mb-20">
							<h3>Efrito 2</h3>
						</div>
                        <p>Please wait while we are signing you in.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {return {

	}},
	methods: {

	}
}
</script>

<style lang="scss" scoped>
    //@import "../assets/styles/pages/login/classic/login-4";
</style>
