<!-- Vue component -->
<template>
    <component :is="tag" :class="wrapperClassName">
        <form-label v-if="title" :for="id" :required="isRequired" :optional="isOptional">
            {{ title }}
        </form-label>
        <div class="form-error-wrap" v-if="!!errorMessage">
            <multiselect
                :class="inputClassName"
                v-model="message"
                :options="options"
                :id="id"
                :placeholder="placeholder"
                v-bind="attrs" />
            <span class="form-error">{{ errorMessage }}</span>
        </div>
        <multiselect
            v-else
            :class="inputClassName"
            v-model="message"
            :options="options"
            :id="id"
            :placeholder="placeholder"
            v-bind="attrs" />
        <div v-if="formText" class="form-text">{{ formText }}</div>
    </component>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import FormLabel from "@/components/Form/FormLabel";
import { computed, ref } from "vue";

export default {
    name: "SelectInput",
    components: {
        Multiselect,
        FormLabel
    },
    props: {
        id: {
            type: String,
            required: true
        },
        required: Boolean,
        optional: Boolean,
        invalid: Number,
        name: String,
        title: String,
        formText: String,
        errorMessage: String,
        placeholder: String,
        wrapperClass: String,
        tag: {
            type: String,
            default: "div"
        },
        modelValue: [String, Number, Array],
        value: String,
        options: Object
    },
    emits: ["update:modelValue"],
    setup(props, { attrs, emit }) {
        const isRequired = ref(props.required)
        const isOptional = ref(props.optional)

        const message = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value)
        })

        const wrapperClassName = computed(() => {
            return [ props.wrapperClass || "form-group" ];
        });

        const inputClassName = computed(() => {
            return [
                props.size && `form-control-${props.size}`
            ];
        });

        return {
            isRequired,
            isOptional,
            wrapperClassName,
            inputClassName,
            attrs,
            props,
            message
        };
    }
}
</script>

<style lang="scss">
    @import "./src/assets/styles/vendors/multiselect";
</style>
