<template>
	<router-view></router-view>
</template>

<script>
export default {
	computed: {
		currentUser() {
			return this.$store.getters.user;
		}
	},
	watch: {
		currentUser(newVal) {
			// console.log('new user');
			// console.log(newVal);
			if (newVal === null) {
				this.$router.replace({name: 'login'});
			}
		}
	},
	created() {
/*
		this.$store.dispatch('loadToken');
		this.$store.dispatch('checkToken').then(res => {
			if (res) {
				this.$router.replace({name: 'home'});
			}
		});
		this.$store.dispatch('loadFromLocalStorage');
 */
	}
}
</script>
