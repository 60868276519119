<template>
    <component
        :is="tag"
        :role="role"
        class="btn-group"
        :class="{ 'btn-group-vertical' : vertical }">
        <slot />
    </component>
</template>

<script>
export default {
    name: "bsBtnGroup",
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        role: {
            type: String,
            default: 'group'
        },
        vertical: Boolean
    }
}
</script>