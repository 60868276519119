<template>
    <login-content>
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6">
                    <div class="card text-center border-0">
                        <i class="icon icon-info text-primary icon-32 mb-4" aria-hidden="true"></i>
                        <h3 class="mb-3">{{ $t('email_confirmed.title')}}</h3>
                        <p class="lead">{{ $t('email_confirmed.text')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </login-content>
</template>

<script>
import {useRoute} from 'vue-router';
import Api from '@/modules/api';
import {onBeforeMount} from "vue";
import LoginContent from "@/components/LoginContent";

export default {
    name: "AccountActivation",
    components: {
        LoginContent
    },
    setup () {
        const route = useRoute();

        onBeforeMount( async () => {
            await Api.activateLogin(route.params.key);
        })
    }
}
</script>
