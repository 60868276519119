<template>
    <page-content>
        <template #header>
            <subheader enable-sticky>
                <div class="d-flex align-items-center">
                    <div class="d-flex align-items-center">
                        <h1 class="subheader-title h6 mb-0">{{ $t('page.agents.heading') }}</h1>
                        <div class="subheader-divider subheader-divider-ver mx-3"></div>
                        <span class="text-muted fs-sm text-nowrap">
                            {{ $t('layout.total_qty', { count: usersStruct.count}) }}
                        </span>
                    </div>
                    <div class="d-flex align-items-center flex-md-grow-1 ms-auto ms-md-3">
                        <form class="form-inline me-md-auto" @submit.prevent>
                            <div class="input-group input-group-flat">
                                <input type="text"
                                       v-model="filters.searchString"
                                       @input="searchInput"
                                       class="form-control form-control-sm"
                                       :placeholder="$t('layout.search')"
                                       aria-label="Search"/>
                                <span class="input-group-text">
                                    <i class="icon icon-search" aria-hidden="true"></i>
                                </span>
                            </div>
                        </form>
                        <div class="dropdown d-md-none">
                            <a class="btn btn-link text-dark dropdown-toggle px-1 no-caret ms-1"
                               href="#"
                               role="button"
                               id="dropdownMenuLink"
                               data-bs-toggle="dropdown"
                               aria-expanded="false" aria-label="Actions">
                                <i class="icon icon-more-vertical" aria-hidden="true"></i>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <li>
                                    <a class="dropdown-item d-flex align-items-center"
                                       href="#">
                                        <i class="icon icon-plus-circle me-2"></i>
                                        {{ $t('btn.add_agent') }}
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item d-flex align-items-center"
                                       @click.prevent="exportModal.show()"
                                       href="#">
                                        <i class="icon icon-download me-2"></i>
                                        {{ $t('btn.export') }}
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item d-flex align-items-center"
                                       @click.prevent="filtersModal.show()"
                                       role="button"
                                       href="#">
                                        <i class="icon icon-filter me-2"></i>
                                        {{ $t('btn.filter') }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-none d-md-flex align-items-center ms-auto">
                        <button
                            class="btn btn-outline-light text-dark btn-sm me-2 visible-sticky"
                            type="button"
                            @click.prevent="exportModal.show()">
                            <i class="icon icon-download me-2"></i> {{ $t('btn.export') }}
                        </button>
                        <button
                            class="btn btn-outline-light text-dark btn-sm me-2 visible-sticky"
                            @click.prevent="filtersModal.show()"
                            type="button">
                            <i class="icon icon-filter me-2"></i> {{ $t('btn.filter') }}
                        </button>
                        <router-link
                            class="btn btn-primary btn-sm"
                            role="button"
                            :to="{ name: 'partner_details'}">
                            <i class="icon icon-plus-circle me-1"></i> {{ $t('btn.add_agent') }}
                        </router-link>
                    </div>
                </div>
            </subheader>
        </template>
        <template #main>
            <bs-card
                :title="$t('page.agents.title')"
                header-border="0"
                header-class="px-lg-5 pt-lg-5 pb-lg-4"
                body-class="px-lg-5 pb-lg-5"
                shadow>
                <template #toolbar1>
                    <bs-btn-group>
                        <bs-btn
                            class="text-dark"
                            variant="outline-light"
                            size="sm"
                            type="button"
                            @click.prevent="exportModal.show()">
                            <i class="icon icon-download me-2"></i> {{ $t('btn.export') }}
                        </bs-btn>
                        <bs-btn
                            class="text-dark"
                            variant="outline-light"
                            size="sm"
                            @click.prevent="filtersModal.show()"
                            type="button">
                            <i class="icon icon-filter me-2"></i> {{ $t('btn.filter') }}
                        </bs-btn>
                    </bs-btn-group>
                </template>
                <div v-if="usersStruct.count">
                    <div class="table-responsive" :class="{ 'mb-4 mb-lg-5': usersStruct.count > usersPerPage }">
                        <table class="table align-middle">
                            <thead>
                            <tr>
                                <th scope="col"
                                    class="sorting"
                                    :class="sortingClass('id')"
                                    @click.prevent="selectOrder('id')">
                                    #
                                </th>
                                <th scope="col"
                                    class="sorting"
                                    :class="sortingClass('login')"
                                    @click.prevent="selectOrder('login')">
                                    {{ $t('layout.table_header.agents') }}
                                </th>
                                <th scope="col">
                                    {{ $t('layout.table_header.country') }}
                                </th>
                                <th scope="col"
                                    class="sorting"
                                    :class="sortingClass('register_date')"
                                    @click.prevent="selectOrder('register_date')">
                                    {{ $t('layout.table_header.registered') }}
                                </th>
                                <th scope="col" class="text-center">
                                    {{ $t('layout.table_header.servers') }}
                                </th>
                                <th scope="col" class="text-center">
                                    {{ $t('layout.table_header.status') }}
                                </th>
                                <th scope="col" class="text-center">
                                    {{ $t('layout.table_header.actions') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(user, index) in usersStruct.result" :key="user.id">
                                <th scope="row">
                                        <span class="fs-xs">
                                            {{ (pageNumber - 1) * usersPerPage + parseInt(index) + 1 }}
                                        </span>
                                </th>
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="symbol" :class="status(user.is_active).symbol">
                                            <div class="symbol-caption">
                                                {{ user.additional_data.company_name.charAt(0) }}
                                            </div>
                                        </div>
                                        <div class="ms-3">
                                            <div class="fw-semi-bold text-dark mb-1">
                                                {{ user.additional_data.company_name }}
                                            </div>
                                            <div :class="{'fs-sm': true,  'text-muted': user.email_activation, 'text-danger': !user.email_activation}">
                                                {{ user.email }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="fs-sm">
                                    <div class="text-dark mb-1">
                                        {{ user.additional_data.country }}
                                    </div>
                                    <div class="text-muted text-capitalize">
                                        {{ user.additional_data.city }}
                                    </div>
                                </td>
                                <td class="fs-sm">
                                    <div class="text-dark mb-1">
                                        {{ user.registration_date ? dateToStr(user.registration_date) : $t('layout.not_available') }}
                                    </div>
                                    <div class="text-muted text-nowrap">
                                        {{ $t('layout.last_login') }}:
                                        {{ user.last_login_date ? dateToStr(user.last_login_date) : $t('layout.not_available') }}
                                    </div>
                                </td>
                                <td class="text-nowrap text-center">
                                    <span v-for="server in visibleServers(user.server_access)"
                                          :key="server.id"
                                          class="badge fs-xxs bg-light-info text-info px-1 me-1">
                                        {{ server.country_iso.toUpperCase() }}
                                    </span>
                                    <bs-popover
                                        v-if="serversCount(user.server_access) > visibleServersCount"
                                        :title="$t('layout.data_servers')"
                                        :content="serversListHtml(user.server_access)"
                                        trigger="hover"
                                        placement="top"
                                        :html="true">
                                            <span class="badge fs-xxs border-info text-info px-1 me-1">
                                                + {{ serversCount(user.server_access) - visibleServersCount }}
                                            </span>
                                    </bs-popover>
                                </td>
                                <td class="text-center">
                                        <span class="badge" :class="status(user.is_active).badge">
                                            {{ status(user.is_active).badgeLabel }}
                                        </span>
                                </td>
                                <td class="text-nowrap text-center">
                                    <bs-btn
                                        type="button"
                                        variant="outline-light"
                                        size="sm"
                                        class="btn-icon border-0 text-dark"
                                        aria-label="Edit"
                                        @click.prevent="$router.push( { name: 'partner_details', params: { id: user.id } } )">
                                        <i class="icon icon-edit" aria-hidden="true"></i>
                                    </bs-btn>
                                    <bs-dropdown class="d-inline-block">
                                        <bs-btn
                                            data-bs-toggle="dropdown"
                                            @click="createUserToken(user, $event)"
                                            aria-expanded="false"
                                            variant="outline-light"
                                            size="sm"
                                            class="btn-icon border-0 text-dark"
                                            aria-label="userMenu">
                                            <i class="icon icon-more-vertical" aria-hidden="true"></i>
                                        </bs-btn>
                                        <bs-dropdown-menu>
                                            <bs-dropdown-item
                                                class="d-flex align-items-center"
                                                @click.prevent="toggleUser(user)"
                                                role="button">
                                                <i class="icon icon-toggle-left me-3 fs-sm"></i>
                                                {{ $t(user.is_active ? 'btn.disable_user' : 'btn.enable_user') }}
                                            </bs-dropdown-item>
                                            <bs-dropdown-item
                                                class="d-flex align-items-center disabled"
                                                data-target="login"
                                                role="button"
                                                @click="$event.target.classList.add('disabled')">
                                                <i class="icon icon-log-in me-3 fs-sm"></i>
                                                {{ $t('btn.login_as_user') }}
                                            </bs-dropdown-item>
                                            <bs-dropdown-item
                                                v-if="user.uniq_key"
                                                class="d-flex align-items-center"
                                                role="button"
                                                @click.prevent="Api.activateLogin(user.uniq_key)">
                                                <i class="icon icon-settings me-3 fs-sm"></i>
                                                Activate user
                                            </bs-dropdown-item>
                                            <bs-dropdown-divider />
                                            <bs-dropdown-item
                                                class="d-flex align-items-center text-danger"
                                                role="button"
                                                @click.prevent="deleteUser(user.id, reloadUsers)">
                                                <i class="icon icon-trash-2 me-3 fs-sm"></i>
                                                {{ $t('btn.delete_user') }}
                                            </bs-dropdown-item>
                                        </bs-dropdown-menu>
                                    </bs-dropdown>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <pagination
                        v-if="usersStruct.count > usersPerPage"
                        @change="paginationChanged"
                        :page="pageNumber"
                        :per-page="usersPerPage"
                        :per-page-options="[5, 10, 15, 20]"
                        :items="usersStruct.count" />
                </div>
                <div v-else class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="card border-0">
                            <div class="card-body text-center">
                                <i class="icon icon-info icon-32 text-primary mb-4"></i>
                                <h4>{{ $t('page.agents.no_items.title') }}</h4>
                                <p class="mb-4">{{ $t('page.agents.no_items.text') }}</p>
                                <button class="btn btn-light-primary">
                                    {{ $t('btn.add_agent') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </bs-card>
        </template>
        <template #footer>
            <bs-offcanvas
                id="tableFilter"
                placement="end"
                aria-labelledby="tableFilterLabel"
                v-model="filtersModal">
                <template #header>
                    <h5 id="tableFilterLabel" class="mb-0">{{ $t('layout.filter') }}</h5>
                </template>
                <div class="mb-4">
                    <date-range-picker
                        v-model="filters.dateRegistered"
                        id="filterDateRegistered"
                        :label="$t('filter.date_registered')"
                        label-class="search-col-label"
                        data-wrapper="dateRegistered"
                        :data-label="$t('filter.date_registered')"
                        data-label-from="dd/mm/yy"
                        data-label-to="dd/mm/yy" />
                </div>
                <div class="mb-4">
                    <date-range-picker
                        v-model="filters.lastLogin"
                        :label="$t('filter.last_login')"
                        label-class="search-col-label"
                        data-wrapper="dateRegistered"
                        :data-label="$t('filter.last_login')"
                        data-label-from="dd/mm/yy"
                        data-label-to="dd/mm/yy" />
                </div>
                <div class="mb-4">
                    <label class="form-label">{{ $t('filter.status') }}</label>
                    <radio-input
                        v-model="filters.status"
                        value="-"
                        id="status_all"
                        :label="$t('filter.user_status.all')"
                        name="status"
                        checked />
                    <radio-input
                        v-model="filters.status"
                        id="status_active"
                        :value="true"
                        :label="$t('filter.user_status.active')"
                        name="status" />
                    <radio-input
                        v-model="filters.status"
                        :value="false"
                        id="status_disabled"
                        :label="$t('filter.user_status.disabled')"
                        name="status" />
                </div>
                <div class="mb-4">
                    <label class="form-label">{{ $t('filter.country') }}</label>
                    <country-select
                        id="country"
                        v-model="filters.country"
                        :placeholder="$t('form.country.placeholder')" />
                </div>
                <checkbox-input
                    id="show_unverified"
                    :switch="true"
                    v-model="filters.show_unverified"
                    :label="$t('filter.show_unverified')" />
                <template #footer>
                    <div class="d-grid gap-2 d-md-block text-md-center w-100">
                        <button
                            class="btn btn-primary me-md-2"
                            type="button"
                            @click.prevent="applyFilter">
                            {{ $t('btn.apply_filter') }}
                        </button>
                        <button
                            class="btn btn-light-primary"
                            type="button"
                            @click.prevent="resetFilter">
                            {{ $t('btn.reset_filter') }}
                        </button>
                    </div>
                </template>
            </bs-offcanvas>
            <bs-modal
                id="exportFields"
                aria-labelledby="exportFieldsLabel"
                v-model="exportModal"
                :centered="true"
                footer-class="d-block d-md-flex justify-content-md-end py-3">
                <template #header>
                    <h5 id="exportFieldsLabel" class="mb-0">
                        {{ $t('layout.choose_export_fields') }}
                    </h5>
                </template>
                <div class="row row-cols-1 row-cols-md-2 gx-4 gy-1 mb-3">
                    <div
                        class="col"
                        v-for="(field, fieldIndex) in exportFields"
                        :key="fieldIndex">
                        <checkbox-input
                            :id="field.name"
                            v-model="exportFields[fieldIndex].value"
                            :label="$t('export_fields.' + field.name)" />
                    </div>
                </div>
                <button type="button"
                        class="btn btn-link btn-sm p-0"
                        @click.prevent="setChecks(true)">
                    {{ $t('btn.select_all') }}
                </button>
                <span class="text-muted mx-1">·</span>
                <button type="button"
                        class="btn btn-link btn-sm p-0"
                        @click.prevent="setChecks(false)">
                    {{ $t('btn.deselect_all') }}
                </button>
                <template #footer>
                    <div class="d-grid gap-2 d-md-block">
                        <button type="button"
                                class="btn btn-light-primary me-md-2"
                                @click.prevent="exportUsers">
                            {{ $t('btn.export_users') }}
                        </button>
                        <button type="button"
                                class="btn btn-light"
                                @click.prevent="exportModal.hide()">
                            {{ $t('btn.close') }}
                        </button>
                    </div>
                </template>
            </bs-modal>
        </template>
    </page-content>
</template>

<script>
import { ref, onMounted, watch, reactive } from 'vue'
import { useI18n } from "vue-i18n"
import Swal from "sweetalert2/dist/sweetalert2.js"
import Api from "@/modules/api"
import useHelpers from "@/modules/helpers"
import DateRangePicker from "@/components/Form/DateRangePicker"
import RadioInput from "@/components/Form/RadioInput"
import bsPopover from "@/components/Bootstrap/Popover/Popover"
import Subheader from "@/components/Subheader"
import PageContent from "@/components/PageContent"
import CountrySelect from "@/components/Form/CountrySelect"
import CheckboxInput from "@/components/Form/CheckboxInput"
import Pagination from "@/components/Pagination"
import bsModal from "@/components/Bootstrap/Modal/Modal"
import bsOffcanvas from "@/components/Bootstrap/Offcanvas/Offcanvas"
import useUser from "@/modules/user"
import bsDropdown from "@/components/Bootstrap/Dropdown/Dropdown"
import bsDropdownMenu from "@/components/Bootstrap/Dropdown/DropdownMenu"
import bsDropdownItem from "@/components/Bootstrap/Dropdown/DropdownItem"
import bsDropdownDivider from "@/components/Bootstrap/Dropdown/DropdownDivider"
import bsBtn from "@/components/Bootstrap/Button/Button"
import bsBtnGroup from "@/components/Bootstrap/ButtonGroup/ButtonGroup"
import bsCard from "@/components/Bootstrap/Card/Card"

export default {
    components: {
        PageContent,
        DateRangePicker,
        RadioInput,
        bsPopover,
        bsModal,
        bsOffcanvas,
        Subheader,
        CountrySelect,
        Pagination,
        CheckboxInput,
        bsDropdown,
        bsDropdownMenu,
        bsDropdownItem,
        bsDropdownDivider,
        bsBtn,
        bsBtnGroup,
        bsCard
    },
    setup() {
        const { t } = useI18n({ useScope: 'global' });
        const { dateToStr } = useHelpers;
        const activeUser = ref(null);
        const usersStruct = ref({count: 0, result: {}});
        const visibleServersCount = ref(2);
        const usersPerPage = ref(10);
        const pageNumber = ref(1);
        const orderBy = ref('id');
        const orderDesc = ref(false);
        const filtersModal = ref(null);
        const exportModal = ref(null);
        const { deleteUser } = useUser;

        let timerId = null;

        const exportFields = reactive([
            { name: 'id', value: true },
            { name: 'login', value: true },
            { name: 'email', value: true },
            { name: 'first_name', value: true },
            { name: 'last_name', value: true },
            { name: 'country', value: true },
            { name: 'city', value: true },
            { name: 'company_name', value: true },
            { name: 'phone', value: true },
            { name: 'fax', value: true },
            { name: 'address', value: true },
            { name: 'remark', value: true },
            { name: 'registration_date', value: true },
            { name: 'server_access', value: true },
            { name: 'last_login_date', value: true },
            { name: 'api_key', value: true },
            { name: 'email_activation', value: true },
            { name: 'is_active', value: true },
            { name: 'is_deleted', value: false },
            { name: 'is_default', value: false },
            { name: 'license', value: false },
            { name: 'parent_id', value: false },
            { name: 'expiry_date', value: false },
            { name: 'session_id', value: false },
            { name: 'session_time', value: false },
            { name: 'last_ip', value: false },
            { name: 'last_client', value: false },
            { name: 'uniq_key', value: false }
        ])

        const setChecks = (value) => {
            exportFields.forEach( field => {
                field.value = value
            })
        };

        const paginationChanged = (e) => {
            pageNumber.value = e.currentPage;
            usersPerPage.value = e.itemsPerPage;
        }

        watch([pageNumber, usersPerPage], () => {
            reloadUsers();
        })

        const filters = reactive({
            searchString: '',
            dateRegistered: null,
            lastLogin: null,
            status: '-',
            country: null,
            show_unverified: false
        })

        const selectOrder = (order) => {
            if ( order === orderBy.value ) {
                orderDesc.value = !orderDesc.value;
            } else {
                orderDesc.value = false;
                orderBy.value = order;
            }
            reloadUsers();
        }

        const sortingClass = (order) => {
            if (orderBy.value === order) {
                return orderDesc.value ? 'sort-desc' : 'sort-asc';
            }
            return '';
        }

        const reloadUsers = async () => {
            usersStruct.value = await Api.getLoginList(getFilter());
        }

        const searchInput = () => {
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = setTimeout(reloadUsers, 1000);
        }

        const getFilter = () => {
            let filter = {};
            if ( filter.searchString !== '' ) {
                filter = { ...filter, like: filters.searchString };
            }
            if ( filters.status !== undefined && filters.status !== '-' ) {
                filter = { ...filter, is_active: filters.status };
            }
            if (filters.country) {
                filter = {...filter, country: filters.country};
            }
            if (filters.dateRegistered) {
                filter = {
                    ...filter,
                    register_interval: {
                        date_from: filters.dateRegistered.slice(0, 10),
                        date_to: filters.dateRegistered.slice(-10)
                    }
                };
            }
            if (filters.lastLogin) {
                filter = {
                    ...filter,
                    last_login_interval: {
                        date_from: filters.lastLogin.slice(0, 10),
                        date_to: filters.lastLogin.slice(-10)
                    }
                };
            }

            return {
                filter: {
                    group: 'partner',
                    is_deleted: false,
                    email_activation: !filters.show_unverified,
                    ...filter
                },
                order: orderBy.value,
                order_by_desc: orderDesc.value,
                start: (pageNumber.value - 1) * usersPerPage.value,
                limit: usersPerPage.value
            };
        }

        onMounted(() => {
            reloadUsers();
        });

        const visibleServers = (servers) => {
            let obj = {};
            const dsKeys = Object.keys(servers);
            const num = dsKeys.length > visibleServersCount.value ? visibleServersCount.value : dsKeys.length;

            for (let i = 0; i < num; i++) {
                obj[i] = servers[dsKeys[i]];
            }

            return obj;
        }

        const serversCount = (servers) => {
            return Object.keys(servers).length;
        }

        const serversListHtml = (servers) => {
            let listHtml = '';
            const dsKeys = Object.keys(servers);

            for (let i = 0; i < dsKeys.length; i++) {
                listHtml = listHtml + '<li>' + servers[dsKeys[i]].server_name + '</li>'
            }

            return '<ul class="list-unstyled">' + listHtml + '</ul>';
        }

        const resetFilter = () => {
            filters.dateRegistered = null;
            filters.lastLogin = null;
            filters.status = '-';
            filters.country = null;
            filters.show_unverified = false;
            filtersModal.value.hide();
            reloadUsers();
        }

        const applyFilter = () => {
            filtersModal.value.hide();
            reloadUsers();
        }

        const status = (status) => {
            return {
                symbol: status ? 'symbol-light-success' : 'symbol-light-danger',
                badge: status ? 'bg-light-success text-success' : 'bg-light-danger text-danger',
                badgeLabel: status ? t('layout.status.active') : t('layout.status.disabled'),
                controlIcon: status ? 'icon-toggle-right' : 'icon-toggle-left',
            }
        }

        const toggleUser = (user) => {
            activeUser.value = user;

            Swal.fire({
                title: user.is_active ? t('user_deactivation.title') : t('user_activation.title'),
                text: user.is_active ? t('user_deactivation.text') : t('user_activation.text'),
                icon: user.is_active ? 'error' : 'success',
                showCancelButton: true,
                confirmButtonText: user.is_active ? t('user_deactivation.action') : t('user_activation.action'),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const userData = {
                        account_id: activeUser.value.id,
                        login: activeUser.value.login,
                        email: activeUser.value.email,
                        is_active: !activeUser.value.is_active,
                        is_delete: activeUser.value.is_deleted,
                    }

                    await Api.updatePartnerAccount(userData);
                    await reloadUsers();
                }
            })
        }

        const exportUsers = async () => {
            exportModal.value.hide();
            let filter = getFilter();
            delete filter.start;
            delete filter.limit;

            const json = await Api.getLoginList(filter);
            const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
            const header = Object.keys(json.result[0])
            const csv = [
                header.join(';'), // header row first
                ...json.result.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'))
            ].join('\r\n');
            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = 'users.csv';
            anchor.click();
        }

        const createUserToken = async (user, e) => {
            const target = e.target.closest('div').querySelector('[data-target = "login"]');

            if (!target.classList.contains('disabled')) {
                return;
            }
            //target.classList.add('disabled');

            const result = await Api.createTemporaryUserToken(user.id);
            if (result.token) {
                let hostParts = location.host.split('.');
                if ('admin' === hostParts[0]) {
                    hostParts[0] = 'partner';
                }
                target.href = location.protocol + '//' + hostParts.join('.') + '/login/' + result.token;
                target.target = '_blank';
                target.classList.remove('disabled');
            }
        }

        return {
            activeUser,
            usersStruct,
            usersPerPage,
            serversCount,
            visibleServers,
            serversListHtml,
            visibleServersCount,
            resetFilter,
            applyFilter,
            dateToStr,
            status,
            reloadUsers,
            toggleUser,
            deleteUser,
            pageNumber,
            orderBy,
            orderDesc,
            sortingClass,
            selectOrder,
            filters,
            exportUsers,
            paginationChanged,
            filtersModal,
            exportModal,
            searchInput,
            createUserToken,
            Api,
            exportFields,
            setChecks
        }
    }
}
/*
function UnicodeToWin1251(aa) {
    var bb = '', c = 0;
    for (var i = 0; i < aa.length; i++) {
        c = aa.charCodeAt(i);
        if (c > 127) {
            if (c > 1024) {
                if (c == 1025) {
                    c = 1016;
                } else if (c == 1105) {
                    c = 1032;
                }
                bb += String.fromCharCode(c - 848);
            }
        } else {
            bb += aa.charAt(i);
        }
    }
    return bb;
}
*/
</script>

<style lang="scss">
@import "./src/assets/styles/vendors/sweetalert2/sweetalert2";

.visible-sticky {
    visibility: hidden;

    .subheader.sticky & {
        visibility: visible;
    }
}
</style>