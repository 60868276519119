import {createRouter, createWebHistory} from 'vue-router';
import store from './store';
import Home from "@/views/Home";
import PageWrapper from "@/components/PageWrapper";
import Login from "@/views/Login";
import Register from "@/views/Register";
import Welcome from "@/views/Welcome";
import BookingsList from "@/views/Booking/BookingsList";
import PartnerList from "@/views/Users/PartnerList.vue";
import PartnerProfile from "@/views/Users/PartnerProfile";
import StaffList from "@/views/Users/StaffList";
import StaffProfile from "@/views/Users/StaffProfile";
import Logout from "@/views/Logout";
import PasswordReset from "@/views/PasswordReset";
import SystemHealth from "@/views/SystemHealth";
import DSCList from "@/views/Users/DSCList";
import Profile from "@/views/Profile";
import AccountActivation from "@/views/AccountActivation";
import AddStaff from "@/views/Users/AddStaff";

const hostParts = location.host.split('.');

const userRoutes = {
    admin: [
        {
            path: '',
            component: PageWrapper,
            meta: {requiresAuth: true},
            children: [
                {
                    path: '',
                    name: 'home',
                    component: PartnerList
                },
                {
                    path: '/users/list',
                    name: 'partner_list',
                    component: PartnerList
                },
                {
                    path: '/users/profile/:id?',
                    name: 'partner_details',
                    component: PartnerProfile
                },
                {
                    path: '/users/list/dsc',
                    name: 'dsc_list',
                    component: DSCList
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: Profile
                },

            ]
        },
        {
            path: '/login/:key?',
            name: 'login',
            component: Login
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout
        },
    ],
    partner: [
        {
            path: '',
            component: PageWrapper,
            meta: {requiresAuth: true},
            children: [
                {
                    path: '',
                    name: 'home',
                    component: StaffList
                },
                {
                    path: '/users/list/staff',
                    name: 'staff_list',
                    component: StaffList
                },
                {
                    path: '/users/staff/:id',
                    name: 'staff_details',
                    component: StaffProfile
                },
                {
                    path: '/users/staff/add',
                    name: 'add_staff',
                    component: AddStaff
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: Profile
                }
            ]
        },
        {
            path: '/activation/:key?',
            name: 'login_activation',
            component: AccountActivation
        },
        {
            path: '/login/:key?',
            name: 'login',
            component: Login
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout
        },
        {
            path: '/register',
            name: 'register',
            component: Register
        },
    ],
    test: [
        {
            path: '',
            component: PageWrapper,
            meta: {requiresAuth: true},
            children: [
                {
                    path: '',
                    name: 'home',
                    component: Home
                },
                {
                    path: '/system/health',
                    name: 'system_health',
                    component: SystemHealth
                },
                {
                    path: '/bookings/list',
                    name: 'bookings_list',
                    component: BookingsList
                },
                {
                    path: '/users/list/partner',
                    name: 'partner_list',
                    component: PartnerList
                },
                {
                    path: '/users/profile/:id?',
                    name: 'partner_details',
                    component: PartnerProfile
                },
                {
                    path: '/users/list/staff',
                    name: 'staff_list',
                    component: StaffList
                },
                {
                    path: '/users/staff/:id',
                    name: 'staff_details',
                    component: StaffProfile
                },
                {
                    path: '/users/staff/add',
                    name: 'add_staff',
                    component: AddStaff
                },
                {
                    path: '/users/list/dsc',
                    name: 'dsc_list',
                    component: DSCList
                },
                {
                    path: '/profile',
                    name: 'profile',
                    component: Profile
                }
            ]
        },
        {
            path: '/activation/:key?',
            name: 'login_activation',
            component: AccountActivation
        },
        {
            path: '/login/:key?',
            name: 'login',
            component: Login
        },
        {
            path: '/register',
            name: 'register',
            component: Register
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout
        },
        {
            path: '/password-reset/:key?',
            name: 'password_reset',
            component: PasswordReset
        },
        {
            path: '/welcome',
            name: 'welcome',
            component: Welcome
        }
    ],
};
const routes = (userRoutes[hostParts[0]] || userRoutes['test']);

// if ('admin' === hostParts[0]) {
//     routes = [
//         {
//             path: '',
//             component: PageWrapper,
//             meta: {requiresAuth: true},
//             children: [
//                 {
//                     path: '',
//                     name: 'home',
//                     component: Home
//                 },
//                 {
//                     path: '/system/health',
//                     name: 'system_health',
//                     component: SystemHealth
//                 },
//                 {
//                     path: '/bookings/list',
//                     name: 'bookings_list',
//                     component: BookingsList
//                 },
//                 {
//                     path: '/users/list/partner',
//                     name: 'partner_list',
//                     component: PartnerList
//                 },
//                 {
//                     path: '/users/profile/:id?',
//                     name: 'partner_details',
//                     component: PartnerProfile
//                 }
//             ]
//         },
//         {
//             path: '/login',
//             name: 'login',
//             component: Login
//         },
//         {
//             path: '/register',
//             name: 'register',
//             component: Register
//         },
//         {
//             path: '/logout',
//             name: 'logout',
//             component: Logout
//         },
//         {
//             path: '/welcome',
//             name: 'welcome',
//             component: Welcome
//         },
//     ];
// } else if ('partner' === hostParts[0]) {
//     routes = [];
// } else if ('efrito' === hostParts[0]) {
//     routes = [];
// } else {
//     routes = [
//         {
//             path: '',
//             component: PageWrapper,
//             meta: {requiresAuth: true},
//             children: [
//                 {
//                     path: '',
//                     name: 'home',
//                     component: Home
//                 },
//                 {
//                     path: '/system/health',
//                     name: 'system_health',
//                     component: SystemHealth
//                 },
//                 {
//                     path: '/bookings/list',
//                     name: 'bookings_list',
//                     component: BookingsList
//                 },
//                 {
//                     path: '/users/list/partner',
//                     name: 'partner_list',
//                     component: PartnerList
//                 },
//                 {
//                     path: '/users/profile/:id?',
//                     name: 'partner_details',
//                     component: PartnerProfile
//                 },
//                 {
//                     path: '/users/list/staff',
//                     name: 'staff_list',
//                     component: StaffList
//                 },
//                 {
//                     path: '/users/staff/:id',
//                     name: 'staff_details',
//                     component: StaffProfile
//                 },
//                 {
//                     path: '/users/staff/add',
//                     name: 'add_staff',
//                     component: AddStaff
//                 },
//                 {
//                     path: '/users/list/dsc',
//                     name: 'dsc_list',
//                     component: DSCList
//                 },
//                 {
//                     path: '/profile',
//                     name: 'profile',
//                     component: Profile
//                 }
//             ]
//         },
//         {
//             path: '/activation/:key?',
//             name: 'login_activation',
//             component: AccountActivation
//         },
//         {
//             path: '/login/:key?',
//             name: 'login',
//             component: Login
//         },
//         {
//             path: '/register',
//             name: 'register',
//             component: Register
//         },
//         {
//             path: '/logout',
//             name: 'logout',
//             component: Logout
//         },
//         {
//             path: '/password-reset/:key?',
//             name: 'password_reset',
//             component: PasswordReset
//         },
//         {
//             path: '/welcome',
//             name: 'welcome',
//             component: Welcome
//         }
//     ];
// }



const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    // console.log('requires auth');
    // console.log(to.meta.requiresAuth);
    // console.log('is auth');
    //console.log(store.getters.isAuthenticated);
    // console.log('to');
    // console.log(to);
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next('/login');
    } else {
        next();
    }
});

export default router;
