<template>
    <aside :class="sidebarClasses" v-bind="attrs">
        <div class="sidebar-content">
            <slot />
        </div>
    </aside>
    <div class="sidebar-backdrop" v-if="props.offCanvas" @click.prevent="toggleSidebar" />
</template>

<script>
import {computed, ref} from 'vue';

export default {
    props: {
        offCanvas: {
            type: Boolean,
            default: false
        },
        position: {
            type: String,
            default: 'left'
        },
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    setup(props, { attrs }) {
        const visible = ref(props.isVisible);

        const sidebarClasses = computed(() => {
            return [
                'sidebar',
                props.offCanvas && 'sidebar-offcanvas',
                props.position === 'right' && 'sidebar-right',
                visible.value && 'show',
            ]
        })

        const toggleSidebar = () => {
            visible.value = !visible.value;
        }

        return {
            visible,
            toggleSidebar,
            sidebarClasses,
            attrs,
            props
        }
    }
}
</script>

<style lang="scss">
@import "./src/assets/styles/layout/sidebar";
</style>