<template>
    <component
        :is="htmlTag"
        :href="linkUrl"
        class="nav-link"
        :class="{
            'active': active,
            'disabled': disabled,
            'dropdown-toggle': dropdownToggle
        }"
        v-bind="attrs">
        <slot>
            <bs-icon
                v-if="icon"
                :icon="icon"
                :size="iconSize"
                :hidden="iconHidden" />
            {{ label }}
            <bs-badge
                v-if="badge"
                :label="badge"
                :variant="badgeVariant"
                :color="badgeColor"
                :class="badgeClass"
                attr="1"
                :pill="badgePill" />
        </slot>
    </component>
</template>

<script>
import Icon from "@/components/Bootstrap/Icon/Icon";
import Badge from "@/components/Bootstrap/Badge/Badge";
import {ref, watchEffect} from "vue"

export default {
    name: "NavLink",
    components: {
        bsIcon: Icon,
        bsBadge: Badge
    },
    props: {
        tag: {
            type: String,
            default: 'a'
        },
        href:{
            type: String,
            default: '#'
        },
        label: String,
        tab: Boolean,
        target: String,
        active: Boolean,
        disabled: Boolean,
        dropdownToggle: Boolean,
        icon: String,
        iconSize: String,
        iconHidden: Boolean,
        badge: [String, Number],
        badgeVariant: String,
        badgeColor: String,
        badgeClass: String,
        badgePill: Boolean
    },
    setup(props, {attrs}) {
        const htmlTag = ref(props.tag)
        const linkUrl = ref(props.href)

        watchEffect(() => {
            if (props.active) {
                attrs = {
                    ...attrs,
                    "aria-current": "page"
                }
            }

            if (props.disabled) {
                attrs = {
                    ...attrs,
                    "tabindex": "-1",
                    "aria-disabled": "true"
                }
            }

            if (props.dropdownToggle) {
                attrs = {
                    ...attrs,
                    "data-bs-toggle": "dropdown",
                    "role": "button",
                    "aria-expanded": "false"
                }
            }

            if (props.tab && props.target) {
                htmlTag.value = 'button'
                linkUrl.value = null

                attrs = {
                    ...attrs,
                    "data-bs-toggle": "tab",
                    "data-bs-target": `#${props.target}`,
                    "role": "tab",
                    "type": "button",
                    "aria-controls": props.target,
                    "aria-selected": props.active ? 'true' : 'false'
                }
            }
        })

        return {
            htmlTag,
            linkUrl,
            attrs
        }
    }
}
</script>
