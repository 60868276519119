<template>
    <div class="card-header">
        <slot />
    </div>
</template>

<script>

export default {
    name: "bsCardHeader"
};
</script>